import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"]
})
export class ImageUploaderComponent implements OnInit {
  form: FormGroup;
  constructor() {}
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = "";

  handleDragEnter() {
    console.log("handleDragEnter");
    this.dragging = true;
  }

  handleDragLeave() {
    console.log("handleDragLeave");
    this.dragging = false;
  }

  handleDrop(e) {
    
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    
    console.log("input change");
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var pattern = /image-*/;
    var reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }

    this.loaded = false;
    // reader.onload = this.handleImageLoad.bind(this);
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    
    // console.log("_handleReaderLoaded");
    var reader = e.target;
    this.imageSrc = reader.result;
    let src = reader.result.split("data:image/jpeg;base64,")[1];
    localStorage.setItem("imgsrc", src);
    // console.log(src)
    this.loaded = true;
  }

  ngOnInit() {}
  clear() {
    this.form.reset();
  }
}
