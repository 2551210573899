import { Component, OnInit, ViewChild, EventEmitter, ElementRef  } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CommonServices } from '../../services/commonservice';

@Component({
  selector: 'app-devices-group',
  templateUrl: './devices-group.component.html',
  styleUrls: ['./devices-group.component.css'],
  providers: [CommonServices]

})
export class DevicesGroupComponent implements OnInit {
  showViewDeviceModal: boolean;
  form: FormGroup;
  deviceGroup: any={};
  listofGroups: any=[];
  filterRecords: any=[];
  noData: string;
  groupInfoById: any = {};
  deviceGroupId: any;
  groupDetails: any ={};
  assign: boolean;
  @ViewChild('addDeviceGorupBtnDisabled', { static: false }) public addDeviceGorupBtnDisabled: ElementRef;
  @ViewChild('updateDeviceGorupBtnDisabled', { static: false }) public updateDeviceGorupBtnDisabled: ElementRef;
  @ViewChild('deleteDeviceGorupBtnDisabled', { static: false }) public deleteDeviceGorupBtnDisabled: ElementRef;
  @ViewChild('associateSubmitDeviceGorupBtnDisabled', { static: false }) public associateSubmitDeviceGorupBtnDisabled: ElementRef;
  devicesList: any = [];
  customers: any;
  customerId: any;
  allChecked: boolean = false;
  editGroup = false;
  unAssign: boolean;
  filter: string;


  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private CommonServices: CommonServices,
  ) { }
   //sorting
   key: string = 'name'; //set default
   reverse: boolean = false;
   sort(key) {
     this.key = key;
     this.reverse = !this.reverse;
   }
 
   //initializing p to one
   p: number = 1;

  ngOnInit() {
    this.form = this.fb.group({
      deviceGroupName: ['', Validators.required],
      description: [''],
    });
    this.getCustomer()
    this.getListofGroups();
    // this.addValidator();
  }

  spaceValidation(event) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  addValidator() {
    this.form.controls.deviceGroupName.setAsyncValidators([
      this.alreadyExists()
    ]);
  }

  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }

  alreadyExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn = true;
      (this.listofGroups || []).filter(items => {
        if (this.form.controls.deviceGroupName.value.toUpperCase() === items.deviceGroupName.toUpperCase()) {
          if(this.editGroup){
            bReturn = false;
            if(this.groupInfoById.deviceGroupName.toUpperCase() === items.deviceGroupName.toUpperCase()){
              bReturn = true;
            }
          }else{
            bReturn = false;
          }
        }
      });
      let isValid = true;
      if((this.form.controls.deviceGroupName.value || '').trim().length === 0){
          isValid = false;
          const invalid: ValidationErrors = { empty: true };
          return isValid ? of(null) : of(invalid);
      }
      const err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }

  // allSelected($event) {
  //   const checked = $event.target.checked;
  //   this.listofGroups.forEach(obj => obj['isChecked'] = checked);
  //   for (var list of this.listofGroups) {
  //     if (list.isChecked == true) {
  //       this.devicesList.push(list.deviceGroupId);
  //       this.assign = true;
  //     }else{
  //       this.devicesList = [];
  //       this.assign = false;
  //     }
  //   }
  // }

//   onChange(items, event){
//     let checked = event.target.checked;
//     if (checked == true){
//       this.assign = true;
//       this.devicesList.push(items.deviceGroupId);
//     }
//     if(checked == false){
//       for( var i = 0; i < this.devicesList.length; i++){ 
//         if ( this.devicesList[i] === items.deviceGroupId) { 
//           this.devicesList.splice(i, 1); 
//         }
//     }
//     this.allChecked = false;
//   }
//     if(this.devicesList.length > 0){
//       if(items.customerid == null || items.customerid == ''){
//       this.assign = true;
//       }else{
//         this.assign = false;
//       }
//     }else{
//       this.assign = false;
//     }
//     console.log(this.devicesList)
 
// }

onChange(items, event){
  let checked = event.target.checked;
  if (checked === true && items.customerid === null){
    this.assign = true;
    this.unAssign = false;
    this.devicesList.push(items.deviceGroupId);
  }
  if (checked == true && items.customerid != null) {
    this.unAssign = true;
    this.assign = false;
    this.devicesList.push(items.deviceGroupId);
  }
  if(checked === false && items.customerid == null){
    this.assign = false;
    for( var i = 0; i < this.devicesList.length; i++){ 
      if ( this.devicesList[i] === items.deviceGroupId) { 
        this.devicesList.splice(i, 1); 
      }
    }
  }
  if (checked == false && items.customerid != null) {
    this.unAssign = false;
    for( var i = 0; i < this.devicesList.length; i++){ 
      if ( this.devicesList[i] === items.deviceGroupId) { 
        this.devicesList.splice(i, 1); 
      }
    }
    // console.log(this.devicesList);
  }
  if(this.devicesList.length > 0){
    this.assign = true;
  }else{
    this.assign = false;
  }
}

associate(modal){
  modal.show();
  this.customerId = '';
}

  clear() {
    this.form.reset();
  }

  cancel(modal){
    modal.hide();
    this.customerId = '';
    this.getListofGroups();
  }

  customerChange(e) {
    this.customerId = e.target.value;
  }

  getCustomer() {
    let token = localStorage.getItem('zerv');
    this.CommonServices.getCustomer(token).subscribe(
      res => {
        // if (res.code == 200) {
        this.customers = res;
        this.spinnerService.hide();
        // } else {
        //   this.onSubmitLoading = false;
        // }
      },
      err => {
        console.log(err);
      }
    );
  }

  associateDeviceGroup(){
    this.buttonDisable(this.associateSubmitDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    let data = {
       'listDeviceGroups' : this.devicesList,
       'customerId' : this.customerId
     }
     let token = localStorage.getItem('zerv');
     this.CommonServices.associateDeviceGroup(token, data).subscribe(
       res => {
         if (res.code == 200) {
          this.spinnerService.hide();
          this.getListofGroups();
           this.CommonServices.showSuccess('Devices Associated to Group Successfully', 'Assigned to Group');
           this.devicesList = [];
           this.assign = false;
         } else {
          this.spinnerService.hide();
           this.CommonServices.showError('Devices are not Associated to Group', 'Try Again');
           this.getListofGroups();
           this.devicesList = [];
           this.assign = false;
          }
       },
       err => {
        this.spinnerService.hide();
         this.CommonServices.showError('Devices are not Associated to Group', 'Try Again');
         this.devicesList = [];
       }
     );
   }

  getListofGroups() {
    this.spinnerService.show();
    let groupType = 'Custom-Type'
    let token = localStorage.getItem('zerv');
    this.CommonServices.getallZervAdminGroups(token,groupType).subscribe(
      res => {
        if (res.code == 200) {
          this.listofGroups = res.listGetAllDeviceGroup;
          this.filterRecords = res.listGetAllDeviceGroup;
          if (this.listofGroups.length === 0) {
            this.noData = 'No Data Found!';
          }
          for(var list of this.listofGroups){
            if(list.customerid != null){
              list['disable'] = true;
            }else{
              list['disable'] = false;
            }
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  getGroupById(Id,Modal) {
    this.spinnerService.show();
    let token = localStorage.getItem('zerv');
    this.CommonServices.getGroupById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.groupInfoById = res;
          this.deviceGroup['deviceGroupName'] = res.deviceGroupName;
          this.deviceGroup['description'] = res.description;
          this.spinnerService.hide();
          Modal.show();
          this.editGroup = true;
        } else {
          this.spinnerService.hide();
          Modal.show()
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        Modal.show();
      }
    );
  }

  viewDevicesModal(items) {
    this.groupDetails = items;
    this.showViewDeviceModal = true;
  }
  addGroup(addNewGroup) {
    this.buttonDisable(this.addDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    this.editGroup = false;
    let formData = this.deviceGroup;
    formData.deviceGroupName = formData.deviceGroupName.trim();
    if(formData.description){
      formData.description = formData.description.trim();
    }
    let data: any;
    data = {
          deviceGroupName: formData.deviceGroupName,
          description: formData.description,
    };
    let token = localStorage.getItem('zerv');
    this.CommonServices.addGroupDevices(token, data).subscribe(
      res => {
        if (res.code == 200) {
          this.form.reset();
          this.spinnerService.hide();
          addNewGroup.hide();
          this.CommonServices.showSuccess(res.message, 'Success')
          this.getListofGroups()
        } else {
          this.form.reset();
          this.spinnerService.hide();
          addNewGroup.hide();
          this.CommonServices.showError(res.message, 'Failed')
          this.getListofGroups()
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    console.log(data);

  }

  updateGroup(addNewGroup,Id) {
    this.buttonDisable(this.updateDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    let formData = this.deviceGroup;
    formData.deviceGroupName = formData.deviceGroupName.trim();
    if(formData.description){
      formData.description = formData.description.trim();
    }    
    let data: any;
    data = {
          deviceGroupName: formData.deviceGroupName,
          description: formData.description,
    };
    let token = localStorage.getItem('zerv');
    this.CommonServices.updateGroupInfo(token, Id, data).subscribe(
      res => {
        if (res.code === "200") {
          this.form.reset();
          this.spinnerService.hide();
          addNewGroup.hide();
          this.CommonServices.showSuccess(res.message, 'Success')
          this.getListofGroups()
        } else {
          this.form.reset();
          this.spinnerService.hide();
          this.CommonServices.showError(res.message, 'Failed')
          this.getListofGroups()
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        this.CommonServices.showError("Device Group already exist with this name", 'Failed')
      }
    );
    console.log(data);

  }

  onClickBack(){
    this.showViewDeviceModal = false;
    this.groupDetails = '';
  }

  getGroupId(Id){
    this.deviceGroupId = Id
  }

  deleteGroup(Id){
    this.buttonDisable(this.deleteDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    let token = localStorage.getItem('zerv');
    this.CommonServices.DeleteGroup(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.spinnerService.hide();
          this.getListofGroups();
          this.CommonServices.showSuccess('Group Deleted successfully', 'Device Deleted');
        } else {
          this.spinnerService.hide();
          this.CommonServices.showError('Group was not Deleted', 'Try Again');
        }
      },
      error => {
        this.spinnerService.hide();
      }
    );
  }

  onClearSearch(event) {
    const value = event.target.value;
      this.filterWithText();
  }

  filterWithText() {
    if (this.filter == '') {
        this.spinnerService.show();
        this.getListofGroups();
    } else {
        this.filterRecords = [];
        this.filterRecords = this.listofGroups.filter(obj => Object.values(obj)
        .some(val => val !== null ? val.toString().trim().toLowerCase().includes(this.filter.trim().toLowerCase()) : ''));
    }
 }

}
