import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CommonServices} from 'src/app/services/commonservice';
import {user} from '../user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {GlobalConstants} from '../../../constants/global-constants';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['../users.component.css'],
})

export class ViewUserComponent implements OnInit {
  @Input() user: any = {};
  @Output() closeViewUserModal: EventEmitter<any> = new EventEmitter<any>();
  customerNameFirstLetter = GlobalConstants.customerNameFirstLetter;
  userForm: user;
  locations: any;
  userData: any;
  active: any;
  @ViewChild('content',
    { static: true }
  ) content: ElementRef;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  onSubmitLoading: boolean;
  showCreateUserModal: boolean;
  showEditUserModal: boolean;
  showViewUserModal: boolean;
  data: any;
  userPhone: any;
  userId: any;
  access: any;
  messagesss: string;
  NoAccessList: any;
  @ViewChild('DeleteUserDisabled', { static: false }) public DeleteUserDisabled: ElementRef;
  @ViewChild('SwitchUserDisabled', { static: false }) public SwitchUserDisabled: ElementRef;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private modalService: NgbModal,
    private commonServices: CommonServices,
    private modalServices: BsModalService
  ) {

  }
  ngOnInit() {
    if (this.user && this.user.phoneNumber) {
      this.getUserById(this.user.phoneNumber);
      this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
    }
  }

  getUserById(userId) {
    const custId = this.user.customerId;
    this.userPhone = userId;
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getUserById(token, userId, custId).subscribe(
      res => {
        if (res.code === '200') {
          this.data = res;
          this.data.firstName = res.name.substr(
            0,
            res.name.indexOf(' ')
          );
          this.data.lastName = res.name.substr(
            res.name.indexOf(' ') + 1
          );
          this.data.phoneNumber = '+' + res.phoneNumber;
          this.spinnerService.hide();
          if (this.data.listGetUserAccess.length === 0) {
            this.NoAccessList = 'No Data Found!';
          }
        } else {
          this.spinnerService.hide();
          this.data = {};
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  close() {
    this.modalService.dismissAll(this.content);
    this.closeViewUserModal.emit(false);
  }
  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }
  // kiran
  getDeleteAddress(Id) {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getDeleteAddress(token, Id).subscribe(
      res => {

        if (res.code === '200') {

          this.getUserById(this.userPhone);
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }

  getUserId(userInfo, confirmationDelete) {
    this.userId = userInfo.id;
    this.access = userInfo.active;

    this.modalRef = this.modalServices.show(confirmationDelete, {
      class: 'modal-sm',
      backdrop: 'static',
      initialState: { data: userInfo.id },
      keyboard: false
    });
  }

  locationAccess(data) {
    const token = localStorage.getItem('zerv');
    this.commonServices.locationAccess(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.onSubmitLoading = false;
          this.modalRef.content = null;
          this.getUserById(this.userPhone);
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
        }
      },
      err => {
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }

  openModal(confirmationDelete: TemplateRef<any>) {
    this.modalRef = this.modalServices.show(confirmationDelete, { class: 'modal-sm', backdrop: 'static', keyboard: false });
  }

  openSwitchModal(confirmationSwitch: TemplateRef<any>, item) {
    this.userId = item.id;
    this.access = item.active;
    const data = {
      id: item.id,
      active: !item.active
    };
    this.modalRef = this.modalServices.show(confirmationSwitch, {
      class: 'modal-sm',
      backdrop: 'static',
      initialState: { data },
      keyboard: false
    });
  }

  confirm(): void {
    this.messagesss = 'Confirmed!';
    this.modalRef.hide();
    const state = this.modalServices.config.initialState;
    // @ts-ignore
    this.getDeleteAddress(state.data);
    this.decline();
  }

  confirmLocationAccess(): void {
    this.messagesss = 'Confirmed!';
    this.modalRef.hide();
    const state = this.modalServices.config.initialState;
    // @ts-ignore
    this.locationAccess(state.data);
    this.decline();
  }

  decline(): void {
    this.messagesss = 'Declined!';
    this.modalRef.hide();
    this.getUserById(this.userPhone);
  }
}
