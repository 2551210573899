import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CommonServices} from '../../services/commonservice';
import {GlobalConstants} from '../../constants/global-constants';

@Component({
  selector: 'app-location-group-client',
  templateUrl: './location-group-client.component.html',
  styleUrls: ['./location-group-client.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CommonServices]
})
export class LocationGroupClientComponent implements OnInit {
  showViewDeviceModal: boolean;
  form: FormGroup;
  deviceGroup: any = {};
  listofGroups: any = [];
  noData: string;
  groupInfoById: any = {};
  deviceGroupId: any;
  groupDetails: any = {};
  listofGroupsbyGroupType: any = [];

  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  columns = GlobalConstants.LOCATIONGROUPS.cols;


  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private commonServices: CommonServices,
  ) { }


  ngOnInit() {
    this.getListofGroups();
  }

  getListofGroups() {
    this.spinnerService.show();
    const groupType = 'Location-Type';
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    this.commonServices.getallclientDevicegroups(token, groupType, customerId).subscribe(
      res => {
        if (res.code === '200') {
          this.listofGroups = res.listGetAllDeviceGroup;
          if (this.listofGroups.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  viewDevicesModal(items) {
    this.groupDetails = items;
    this.showViewDeviceModal = true;
  }

  onClickBack() {
    this.showViewDeviceModal = false;
    this.groupDetails = '';
  }
}
