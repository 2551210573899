import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs/internal/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { AuthService } from '../auth/auth.service';
// import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class XhrService {

  constructor(private authService:AuthService,
    private http: HttpClient) { }

  xhr(request: {
    url: string,
    method: string,
    data?: any,
    headers?: {},
    params?: {},
    file?: any,
    fileInputName?: string,
    without_auth_headers?: boolean
  }, xhrOptions: any = {}) {
    let params: HttpParams = new HttpParams();
    _.forEach(request.params, (val, key) => {
      params = params.set(key.toString(), val);
    });
    let headers: HttpHeaders = new HttpHeaders();
    _.forEach(request.headers, (val, key) => {
      headers = headers.set(key.toString(), val);
    });
    if (!request.without_auth_headers) {
      const authHeaders = this.authService.getAuthorization();
      _.forEach(authHeaders, (val, key) => {
        headers = headers.set(key.toString(), val);
      });      
    }
   
    let service: any;
    const options = {headers, params};
    if (request.method === 'P') {
      service = this.http.post(request.url, request.data, {...options, ...xhrOptions, observe: "response"});
    }
    if (request.method === 'PU') {
      service = this.http.put(request.url, request.data, {...options,observe: "response"} );
    }
    if (request.method === 'G') {
      service = this.http.get(request.url,{...options, observe: "response" });
    }
    if (request.method === 'D') {
      service = this.http.delete(request.url, {body: request.data, ...options, ...xhrOptions, observe: "response"});
    }
    return service.map(res => {
      return res.body as Object;
    }).catch((err) => {
      if (err && err.error && err.error.code === 'TOKEN_IS_INVALID') {
        this.authService.invalidApiToken();
      }
      return new Observable(observer => {
        observer.error(err);
      });
    });

  }
}
