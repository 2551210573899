import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CommonServices} from '../../services/commonservice';
import {PrimeNGConfig, SortEvent} from 'primeng/api';
import {GlobalConstants} from '../../constants/global-constants';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-users-zerv-admin',
  templateUrl: './users-zerv-admin.component.html',
  styleUrls: ['./users-zerv-admin.component.css'],
  providers: [CommonServices]
})
export class UsersZervAdminComponent implements OnInit {
  constructor(
    // private fb: FormBuilder,
    private router: Router,
    private commonServices: CommonServices, // private imageploader: ImageUploaderComponent
    private spinnerService: Ng4LoadingSpinnerService,
    private primengConfig: PrimeNGConfig
  ) {
  }
  userData: any = [];
  // user: any = {};
  allUserById: any = {};
  locationList: any;
  allUsers: any = {};
  onSubmitLoading: boolean;
  NoUserData: any;
  NoAllusersData: any;
  NoLocationListData: any;
  // sorting
  key = 'name'; // set default
  reverse = false;

  // initializing p to one
  paginate1 = 1;
  paginate2 = 1;

  @ViewChild('usersTable') uesrsTable: Table;
  cols = GlobalConstants.ZERV_ADMIN.USER_COLS;
  itemsPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  totalRecords: any = [];
  paginationRefresh: boolean;
  sortField = 'name:1';
  currentPage = {first: 0, rows: GlobalConstants.ItemPerPage};
  filterColumn = '';
  currentTabIndex = 0;
  totalUserCount = 0;
  customerNameFirstLetter = GlobalConstants.customerNameFirstLetter;
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.getMyUser();
  }

  selectedUserTab(tabIndex) {
    this.userData = [];
    if (this.currentTabIndex !== tabIndex) {
      this.sortField = 'name:0';
      this.currentPage = {first: 0, rows: GlobalConstants.ItemPerPage};
      this.filterColumn = '';
    }
    this.currentTabIndex = tabIndex;
    if (this.currentTabIndex === 0) {
      if (this.cols.length > 3) {
        this.cols.pop();
      }
      this.getMyUser();
    } else {
      if (this.cols.length === 3) {
        this.cols.push(GlobalConstants.ZERV_ADMIN.ADMIN_COLS[0]);
      }
      this.getAllUsers();
    }
  }

  getMyUser() {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getUser(token, '', '', '', '').subscribe(
      res => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        if (res.code === '200') {
          this.userData = res.listUsers;
          this.totalUserCount = res.totalUserCount;
          if (this.userData.length === 0) {
            this.NoUserData = 'No Data Found!';
          }
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getAllUsers() {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getAllUsers(token, this.currentPage.rows, this.currentPage.first, this.sortField, this.filterColumn).subscribe(
      res => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        if (res.code === '200') {
          this.userData = res.listGetAllUsers;
          this.totalUserCount = res.totalUserCount;
          if (this.totalUserCount === 0) {
            this.NoAllusersData = 'No Data Found!';
          }
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  getAllUserById(userId, customerId) {
    this.locationList = '';
    const custId = customerId;
    const token = localStorage.getItem('zerv');
    this.spinnerService.show();
    this.commonServices.getUserById(token, userId, customerId).subscribe(
      res => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        if (res.code === '200') {
          this.allUserById = res;
          this.locationList = res.listGetUserAccess;
          if (this.locationList.length === 0) {
            this.NoLocationListData = 'No Data Found!';
          }
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  closeDetailModal() {
    this.allUserById = '';
    this.locationList = '';
  }

  pageChanged($event) {
    this.currentPage.rows = $event.rows;
    this.currentPage.first = $event.first;
    this.paginationRefresh = true;
    this.selectedUserTab(this.currentTabIndex);
  }

  customSort(event: SortEvent) {
    if (event.field !== 'name') {
      return;
    }
    if (event.order === -1) {
      this.sortField = event.field + ':0';
    } else {
      this.sortField = event.field + ':' + event.order;
    }
    this.currentPage.rows = GlobalConstants.ItemPerPage;
    this.currentPage.first = 0;
    this.selectedUserTab(this.currentTabIndex);
  }

  onClearSearch(event) {
    const value = event.target.value;
      this.onClickSearch(value);
  }

  onClickSearch(value) {
    this.currentPage.rows =  25;
    this.currentPage.first = 0;
    const searchString = value;
    if (searchString === '') {
      this.filterColumn = '';
    } else {
      this.filterColumn = 'name:' + searchString + ',' + 'email:' + searchString + ',' + 'phoneNumber:' + searchString + ',' + 'customerName:' + searchString;
    }
    this.selectedUserTab(this.currentTabIndex);
  }
}
