import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CommonServices} from '../../../services/commonservice';
import {Router} from '@angular/router';
import {IDevice} from '../../../model/device.model';

@Component({
  selector: 'app-view-devices-client',
  templateUrl: './view-devices-client.component.html',
  styleUrls: ['./view-devices-client.component.css'],
  providers: [CommonServices]
})
export class ViewDevicesClientComponent implements OnInit {

  constructor(
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
  ) { }
  devices: any = [];
  noData: string;
  devicesList: any = [];
  @Input() groupDetails: any = {};
  @Input() groupInfo: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  allGroupedDevices: any = [];
  assign: boolean;
  unAssignId: any;
  filter;
  disabled = false;
  allChecked = false;
  availableDevice = false;
  // sorting
  key = 'name'; // set default
  reverse = false;

  // initializing p to one
  p = 1;
  paginate1 = 1;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  ngOnInit() {
    this.assignedDevices();
  }
  getavailableDevices() {
    this.spinnerService.show();
    this.availableDevice = true;
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    this.commonServices.getavailableDevices(token, customerId).subscribe(
      res => {
        if (res.code === '200') {
          this.devices = res.listGetDevices;
          for (const list of this.allGroupedDevices) {
            for (const item of this.devices) {
              if (list.deviceMACId === item.deviceMACId) {
                this.disabled = true;
                item.disable = this.disabled;
              }
            }
          }
          if (this.devices.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  allSelected($event) {
    const checked = $event.target.checked;
    this.devices.forEach(obj => {
      if (obj.disable) {
        obj.isChecked = false;
      } else {
        obj.isChecked = checked;
      }
    });
    for (const items of this.devices) {
      if (items.isChecked === true) {
        const deviceObj = {} as IDevice;
        deviceObj.deviceMACId = items.deviceMACId ? items.deviceMACId : null;
        deviceObj.customerId = items.customerId ? items.customerId : null;
        deviceObj.active = items.active ? items.active : null;
          // deviceObj['universalAccessCode'] =  "Zerv";
        deviceObj.installLocation = items.locationName ? items.locationName : null;
        deviceObj.installSubLocation = items.subLocationName ? items.subLocationName : null;
        deviceObj.installLocationFriendlyName = items.DeviceKey ? items.DeviceKey : null;
        deviceObj.installSubLocationFriendlyName = items.FirmwareVersion ? items.FirmwareVersion : null;
        deviceObj.deviceGroupId = this.groupDetails.deviceGroupId;
        this.devicesList.push(deviceObj);
        this.assign = true;
      } else {
        this.devicesList = [];
        this.assign = false;
      }
    }
  }

  onChange(items, event) {
    const checked = event.target.checked;
    if (checked === true) {
      this.assign = true;
      const deviceObj = {} as IDevice;
      deviceObj.deviceMACId = items.deviceMACId ? items.deviceMACId : null;
      deviceObj.customerId = items.customerId ? items.customerId : null;
      deviceObj.active = items.active ? items.active : null;
      deviceObj.installLocation = items.locationName ? items.locationName : null;
      deviceObj.installSubLocation = items.subLocationName ? items.subLocationName : null;
      deviceObj.installLocationFriendlyName = items.DeviceKey ? items.DeviceKey : null;
      deviceObj.installSubLocationFriendlyName = items.FirmwareVersion ? items.FirmwareVersion : null;
      deviceObj.deviceGroupId = this.groupDetails.deviceGroupId;
      this.devicesList.push(deviceObj);
    }
    if (checked === false) {
      for ( let i = 0; i < this.devicesList.length; i++) {
        if ( this.devicesList[i].deviceMACId === items.deviceMACId) {
          this.devicesList.splice(i, 1);
        }
    }
      this.allChecked = false;
    }
    if (this.devicesList.length > 0) {
        this.assign = true;
    } else {
      this.assign = false;
    }
    // console.log(this.devicesList)
  }
  addDevicestoGroup() {
    this.spinnerService.show();
   const data = {
      listAddDevicesToGroup : this.devicesList
    };
   const token = localStorage.getItem('zerv');
   this.commonServices.addDevicestoGroup(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.commonServices.showSuccess(res.message, "Success");
          this.assignedDevices();
          this.getavailableDevices();
          this.assign = false;
        } else {
          this.spinnerService.hide();
          this.assign = false;
          this.commonServices.showError(res.message, "Failed")
        }
      },
      err => {
        this.assign = false;
        this.spinnerService.hide();
        this.commonServices.showError("Devices are not Added to Group", "Failed")
      }
    );
  }
  assignedDevices() {
    this.spinnerService.show();
    this.availableDevice = false;
    this.devicesList = [];
    let Id = '';
    if (this.groupInfo.deviceGroupId) {
      Id = this.groupInfo.deviceGroupId;
    } else {
      Id = this.groupDetails.deviceGroupId;
    }
    const token = localStorage.getItem('zerv');
    this.commonServices.getallGroupedDevices(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.allGroupedDevices = res.listGetAllDeviceGroupMapping;
          this.spinnerService.hide();
          if (this.devices.length === 0) {
            this.noData = 'No Data Found!';
          }
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  onClickBack() {
  // window.location.reload()
  if (this.groupInfo.deviceGroupId) {
    this.router.navigate(['/users']);
  } else {
    this.router.navigate(['/devicesGroup']);
  }
  this.onSubmit.emit(false);
  }
  getUnAssignId(Id) {
    this.unAssignId = Id;
  }
  removeDevicefromGroup(Id) {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.unassignDevicefromGroup(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.commonServices.showSuccess('Device Deleted from Group successfully', 'Device Deleted');
          this.assignedDevices();
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Device was not Deleted from Group', 'Try Again');
        }
      },
      error => {
        this.spinnerService.hide();
      }
    );
  }
}
