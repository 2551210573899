import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonServices } from "../../services/commonservice";
import { GlobalConstants } from "../../constants/global-constants";
import { LazyLoadEvent, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import * as moment from "moment";

@Component({
  selector: 'app-audit-trail-logs',
  templateUrl: './audit-trail-logs.component.html',
  styleUrls: ['./audit-trail-logs.component.css'],
  encapsulation : ViewEncapsulation.None,
  providers: [CommonServices]
})
export class AuditTrailLogsComponent implements OnInit {
  currentDate = new Date();
  data: any = {};
  FromDate: any;
  ToDate: any;
  dateValidationError: boolean = false;
  listofSelectedCustomers:any = [];
  customersInfo: any =[];
  customersList: any =[];
  paginationRefresh: boolean;
  userAuditTrail: any = [];
  totalRecords: any = [];
  mainAuditTrail: any = [];
  mainTotalRecords: number;
  noData: any;
  text: any;

  _selectedColumns: any = [];
  cols = GlobalConstants.AUDITTRAILS.cols;
  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  sortingObj: any = {};


  constructor(private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,) { 
      for (let i = 0; i < 4; i++) {
        this._selectedColumns.push(this.cols[i]);
      }
    }

  ngOnInit(): void {
    if (localStorage.getItem("selectedAuditTrailsColumns") != null) {
      this._selectedColumns = JSON.parse(
        localStorage.getItem("selectedAuditTrailsColumns")
      );
    }
    this.data.toDate = this.currentDate;
    this.data.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
    // console.log(moment(this.data.fromDate).format("YYYY-MM-DD"));
    // debugger
    this.getCustomer();
    // this.data.customerName = ['Zerv'];
    // this.data.customerName.customerName = 'Zerv';
  }
  validation(){
    if(this.data.fromDate > this.data.toDate){
      this.dateValidationError = true;
      // this.data.toDate = "";
    }else{
      this.dateValidationError = false;
    }
  }
  getCustomer() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustomer(token).subscribe(
      res => {
        this.customersInfo = res;
      },
      err => {
        // console.log(err);
      }
    );
  }
  onChangeCustomer($event){
    this.customersList = [];
    this.listofSelectedCustomers = $event;
      for(var list of this.listofSelectedCustomers){
          this.customersList.push(list.customerId)
        }
  }
  getUserAuditTrail(event: LazyLoadEvent) {
    this.spinnerService.show();
    if (event != undefined) {
      if (event.sortField === undefined) {
        this.sortingObj = { field: "customerName", order: "DESC" };
      } else {
        if (event.sortField === "customerName" && event.sortOrder === -1) {
          this.sortingObj = { field: "customerName", order: "DESC" };
        }
        if (event.sortField === "customerName" && event.sortOrder === 1) {
          this.sortingObj = { field: "customerName", order: "ASC" };
        }

        if (event.sortField === "userName" && event.sortOrder === -1) {
          this.sortingObj = { field: "userName", order: "DESC" };
        }
        if (event.sortField === "userName" && event.sortOrder === 1) {
          this.sortingObj = { field: "userName", order: "ASC" };
        }

        // if (event.sortField === "action" && event.sortOrder === -1) {
        //   this.sortingObj = { field: "action", order: "DESC" };
        // }
        // if (event.sortField === "action" && event.sortOrder === 1) {
        //   this.sortingObj = { field: "action", order: "ASC" };
        // }

        if (event.sortField === "actionValue" && event.sortOrder === -1) {
          this.sortingObj = { field: "actionValue", order: "DESC" };
        }
        if (event.sortField === "actionValue" && event.sortOrder === 1) {
          this.sortingObj = { field: "actionValue", order: "ASC" };
        }
        if (event.sortField === "auditTimestamp" && event.sortOrder === -1) {
          this.sortingObj = { field: "auditTimestamp", order: "DESC" };
        }
        if (event.sortField === "auditTimestamp" && event.sortOrder === 1) {
          this.sortingObj = { field: "auditTimestamp", order: "ASC" };
        }
        if (event.sortField === "message" && event.sortOrder === -1) {
          this.sortingObj = { field: "message", order: "DESC" };
        }
        if (event.sortField === "message" && event.sortOrder === 1) {
          this.sortingObj = { field: "message", order: "ASC" };
        }
      }
    }

    // this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    let customerId = this.customersList.join(',');
    let page: any;
    let size: any;
    let FDate = moment(this.data.fromDate).format("YYYY-MM-DD");
    let TDate = moment(this.data.toDate).format("YYYY-MM-DD");

    if (event != undefined) {
      // this.event = { rows: event.rows, first: event.first };
      size = event.rows;
      page = event.first / event.rows;
    } else {
      size = 25;
      page = 0;
      this.sortingObj = { field: "customerName", order: "DESC" };
    }
    let sortParam = this.sortingObj.field;
    let sortDir = this.sortingObj.order;
    //   if( this.paginationRefresh){
    //     page = this.page - 1;
    // }else{
    //     page = 0;
    // }
    this.CommonServices.getUserAuditTrail(
      token,
      customerId,
      FDate,
      TDate,
      page,
      size,
      sortParam,
      sortDir
    ).subscribe(
      (res) => {
        if (res.code == 200) {
          this.userAuditTrail = res.userAuditTrail;
          this.totalRecords = res.totalElements;
          this.mainAuditTrail = res.userAuditTrail;
          this.mainTotalRecords = res.totalElements;
          // console.log(this.userAuditTrail);
          this.paginationRefresh = false;
          if (this.userAuditTrail.length == 0) {
            this.noData = "No Data Found!";
            this.totalRecords = 0;
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      (err) => {
        // console.log(err);
        this.spinnerService.hide();
        this.text = "No Data Found";
        this.CommonServices.showError('Failed to Load Data', 'Failed')
      }
    );
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((el) => {
      return val.find((element) => {
        return element.field === el.field;
      });
    });
    // restore original order
    // this._selectedColumns = this.cols.filter((a) => val.includes(a));
    localStorage.setItem(
      "selectedAuditTrailsColumns",
      JSON.stringify(this._selectedColumns)
    );
  }
  update(dt: Table) {
    dt.reset();
}
@Input() get selectedColumns(): any[] {
  return this._selectedColumns;
}
search(term: string) {
    if(!term) {
      this.mainAuditTrail = this.userAuditTrail;
      this.mainTotalRecords = this.totalRecords;
    } else {
      // this.mainAuditTrail = this.auditInfo.filter(x => x.deviceMACId.trim().toLowerCase().includes(term.trim().toLowerCase()));
      this.mainAuditTrail =  this.userAuditTrail.filter(obj => Object.values(obj).some(val => val !=null ? val.toString().trim().toLowerCase().includes(term.trim().toLowerCase()) : ""));
      this.mainTotalRecords = this.mainAuditTrail.length;
    }
  }
}
