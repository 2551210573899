import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input, ViewEncapsulation } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { GlobalConstants } from "src/app/constants/global-constants";
import { CommonServices } from "../../../services/commonservice";

@Component({
  selector: 'app-view-loc',
  templateUrl: './view-loc.component.html',
  styleUrls: ['./view-loc.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ViewLocComponent implements OnInit {
  devices: any =[];
  noData: string;
  devicesList: any = [];
  @Input() groupDetails: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  allGroupedDevices: any = [];
  assign: boolean;
  unAssignId: any;
  disabled: boolean = false;

  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  columns = GlobalConstants.GROUPEDDEVICES.cols;

  constructor(
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
  ) { }

  // //sorting
  // key: string = "name"; //set default
  // reverse: boolean = false;
  // sort(key) {
  //   this.key = key;
  //   this.reverse = !this.reverse;
  // }

  //initializing p to one
  // p: number = 1;

  ngOnInit() {
    this.assignedDevices();
    // this.getDevices();
  }

  // getDevices() {
  //   this.spinnerService.show();
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.getDevices(token).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.devices = res.listGetAllDevices;
  //         if (this.devices.length == 0) {
  //           this.noData = "No Data Found!";
  //         }
  //         for(var list of this.allGroupedDevices){
  //           for(var item of this.devices){
  //             if(list.deviceMACId == item.deviceMACId){
  //               this.disabled = true;
  //               item["disable"] = this.disabled;
  //             }
  //           }         
  //         }
  //         console.log(this.devices)
  //         this.spinnerService.hide();
  //       } else {
  //         this.spinnerService.hide();
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //       this.spinnerService.hide();
  //     }
  //   );
  // }

  // onChange(items, event){
  //   let checked = event.target.checked;
  //   if (checked == true){
  //     this.assign = true;
  //     let deviceObj = {};
  //         deviceObj['deviceMACId'] = items.deviceMACId ? items.deviceMACId: null;
  //         deviceObj['customerId'] = items.customerId ? items.customerId: null;
  //         deviceObj['active'] = items.active ? items.active: null;
  //         // deviceObj['universalAccessCode'] =  "Zerv";
  //         deviceObj['installLocation'] = items.locationName ? items.locationName: null;
  //         deviceObj['installSubLocation'] = items.subLocationName ? items.subLocationName: null;
  //         // deviceObj['installLocationFriendlyName'] = items.DeviceKey ? items.DeviceKey: null;
  //         // deviceObj['installSubLocationFriendlyName'] = items.FirmwareVersion ? items.FirmwareVersion: null;
  //         deviceObj['uniqueName'] =  items.uniqueName ? items.uniqueName: null;
  //         deviceObj['deviceGroupId'] = this.groupDetails.deviceGroupId;
  //     this.devicesList.push(deviceObj);
  //   }
  //   if(checked == false){
  //     this.assign = false;
  //   }
  //   // console.log(this.devicesList)
  // }

  // addDevicestoGroup(){
  //  let data = {
  //     "listAddDevicesToGroup" : this.devicesList
  //   }
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.addDevicestoGroup(token, data).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.assignedDevices();
  //         this.getDevices();
  //         this.CommonServices.showSuccess("Devices Added to Group Successfully", "Assigned to Group")
  //       } else {
  //         this.CommonServices.showError("Devices are not Added to Group", "Try Again")
  //         this.getDevices();
  //       }
  //     },
  //     err => {
  //       this.CommonServices.showError("Devices are not Added to Group", "Try Again")
  //     }
  //   );
  // }

  assignedDevices(){
    this.spinnerService.show();
    let Id = this.groupDetails.deviceGroupId
    let token = localStorage.getItem("zerv");
    this.CommonServices.getallGroupedDevices(token,Id).subscribe(
      res => {
        if (res.code == 200) {
          this.allGroupedDevices = res.listGetAllDeviceGroupMapping;
          if (this.allGroupedDevices.length == 0) {
            this.noData = "No Data Found!";
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  onClickBack(){
  // window.location.reload()
  this.onSubmit.emit(false);
  }

  // getUnAssignId(Id){
  //   this.unAssignId = Id
  // }

  // removeDevicefromGroup(Id){
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.unassignDevicefromGroup(token, Id).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.assignedDevices();
  //         this.CommonServices.showSuccess("Device Deleted from Group successfully", "Device Deleted")
  //       } else {
  //         this.CommonServices.showError("Device was not Deleted from Group", "Try Again")
  //       }
  //     },
  //     error => {
  //       this.spinnerService.hide();
  //     }
  //   );
  // }

}
