import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./../auth/auth.service";
import { CommonServices } from "./../services/commonservice";
import { CookieService } from "ngx-cookie-service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import * as moment from "moment";
import { CommonService } from '../services/common.service';
import { ApiService } from '../auth/api.service';
import { decode } from 'punycode';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [CommonServices]
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  private formSubmitAttempt: boolean;
  data: any = {};
  // onSubmitLoading: boolean;
  message: any;
  public onSubmitLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private CommonServices: CommonServices,
    private cookieService: CookieService,
    private spinnerService: Ng4LoadingSpinnerService,
    private apiService: ApiService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    // debugger;
    // this.spinnerService.show();
    let formData = this.data;
    let data = {
      username: formData.userName,
      password: formData.password
    };
    this.onSubmitLoading = true;
    this.message = "";

    this.apiService.login({ data })
      .subscribe(
        res => {
          this.authService.login(res);
          localStorage.setItem("zerv", res.idToken);
          // this.router.navigate(["/dashboard"]);

          // let adminrole = value["custom:role"];
          // let adminrole = this.authService
          //     localStorage.setItem("Role", adminrole);
          if (this.authService.zrole == "zerv") {
            this.onSubmitLoading = false;
            this.router.navigate(["/dashboardZervAdmin"]);
          } else {
            this.onSubmitLoading = false;
            this.router.navigate(["/dashboard"]);
          }
          // if (res.code == 200) {

          //   let val = res.idToken.split(".")[1];
          //   let role1 = atob(val);
          //   let value = JSON.parse(role1);
          //   let date = new Date(value["exp"]);
          //   // console.log(date);
          //   // let dateTime = new Date(value["exp"] * 1000);
          //   // console.log(dateTime);
          //   // let updatedDateTime = moment(dateTime, "H:mm:ss:SS")
          //   //   .add(5, "hours")
          //   //   .add(30, "minutes")
          //   //   .toDate();
          //   // this.cookieService.set("zerv", res.idToken, updatedDateTime);
          //   let adminrole = value["custom:role"];
          //   localStorage.setItem("Role", adminrole);
          //   if (adminrole == "Zerv-Admin") {
          //     this.onSubmitLoading = false;
          //     this.router.navigate(["/dashboardZervAdmin"]);
          //   } else {
          //     this.onSubmitLoading = false;
          //     this.router.navigate(["/dashboard"]);
          //   }
          //   this.onSubmitLoading = false;
          // } else {

          //   this.onSubmitLoading = false;
          //   console.log(this.message);
          //   // this.message = "Invalid UserName or Password";
          // }
          // this.spinnerService.hide();
        },
        err => {
          console.log(err);
          this.onSubmitLoading = true;
          this.spinnerService.hide();
          // this.message = "Invalid UserName or Password";
          this.CommonServices.showError("Invalid UserName or Password", "Not Found")
          this.onSubmitLoading = false;
        }
      );
  }

  // onSubmit() {
  //   let formData = this.data;
  //   if (formData.invalid) {
  //     return;
  //   }
  //   const data = {
  //     userName: this.data.userName, password: this.data.password
  //   };
  //   // let val: any = {};
  //   // val.username = data.userName;
  //   // val.password = data.password;

  //   // val.username = 'Zerv_Cody_Horchak';
  //   // val.password = 'jePqyp-8zudni-camgah';
  //   this.apiService.login({ data })
  //     .subscribe((res) => {
  //       this.commonService.showSuccessMessage(res.message);
  //       this.authService.login(res);
  //       this.router.navigate(["/admin/dashboard"]);
  //       // this.onSigning = false;
  //     }, err => {
  //       this.commonService.showErrorMessage(err);
  //       // this.onSigning = false;
  //     });
  // }

}
