import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';
import {Router} from '@angular/router';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonServices} from '../../services/commonservice';
import {Papa} from 'ngx-papaparse';
import _ from 'lodash';
import {PrimeNGConfig, SortEvent} from 'primeng/api';
import {GlobalConstants} from '../../constants/global-constants';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css'],
  providers: [
    CommonServices,
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true }
    }
  ]
})
export class DevicesComponent implements OnInit {

  @ViewChild('deviceDt') deviceTable: Table;
  cols = GlobalConstants.CLIENT_ADMIN.DEVICE_COLS;
  devices: any;
  allDevices: any;
  allDeviceMacIds = [];
  form: FormGroup;
  filter;
  key = 'name';
  p = 0;
  reverse = false;
  _selectedColumns: any[] = [];
  onSubmitLoading: boolean;
  submitted = false;
  assignDeviceData: any = {};
  deviceMACId: any;
  locations: any;
  sLocation: any;
  universalAccessCode: any;
  locationName: any;
  subLocationName: any;
  lFName: any;
  sLFName: any;
  cardType: any;
  loc: any;
  noData: any;
  deviceUsersDetails: any;
  checkExists: boolean;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('assignDeviceBtnDiabled', { static: false }) public assignDeviceBtnDiabled: ElementRef;
  uploadingRecords: any = [];
  devicesList: any = [];
  customers: any;
  dropdownList: any;
  dropdownSettings: {
    singleSelection;
    allowSearchFilter;
    idField;
    textField;
    selectAllText;
    unSelectAllText;
    itemsShowLimit;
  };
  limitSelection = false;
  listofSelectedCustomerId: any = [];
  listofCustomersId: any;
  shareDeviceBtn: boolean;
  devicesSharedToMe: any = [];
  devicesSharedToOthers: any = [];
  selectedDeviceMacId: any;
  shareDeviceOption: boolean;
  unShareDeviceBtn: boolean;
  unShareDeviceOption: boolean;
  sharedWithCustomersId: any = [];
  uploadResultsModal: boolean;
  uploadResultsmsg: any;
  uploadResultsFailmsg: any;
  itemsPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  totalRecords: any = [];
  paginationRefresh: boolean;
  sortField = 'deviceMACId:0';
  sortOrder = 0;
  currentPage = {first: 0, rows: GlobalConstants.ItemPerPage};
  filterColumn = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private papa: Papa,
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
    for (let i = 0; i < 6; i++) {
      this._selectedColumns.push(this.cols[i]);
    }
    this.dropdownList = this.customers;
    this.listofSelectedCustomerId = [];
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'customerId',
      textField: 'customerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
    };
    this.form = this.fb.group({
      location: ['', Validators.required],
      subLocation: ['', Validators.required],
      locationFriendly: ['', Validators.required],
      subLocationFriendly: ['', Validators.required],
      cardType: ['', Validators.required],
      universalCode: ['']
    });
    this.spinnerService.show();
    this.loadAllDevices();
    this.getDevices();
    this.getLocation();
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }


  spaceValidation(event) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
  getShareCustomerList(Modal) {
    this.onSubmitLoading = true;
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const custId = value['custom:customerId'];
    this.commonServices.getCustomerRelations(token, custId).subscribe(
      res => {
        if (res.code === 'SC_RETRIEVED_SUCCESSFUL') {
          this.customers = res.data.relatedCustomers;
          this.onSubmitLoading = false;
          Modal.show();
          // this.getDeviceSharedTo()
        } else {
          this.onSubmitLoading = false;
          this.commonServices.showError('Not Found', 'Failed');
        }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
        this.commonServices.showError('Not Found', 'Failed');
      }
    );
  }
  // getDeviceSharedTo(){
  //   this.listofSelectedCustomerId = [];
  //   let deviceId = this.selectedDeviceMacId
  //   let token = localStorage.getItem("zerv");
  //   this.commonServices.getDeviceSharedTo(token, deviceId).subscribe(
  //     res => {
  //       if (res.code === "SC_RETRIEVED_SUCCESSFUL") {
  //         this.listofSelectedCustomerId = res.data.relatedCustomers;
  //       }
  //     },
  //   );
  // }
  onChange(customerName, customerId, deviceKey, e) {
    this.selectedDeviceMacId = deviceKey;
    // this.listofCustomersId = customerId;
    const checked = e.target.checked;
    if (checked === true) {
      this.shareDeviceBtn = true;
      this.devicesList.push(deviceKey);
      // console.log(this.devicesList);
    }

    if (checked === false) {
      this.shareDeviceBtn = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a !== deviceKey) {
          return a;
        }
      });
      // console.log(this.devicesList);
    }
  }

  getLocation() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getLocation(token).subscribe(
      res => {

        if (res.code === '200') {
          this.locations = res.listLocations;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();

      }
    );
  }
  getSubLocation(e) {
    const token = localStorage.getItem('zerv');
    this.commonServices.getSubLocation(token, e).subscribe(
      res => {
        if (res.code === '200') {
          this.sLocation = res.listSublocations;
          this.spinnerService.hide();
          // console.log(this.sLocation);
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  getDevices() {
    this.shareDeviceOption = true;
    this.unShareDeviceOption = false;
    this.spinnerService.show();
    this.devicesList = [];
    const token = localStorage.getItem('zerv');
    this.commonServices.getClientDevices(token, this.currentPage.rows,
      this.currentPage.first, this.sortField, this.filterColumn).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.devices = res.listGetDevices;
          this.totalRecords = res.totalDeviceCount;
          if (this.totalRecords === 0) {
            this.noData = 'No Data Found!';
          }
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }


  // TODO: all the upload logic need to be moved to backend
  // for upload...
  loadAllDevices() {
    if (this.allDevices && this.allDevices.length) {
      // already loaded, no need to load again...
      return;
    }
    const token = localStorage.getItem('zerv');
    this.commonServices.getAllClientDevices(token).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.allDevices = res.listGetDevices;
          this.allDevices.forEach ((device, index)  => {
            this.allDeviceMacIds.push(device.deviceMACId);
          });
        }
      },
      err => {
        // console.log(err);
      }
    );
  }

  getDeviceskey(value) {
    this.deviceMACId = value;
    this.devices.filter(item => {
      if (value === item.deviceMACId) {
        (this.lFName = item.locationFriendlyName),
          (this.subLocationName = item.subLocationName),
          (this.locationName = item.locationName),
          (this.sLFName = item.subLocationFriendlyName),
          (this.cardType = item.cardType),
          (this.universalAccessCode = item.universalAccessCode);

        this.assignDeviceData.location = item.locationName;
        this.assignDeviceData.subLocation = item.subLocationName;
        this.assignDeviceData.locationFriendly = item.locationFriendlyName;
        this.assignDeviceData.subLocationFriendly =
          item.subLocationFriendlyName;
        this.assignDeviceData.cardType = item.cardType;
        this.assignDeviceData.universalCode = item.universalAccessCode;
      }
    });
    this.spinnerService.hide();
  }
  assignDevice(assignLocModal) {
    this.buttonDisable(this.assignDeviceBtnDiabled.nativeElement);
    this.spinnerService.show();
    this.submitted = true;
    const formData = this.assignDeviceData;
    formData.location = formData.location.trim();
    formData.subLocation = formData.subLocation.trim();
    formData.locationFriendly = formData.locationFriendly.trim();
    formData.subLocationFriendly = formData.subLocationFriendly.trim();
    const device = {
      deviceMACId: this.deviceMACId,
      installLocation: formData.location,
      installSubLocation: formData.subLocation,
      installLocationFriendlyName: formData.locationFriendly,
      installSubLocationFriendlyName: formData.subLocationFriendly,
      cardType: formData.cardType,
      universalAccessCode: formData.universalCode
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.assignDevice(token, device).subscribe(
      res => {
        assignLocModal.hide();
        this.form.reset();
        if (res.code === '200') {
          this.commonServices.showSuccess(res.message, 'Success');
          this.getDevices();
        } else {
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        this.commonServices.showError('Failed to Load Data', 'Error');
        this.spinnerService.hide();
      }
    );
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  getDeviceUsersById(Id, Modal) {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getDeviceUsersById(token, Id).subscribe(
      res => {
        this.spinnerService.hide();
        Modal.show();
        if (res.code === '200') {
          this.deviceUsersDetails = res.data;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  public changeListener(event) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: string = reader.result as string;
        // console.log(csv);
        this.uploadCSVdata(csv);
      };
    }
    this.reset();
  }

  uploadCSVdata(csv) {
    const uploadingRecords = [];
    const failedRecords = [];
    const filteredRecords = [];
    this.papa.parse(csv, {
      skipEmptyLines: true,
      header: true,
      complete: (results) => {
        for (let i = 0; i < results.data.length; i++) {
          const device = results.data[i];
          uploadingRecords.push(device);
        }
      }
    });
    if (uploadingRecords.length === 0) {
      if (failedRecords.length > 0) {
        this.uploadResultsFailmsg = 'Failed to upload ' + failedRecords.length + ' Devices';
      }
    } else {
      uploadingRecords.reverse();
      this.uploadAdevice(uploadingRecords, 0, 0);
    }
  }

  uploadAdevice(uploadingRecords, failedRecordsCount, count) {
    if (!uploadingRecords || uploadingRecords.length === 0) {
      this.spinnerService.hide();
      this.getDevices();
      return failedRecordsCount;
    }
    const device = uploadingRecords.pop();
    device.DeviceMACId = device.DeviceMACId.trim();
    device.Location = device.Location.trim();
    device.SubLocation = device.SubLocation.trim();
    device.LocationFriendly = device.LocationFriendly.trim();
    device.SubLocationFriendly = device.SubLocationFriendly.trim();
    device.CardType = device.CardType.trim();
    device.UniversalCode = device.UniversalCode.trim();
    this.deviceMACId = device.DeviceMACId ? device.DeviceMACId : null;
    this.assignDeviceData.location = device.Location ? device.Location : null;
    this.assignDeviceData.subLocation = device.SubLocation ? device.SubLocation : null;
    this.assignDeviceData.locationFriendly = device.LocationFriendly ? device.LocationFriendly : null;
    this.assignDeviceData.subLocationFriendly = device.SubLocationFriendly ? device.SubLocationFriendly : null;
    this.assignDeviceData.cardType = device.CardType ? device.CardType : null;
    this.assignDeviceData.universalCode = device.UniversalCode ? device.UniversalCode : null;
    const formData = this.assignDeviceData;

    const d = {
      deviceMACId: device.DeviceMACId ? device.DeviceMACId : null,
      installLocation: formData.location,
      installSubLocation: formData.subLocation,
      installLocationFriendlyName: formData.locationFriendly,
      installSubLocationFriendlyName: formData.subLocationFriendly,
      cardType: formData.cardType,
      universalAccessCode: formData.universalCode
    };

    const token = localStorage.getItem('zerv');
    this.commonServices.assignDevice(token, d).subscribe(
      res => {
        if (res.code === '200') {
          this.form.reset();
          this.uploadResultsModal = true;
          count += 1;
          this.uploadResultsmsg = 'Assigned ' + count + ' Device(s) successfully.';
          this.uploadAdevice(uploadingRecords, failedRecordsCount, count);
        } else {
          failedRecordsCount += 1;
          if (failedRecordsCount > 0) {
            this.uploadResultsFailmsg = 'Failed to assign ' + failedRecordsCount + ' device(s)';
          }
          this.uploadAdevice(uploadingRecords, failedRecordsCount, count);
        }
      },
      err => {
        failedRecordsCount += 1;
        if (failedRecordsCount > 0) {
          this.uploadResultsFailmsg = 'Failed to upload ' + failedRecordsCount + ' Devices';
        }
        this.uploadAdevice(uploadingRecords, ++failedRecordsCount, count);
        // this.commonServices.showError('Failed to Load Data', 'Error');
      }
    );
  }

  reset() {
    this.fileUpload.nativeElement.value = '';
  }
  shareDevicesToCustomers(modal) {
    let customerIds = [];
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const custId = value['custom:customerId'];
    for (const id of this.listofSelectedCustomerId) {
      customerIds.push(id.customerId);
    }
    let data: any;
    data = {
      customerId : custId,
      relCustomerIds : customerIds,
      deviceMacIds : this.devicesList
    };
    this.commonServices.shareDevicesToCustomers(token, data).subscribe(
      res => {
        if (res.code === 'SC_UPDATED_SUCCESSFUL') {
          customerIds = [];
          this.devicesList = [];
          this.listofSelectedCustomerId = [];
          modal.hide();
          this.commonServices.showSuccess('Shared Successfully', 'Success');
          this.getDevices();
        } else {
          this.listofSelectedCustomerId = [];
          modal.hide();
          this.commonServices.showError('Failed to Share', 'Failed');
        }
      },
      err => {
        console.log(err);
        this.listofSelectedCustomerId = [];
        modal.hide();
        this.commonServices.showError('Failed to Share', 'Failed');
      }
    );
  }
  closeShareDeviceModal(modal) {
    this.listofSelectedCustomerId = [];
    modal.hide();
  }
  unlockDevice(Id) {
    this.spinnerService.show();
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    let data: any;
    data = { deviceId };
    this.commonServices.unlockDevice(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.commonServices.showSuccess('Device Unlocked Successfully', 'Success');
          this.getDevices();
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Failed to Unlock Device', 'Failed');
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        this.commonServices.showError('Failed to Unlock Device', 'Failed');
      }
    );
  }
  pingDevice(Id) {
    this.spinnerService.show();
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    const data = { deviceId };
    this.commonServices.pingDevice(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.commonServices.showSuccess('Device Pinged Successfully', 'Success');
          this.getDevices();
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Failed to Ping Device', 'Failed');
        }
      },
      err => {
        this.spinnerService.hide();
        console.log(err);
        this.commonServices.showError('Failed to Ping Device', 'Failed');
      }
    );
  }
  rebootDevice(Id) {
    this.spinnerService.show();
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    const data = { deviceId };
    this.commonServices.rebootDevice(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.commonServices.showSuccess('Device Rebooted Successfully', 'Success');
          this.getDevices();
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Failed to Reboot Device', 'Failed');
        }
      },
      err => {
        this.spinnerService.hide();
        console.log(err);
        this.commonServices.showError('Failed to Reboot Device', 'Failed');
      }
    );
  }
  getSharedDevicesByTenants() {
    this.shareDeviceOption = false;
    this.unShareDeviceOption = false;
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getSharedDevicesByTenants(token).subscribe(
      res => {
        if (res.code === '200') {
          this.devicesSharedToMe = res.listGetDevices;
          if (this.devices.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  getSharedDevicesToTenants() {
    this.shareDeviceOption = false;
    this.unShareDeviceOption = true;
    this.spinnerService.show();
    this.devicesList = [];
    const token = localStorage.getItem('zerv');
    this.commonServices.getSharedDevicesToTenants(token).subscribe(
      res => {
        if (res.code === '200') {
          this.devicesSharedToOthers = res.listGetDevicesSharedToOthers;
          if (this.devices.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  onChangeUnShare(customersId, deviceMACId, e) {
    const checked = e.target.checked;
    if (checked === true) {
      this.unShareDeviceBtn = true;
      this.sharedWithCustomersId.push(customersId);
      this.devicesList.push(deviceMACId);
    }

    if (checked === false) {
      this.unShareDeviceBtn = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a !== deviceMACId) {
          return a;
        }
      });
    }
  }
  unShareDevice() {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const custId = value['custom:customerId'];
    let data: any;
    data = {
      customerId: custId,
      relCustomerIds: this.sharedWithCustomersId,
      deviceMacIds: this.devicesList
    };
    this.commonServices.unShareDevice(token, data).subscribe(
      res => {
        if (res.code === 'SC_UPDATED_SUCCESSFUL') {
          this.commonServices.showSuccess('Updated Successfully', 'Success');
          this.spinnerService.hide();
          this.getSharedDevicesToTenants();
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Failed to Update', 'Failed');
        }
      },
      err => {
        this.spinnerService.hide();
      }
    );
  }

  pageChanged($event) {
    this.currentPage.rows = $event.rows;
    this.currentPage.first = $event.first;
    this.paginationRefresh = true;
    this.getDevices();
  }

  customSort(event) {
    const field = event.field;
    if (field !== 'deviceMACId') {
      return;
    }
    if (this.sortOrder === 0) {
      this.sortOrder = 1;
    } else {
      this.sortOrder = 0;
    }
    this.reverse = !this.reverse;
    this.sortField = field + ':' + this.sortOrder;
    this.currentPage.rows = GlobalConstants.ItemPerPage;
    this.currentPage.first = 0;
    this.getDevices();
  }

  onClearSearch(event) {
    const value = event.target.value;
      this.onClickSearch(value);
  }

  onClickSearch(value) {
    this.currentPage.rows =  25;
    this.currentPage.first = 0;
    const searchString = value;
    if (searchString === '') {
      this.filterColumn = '';
    } else {
      this.filterColumn = 'deviceMACId:' + searchString + ','
        + 'cardType:' + searchString + ','
        + 'subLocationName:' + searchString + ','
        + 'locationName:' + searchString + ','
        + 'subLocationFriendlyName:' + searchString + ','
        + 'locationFriendlyName:' + searchString + ','
        + 'universalAccessCode:' + searchString + ','
        + 'firmwareVersion:' + searchString + ','
        + 'deviceKey:' + searchString;
    }
    this.getDevices();

  }
}

