import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CommonServices} from '../../../services/commonservice';
import {FormBuilder, NgForm} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {user} from '../user.model';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {UsersComponent} from '../../../client-admin/users/users.component';
import {IUserAccessModel} from '../../../model/user-access.model';
import {IGroupAccessModel} from '../../../model/group-access.model';
import {GlobalConstants} from '../../../constants/global-constants';

@Component({
  selector: 'app-user-access-groups',
  templateUrl: './user-access-groups.component.html',
  styleUrls: ['./user-access-groups.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CommonServices]
})
export class UserAccessGroupsComponent implements OnInit {
  @Input() userGroupDetails: any = {};
  @Input() userDetails: any = {};
  @Input() isEdit;
  @Output() loadOnBack = new EventEmitter<any>();
  selectedItems = [];
  selectedGroup;
  availableGroups;
  selectedSubLocItems = [];
  dropdownSettings = {};
  dropdownSubList = [];
  deviceGroups: any = [];
  listofGroups: any = [];

  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cardFormatList: any = [];
  itemSelect: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('timeZone', { static: true }) timeZone: ElementRef;
  @ViewChild('saveGroupBtnDisbaled', { static: false }) saveGroupBtnDisbaled: ElementRef;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  allChecked = true;
  days = [];
  data: any = {
    addresses: []
  };
  addressFields = ['facilityId', 'badgeId', 'cardFormat', 'antiPassBack', 'range'];
  userAddress: any = [];
  addressSubLocations: any = [];
  subLocations: any = [];
  listSubLocations: any = [];
  removedSubLocations: any = [];
  user: user;
  selectedSubLocation: any = [];
  locations: any;
  accessPoint: any = [];
  genericAccessCode: any = [];
  badgeId: any = [];
  removedExistingAccess: any = [];
  removeExistingAccessDuration: any = [];
  todayDate: NgbDateStruct = undefined;
  phExists = false;
  credentialIdentifier: any = [];
  sDate: any;
  eDate: any;
  range: any = '10';
  antiPassBack: any = [];
  refreshCredentialFrequency: any;
  showAssignedDevices: boolean;
  groupedDevices: any = {};
  bgId: any;
  facId: any;
  accTime: any = [];
  groupAccTime: any = [];

  isChecked = true;
  startTime: any = {};
  endTime: any = {};
  startDate: any = [];
  endDate: any = [];
  label: any = [];

  sundayStartTime: any = [];
  mondayStartTime: any = [];
  tuesdayStartTime: any = [];
  wednesdayStartTime: any = [];
  thursdayStartTime: any = [];
  fridayStartTime: any = [];
  satdayStartTime: any = [];
  sundayEndTime: any = [];
  mondayEndTime: any = [];
  tuesdayEndTime: any = [];
  wednesdayEndTime: any = [];
  thursdayEndTime: any = [];
  fridayEndTime: any = [];
  satdayEndTime: any = [];
  indexValue: any;
  sundayChecked: any = [];
  mondayChecked: any = [];
  tuesdayChecked: any = [];
  wednesChecked: any = [];
  thursdayChecked: any = [];
  fridayChecked: any = [];
  saturdayChecked: any = [];

  sunday: any;
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunAccess = false;
  monAccess = false;
  tueAccess = false;
  wedAccess = false;
  thuAccess = false;
  friAccess = false;
  satAccess = false;
  tzDeviceId: any;
  sundayValues: any;
  mondayValues: any;
  tuesdayValues: any;
  wednesdayValues: any;
  thursdayValues: any;
  fridayValues: number;
  saturdayValues: any;
  subLocModal: BsModalRef;
  phNumber: any;
  existedGroups: any = [];
  removedExistingUserDeviceGroudIds: any = [];
  PHnumber: any;
  listofGroupedDevices: any = [];
  deviceGroupId: any;
  facilityIdError = false;
  facilityIdErrMessage: any;
  badgeIdError = false;
  badgeIdErrMessage: any;
  invalidTime = false;

  constructor(
    private fb: FormBuilder,
    private commonServices: CommonServices,
    private usersComponent: UsersComponent,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalServices: BsModalService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {
    this.user = new user();
    // this.data.addresses.push({});
    this.todayDate = calendar.getToday();
  }
  // sorting
  key = 'name'; // set default
  reverse = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  ngOnInit() {
    this.itemSelect = '';
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: this.ShowFilter,
      idField: 'deviceGroupId',
      textField: 'deviceGroupName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
    };
    this.getCardFormatList();
    this.getLocations();
    this.getListofGroups();
    this.getUserById(this.userGroupDetails.phoneNumber);
  }

  isThereAnUnsavedGroup() {
    if (this.selectedItems.length === 0) {
      return false;
    }
    const ng = this.selectedItems[this.selectedItems.length - 1];
    return (ng.isNewGroup === true || ng.isNotSaved === true);
  }

  spaceValidation(event) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43) {
      return false;
    }
    return true;

  }

  onChangeCardFormat() {
    this.facilityIdError = false;
    this.data.facilityId = '';
    this.badgeIdError = false;
    this.data.accessCode = '';
  }

  facilityIdVAlidation(event, data, isUserProfile) {
    const error = {isValid: false, errorMsg: ''};
    if (this.data.cardFormat === 'HID Prox 26-bit H10301' || this.data.cardFormat === '26-bit QuadReal Commerce Place' ||
      this.data.cardFormat === 'HID Prox 36-bit C10202') {
      if (event > 255 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'FacilityId should be in range of 0 to 255';
      }
    } else if (this.data.cardFormat === 'HID Prox 35-bit C1000' || this.data.cardFormat ===  'HID Prox 37-bit H10304') {
      if (event > 65535 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'FacilityId should be in range of 0 to 65535';
      }
    } else if (this.data.cardFormat === 'HID Prox 37-bit H10302') {
      if (event < 0) {
        error.isValid = true;
        error.errorMsg = 'FacilityId should be Minmum value 0';
      }
    }
    if (!isUserProfile)  {
      data['facilityIdErrMessage'] = error.errorMsg;
      return error.isValid;
    } else {
      this.facilityIdError = error.isValid;
      this.facilityIdErrMessage = error.errorMsg;
    }
    return false;
  }

  badgeIdVAlidation(event, data, isUserProfile) {
    const error = {isValid: false, errorMsg: ''};
    if (this.data.cardFormat === 'HID Prox 26-bit H10301' || this.data.cardFormat === '26-bit QuadReal Commerce Place') {
      if (event > 65535 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'BadgeId should be in range of 0 to 65535';
      } else {
        error.isValid = false;
      }
    } else if (this.data.cardFormat === 'HID Prox 35-bit C1000' || this.data.cardFormat === 'HID Prox 36-bit C10202') {
      if (event > 67108864 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'BadgeId should be in range of 0 to 67108864';
      } else {
        error.isValid = false;
      }
    } else if (this.data.cardFormat === 'HID Prox 37-bit H10304') {
      if (event > 524287 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'BadgeId should be in range of 0 to 524287';
      } else {
        error.isValid = false;
      }
    } else if (this.data.cardFormat === 'HID Prox 37-bit H10302') {
      if (event > 34359738367 || event < 0) {
        error.isValid = true;
        error.errorMsg = 'BadgeId should be in range of 0 to 34359738367';
      } else {
        error.isValid = false;
      }
    }

    if (!isUserProfile)  {
      data['badgeIdErrMessage'] = error.errorMsg;
      return error.isValid;
    } else {
      this.badgeIdError = error.isValid;
      this.badgeIdErrMessage = error.errorMsg;
    }
    return false;
  }
  validateAntiPassBack(i, event) {
    if (event > 100) {
      this.data.addresses[i].antiPassBack = 100;
    } else if (event < 0) {
      this.data.addresses[i].antiPassBack = 0;
    } else {
      this.data.addresses[i].antiPassBack = event;
    }
    if (!event || event === '') {
      this.data.addresses[i].antiPassBack = 0;
    }
  }

  validateRange(i, event) {
    if (this.data.addresses[i]) {
      if (event > 100) {
        this.data.addresses[i].range = 100;
      } else if (event < 0) {
        this.data.addresses[i].range = 0;
      } else {
        this.data.addresses[i].range = event;
      }
    }
  }

  onClickBacktoUsers() {
    // window.location.reload()
    this.loadOnBack.emit(false);
    this.usersComponent.getUsersList();
  }

  openModal(confirmationDelete: TemplateRef<any>, group, action) {
    this.modalRef = this.modalServices.show(confirmationDelete, {
      class: 'modal-sm',
      backdrop: 'static',
      initialState: { data: group, action },
      keyboard: false
    });
  }

  onClickBack() {
    this.showAssignedDevices = false;
    this.groupedDevices = '';
  }

  onModelYes() {
    const state = this.modalServices.config.initialState;
    // @ts-ignore
    if (state.action === 'DELETE_GROUP') {
      // @ts-ignore
      this.deleteGroup(state.data);
      this.decline();
    }
  }

  deleteGroup(group) {
    // @ts-ignore
    if (group.isNotSaved || group.isNewGroup) {
      this.selectedItems.pop();
      this.selectedGroup = [];
    } else {
      // @ts-ignore
      this.addDeviceGrouptoUser(null, [group.userDeviceGroupId]);
    }
    this.filterGroups();
  }

  saveGroup(group) {
    this.addDeviceGrouptoUser(group, []);
  }

  isNotValidGroup(group, indx) {
    if (group.isNewGroup) {
      return true;
    }

    if (group.facilityId === ''
      || group.accessCode === ''
      || group.antiPassBack === '') {
      return true;
    }
    if (this.facilityIdVAlidation(group.facilityId, group, false)) {
      return true;
    } else {
      delete group.facilityIdErrMessage;
    }
    if (this.badgeIdVAlidation(group.accessCode, group, false)) {
      return true;
    } else {
      delete group.badgeIdErrMessage;
    }
    if (group.range) {
      return this.validateRange(indx, group.range);
    }
  }

  filterGroups() {
    this.availableGroups = this.listofGroups.filter(i => !this.selectedItems.find(f => f.deviceGroupId === i.deviceGroupId)); // Second Way
  }

  onChangeGroup($event, group) {
    const selectedGroup = $event.value;
    if (selectedGroup) {
      const date = new Date(new Date().setDate(new Date().getDate() - 1));
      this.startDate[this.indexValue] = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      this.endDate[this.indexValue] = {year: this.todayDate.year + 10, month: this.todayDate.month, day: this.todayDate.day};
      this.dateCheck();
      const newGroup = Object.assign({}, selectedGroup);
      newGroup.facilityId = selectedGroup.facilityId ? selectedGroup.facilityId : this.data.facilityId;
      newGroup.accessCode = selectedGroup.accessCode ? selectedGroup.accessCode : this.data.accessCode;
      newGroup.facilityId = selectedGroup.facilityId ? selectedGroup.facilityId : this.data.facilityId;
      newGroup.accessCode = selectedGroup.accessCode ? selectedGroup.accessCode : this.data.accessCode;
      newGroup.cardFormat = selectedGroup.cardformat ? selectedGroup.cardFormat : this.data.cardFormat;
      newGroup.range = selectedGroup.range ? selectedGroup.range : '10';
      newGroup.antiPassBack = selectedGroup.antiPassBack ? selectedGroup.antiPassBack : '10';
      newGroup.isNotSaved = true;
      newGroup.accessStartDate = newGroup.accessStartDate ? newGroup.accessStartDate : this.sDate;
      newGroup.accessEndDate = newGroup.accessEndDate ? newGroup.accessEndDate : this.eDate;
      newGroup.sunAccess = newGroup.sunAccess ? newGroup.sunAccess : true;
      newGroup.sun_access_end_time = newGroup.sun_access_end_time ? newGroup.sun_access_end_time : '23:59';
      newGroup.sun_access_start_time = newGroup.sun_access_start_time ? newGroup.sun_access_start_time : '00:00';
      newGroup.monAccess = newGroup.monAccess ? newGroup.monAccess : true;
      newGroup.mon_access_end_time = newGroup.mon_access_end_time ? newGroup.mon_access_end_time : '23:59';
      newGroup.mon_access_start_time = newGroup.mon_access_start_time ? newGroup.mon_access_start_time : '00:00';
      newGroup.tueAccess = newGroup.tueAccess ? newGroup.tueAccess : true;
      newGroup.tue_access_end_time = newGroup.tue_access_end_time ? newGroup.tue_access_end_time : '23:59';
      newGroup.tue_access_start_time = newGroup.tue_access_start_time ? newGroup.tue_access_start_time : '00:00';
      newGroup.wedAccess = newGroup.wedAccess ? newGroup.wedAccess : true;
      newGroup.wed_access_end_time = newGroup.wed_access_end_time ? newGroup.wed_access_end_time : '23:59';
      newGroup.wed_access_start_time = newGroup.wed_access_start_time ? newGroup.wed_access_start_time : '00:00';
      newGroup.thuAccess = newGroup.thuAccess ? newGroup.thuAccess : true;
      newGroup.thu_access_end_time = newGroup.thu_access_end_time ? newGroup.thu_access_end_time : '23:59';
      newGroup.thu_access_start_time = newGroup.thu_access_start_time ? newGroup.thu_access_start_time : '00:00';
      newGroup.friAccess = newGroup.friAccess ? newGroup.friAccess : true;
      newGroup.fri_access_end_time = newGroup.fri_access_end_time ? newGroup.fri_access_end_time : '23:59';
      newGroup.fri_access_start_time = newGroup.fri_access_start_time ? newGroup.fri_access_start_time : '00:00';
      newGroup.satAccess = newGroup.satAccess ? newGroup.satAccess : true;
      newGroup.sat_access_end_time = newGroup.sat_access_end_time ? newGroup.sat_access_end_time : '23:59';
      newGroup.sat_access_start_time = newGroup.sat_access_start_time ? newGroup.sat_access_start_time : '00:00';
      newGroup.timeAccessId = 'new_link_timeAccessId';
      newGroup.marginBottomClass = 'mb-40';
      this.selectedItems[this.selectedItems.length - 1] = newGroup;
      this.scrollToView('new_link_timeAccessId');
    } else {
      group.isNewGroup = true;
    }
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }
  getCardFormatList() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getCardFormatList(token).subscribe(
      res => {
        if (res.code === '200') {
          this.cardFormatList = res.listGetCardType;
        }
      },
      err => {
        // console.log(err);
      }
    );
  }
  getListofGroups() {
    const groupType = 'Custom-Type';
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    this.commonServices.getallclientDevicegroups(token, groupType, customerId).subscribe(
      res => {
        if (res.code === '200') {
          this.listofGroups = res.listGetAllDeviceGroup;
          this.filterGroups();
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getUserGroups() {
    let phNumber = this.PHnumber;
    if (!phNumber) {
      phNumber = this.userGroupDetails.phoneNumber;
    }
    if (!phNumber) {
      return;
    }

    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    this.spinnerService.show();
    this.commonServices.getUserGroupsList(token, phNumber, customerId).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.selectedItems = res.listUserDeviceGroupResponse;
          this.existedGroups = res.listUserDeviceGroupResponse;
          this.filterGroups();
        }
      },
      err => {
        this.spinnerService.hide();
      }
    );
  }

  addNewGroup() {
    this.selectedItems.push(GlobalConstants.CLIENT_ADMIN.USER_NEW_GROUP_DEFAULT_DATA);
    this.scrollToView('adding_newgroup');

  }

  scrollToView(elementId) {
    setTimeout(() => {
      if (document.getElementById(elementId)) {
        const element = document.getElementById(elementId) as HTMLElement;
        element.scrollIntoView();
      }
    }, 500);
  }

  addDeviceGrouptoUser(group, removedGroudIds) {
    this.buttonDisable(this.saveGroupBtnDisbaled.nativeElement);
    const deviceGroupAccess = [];
    if (group) {
      group.id = group.userDeviceGroupId ? group.userDeviceGroupId: '0';
      deviceGroupAccess.push(group);
    }
    const token = localStorage.getItem('zerv');
    let serviceUrl;
    let data: any;
    if (group && group.isNotSaved) {
      data = {
        listPhonenumbers: [{
          phoneNumber : this.data.phoneNumber
        }],
        listDeviceGroupIds : deviceGroupAccess
      };
      serviceUrl = this.commonServices.addDeviceGrouptoUser(token, data);
    } else {
      data = {
        listDeviceGroupIds : deviceGroupAccess,
        removedExistingUserDeviceGroudIds : removedGroudIds
      };
      this.PHnumber = this.PHnumber.replace('+', '');
      serviceUrl = this.commonServices.UpdateDeviceGroupstoUser(token, data, this.PHnumber);
    }
    this.spinnerService.show();
    serviceUrl.subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.selectedGroup = [];
          this.removedExistingUserDeviceGroudIds = [];
          this.getUserGroups();
          this.commonServices.showSuccess(res.message, 'Success');
          this.scrollToView('add-new-button');
        } else {
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        this.removedExistingUserDeviceGroudIds = [];
        this.spinnerService.hide();
        this.commonServices.showError('Failed to save Group', 'Failed');
      }
    );
  }

  openGroupTzModal(address, timeZone: TemplateRef<any>, i) {
    this.getGroupTimeAccess(address, i);
    this.modalRef = this.modalServices.show(timeZone, { class: 'modal-lg', backdrop: false, keyboard: false });
  }
  openTzModal(address, timeZone: TemplateRef<any>, i) {
    this.getInitalTimeAccess(address, i);
    this.modalRef = this.modalServices.show(timeZone, { class: 'modal-lg', backdrop: false, keyboard: false });
  }
  closeTzModal(): void {
    this.modalRef.hide();
  }
  decline(): void {
    this.modalRef.hide();
  }
  viewDevices(item) {
    this.groupedDevices = item;
    this.showAssignedDevices = true;
  }

  getListofGroupedDevices(Id) {
    const token = localStorage.getItem('zerv');
    this.commonServices.getallGroupedDevices(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.listofGroupedDevices = res.listGetAllDeviceGroupMapping;
          for (const list of this.data.addresses) {
            for (const item of this.listofGroupedDevices) {
              if (list.accessPoint === item.deviceMACId) {
                list.disable = true;
              } else {
                list.disable = false;
              }
            }
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getUserById(userId) {
    if (this.data.phoneNumber) {
      if (!userId) {
        userId = this.data.phoneNumber;
      }
    }
    if (!userId) {
      return;
    }
    this.addressSubLocations = [];
    const token = localStorage.getItem('zerv');
    let custId = this.userGroupDetails.customerId;
    if (!custId) {
      const val = token.split('.')[1];
      const role1 = atob(val);
      const value = JSON.parse(role1);
      custId = value['custom:customerId'];
    }
    this.spinnerService.show();
    this.commonServices.getUserById(token, userId, custId).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.data = res;
          this.data.firstName = res.name.substr(
            0,
            res.name.indexOf(' ')
          );
          this.data.lastName = res.name.substr(
            res.name.indexOf(' ') + 1
          );
          this.data.phoneNumber = '+' + res.phoneNumber;
          this.PHnumber = res.phoneNumber;
          this.facId = this.data.facilityId;
          this.bgId = this.data.accessCode;
          this.userAddress = this.commonServices.copyObject(res.listGetUserAccess);
          const listofUngroupedDevices = [];
          for (const list of this.userAddress) {
            if (list.isGroupedDevice === false || list.isGroupedDevice === null) {
              listofUngroupedDevices.push(list);
            }
          }
          this.data.addresses = listofUngroupedDevices;
          if (this.data.addresses) {
            (this.data.addresses || []).map((add, i) => {
              add.genericAccessCode = add.accessCode;
              add.badgeId = add.accessCode;
              add.subLocation = add.accessPoint;
              add.facilityId = add.facilityId ? add.facilityId : this.data.facilityId;
              add.accessCode = add.accessCode ? add.accessCode : this.data.accessCode;
              add.cardFormat = add.cardFormat ? add.cardFormat : this.data.cardFormat;
              this.addressSubLocations[i] = this.data.addresses[i].subLocation;
            });
          }
          if (this.addressSubLocations.length > 0) {
            for (let i = 0; i < res.listGetUserAccess.length; i++) {
              if (this.data.addresses[i]) {
                this.getSubLocations(this.data.addresses[i].location, i, true);
              }
            }
          }
          this.getUserGroups();
        } else {
          this.commonServices.showError('Failed to Load Data from Server', 'Failed');
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.commonServices.showError('Failed to Load Data from Server', 'Failed');
      }
    );
  }
  getLocations() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getLocation(token).subscribe(
      res => {
        if (res.code === '200') {
          this.locations = res.listLocations;
        }
      },
      err => {
        // console.log(err);
      }
    );
  }
  getSubLocations(ev, i, onEdit?: boolean) {
    this.subLocations[i] = [];
    if (!onEdit) {
      this.clearAccessPoint(i);
    }
    const token = localStorage.getItem('zerv');
    this.commonServices.getSubLocation(token, ev).subscribe(
      res => {
        if (res.code === '200') {
          this.listSubLocations = res.listSublocations;
          this.subLocations[i] = res.listSublocations;
          if (this.subLocations && this.subLocations.length > 0) {
            if (res && res.listSublocations && res.listSublocations.length > 0) {
            }
            if (this.removedSubLocations && this.removedSubLocations.length > 0) {
            }
          }
        }
      },
      err => {
      }
    );
  }
  clearAccessPoint(index) {
    this.data.addresses[index].accessPoint = null; // device id
    this.data.addresses[index].genericAccessCode = null;
    this.data.addresses[index].badgeId = null;
    this.data.addresses[index].facilityId = null;
    this.data.addresses[index].credentialIdentifier = null;
    this.data.addresses[index].range = null;
    this.data.addresses[index].cardFormat = null;
    this.data.addresses[index].antiPassBack = null;
  }

  validateAddress(address, devicesTabForm: NgForm) {
    for (const field in this.addressFields) {
      if (address[field] === '') {
        break;
      }
    }
    return false;
  }

  addNewAddress() {
    this.userAddress = [];
    this.user = new user();
    const address = this.commonServices.copyObject(this.data.addresses);
    address[(address.length)] = {isNewGroup: true, antiPassBack: 10, range: 10};
    (address || []).map((add) => {
      add['facilityId'] = add.facilityId ? add.facilityId: this.data.facilityId;
      add['badgeId'] = add.badgeId ? add.badgeId: this.data.accessCode;
      add['cardFormat'] = add.cardFormat ? add.cardFormat: this.data.cardFormat;
      add['antiPassBack'] = add.antiPassBack ? add.antiPassBack: '10';
      add['range'] = add.range ? add.range: '10';
      this.userAddress.push(add);
    });
    this.data.addresses = address;
    this.selectedSubLocation = [];
  }
  removeLocationAccess(item, index) {
    if (item && item.id) {
      this.removedExistingAccess.push(item.id);
      this.removeExistingAccessDuration.push(item.userAccessDurationId);
      // this.addRemovedSubLoactionAccess(item.subLocation);
    }
    this.addressSubLocations.splice(index, 1);
    this.userAddress.splice(index, 1);
    this.data.addresses.splice(index, 1);
    this.subLocations.splice(index, 1);
  }
  getAccess(subloc, index) {
    if (subloc) {
      const subLocation = {};
      (this.subLocations || []).forEach(userSubLocs => {
        (userSubLocs || []).forEach(userSubLoc => {
          if (userSubLoc.accessPoint === subloc) {
            // this.addressSubLocations[index] = subloc;
            this.data.addresses[index].accessPoint = userSubLoc.accessPoint; // device id
            this.data.addresses[index].genericAccessCode = userSubLoc.genericAccessCode;
            this.data.addresses[index].badgeId = userSubLoc.genericAccessCode ? userSubLoc.genericAccessCode : this.data.accessCode;
            this.data.addresses[index].facilityId = this.data.facilityId;
            this.data.addresses[index].cardFormat = this.data.cardFormat;
            this.data.addresses[index].antiPassBack = userSubLoc.antiPassBack ? userSubLoc.antiPassBack : '10';
            this.data.addresses[index].range = userSubLoc.range ? userSubLoc.range : '10';
          }
        });
      });
    }
  }
  changeWeek(event) {
    this.days.forEach(obj => {
      if (obj.label === event.target.name) {
        obj['isChecked'] = event.target.checked;
        obj['startTime'] = obj.startTime ? obj.startTime : '00:00';
        obj['endTime'] = obj.endTime ? obj.endTime : '23:59';
      }
    });
    if (event.target.name === 'Sunday') {
      if (this.days[0].isChecked) {
        this.sunAccess = this.days[0].isChecked;
        this.sundayChecked[this.indexValue] = this.days[0].isChecked;
        this.sunday = [{ sun_access_start_time: this.days[0].startTime, sun_access_end_time: this.days[0].endTime }];
        this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime;
      } else {
        this.allChecked = false;
        this.sundayChecked[this.indexValue] = this.days[0].isChecked;
        this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime;
      }
    }

    if (event.target.name === 'Monday') {
      if (this.days[1].isChecked) {
        this.monAccess = this.days[1].isChecked;
        this.mondayChecked[this.indexValue] = this.days[1].isChecked;
        this.monday = [{ mon_access_start_time: this.days[1].startTime, mon_access_end_time: this.days[1].endTime }];
        this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime;
      } else {
        this.allChecked = false;
        this.mondayChecked[this.indexValue] = this.days[1].isChecked;
        this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime;
      }
    }

    if (event.target.name === 'Tuesday') {
      if (this.days[2].isChecked) {
        this.tueAccess = this.days[2].isChecked;
        this.tuesdayChecked[this.indexValue] = this.days[2].isChecked;
        this.tuesday = [{ tue_access_start_time: this.days[2].startTime, tue_access_end_time: this.days[2].endTime }];
        this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime;
      } else {
        this.allChecked = false;
        this.tuesdayChecked[this.indexValue] = this.days[2].isChecked;
        this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime;
      }
    }

    if (event.target.name === 'Wednesday') {
      if (this.days[3].isChecked) {
        this.wedAccess = this.days[3].isChecked;
        this.wednesChecked[this.indexValue] = this.days[3].isChecked;
        this.wednesday = [{ wed_access_start_time: this.days[3].startTime, wed_access_end_time: this.days[3].endTime }];
        this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime;
      } else {
        this.allChecked = false;
        this.wednesChecked[this.indexValue] = this.days[3].isChecked;
        this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime;
      }
    }

    if (event.target.name === 'Thursday') {
      if (this.days[4].isChecked) {
        this.thuAccess = this.days[4].isChecked;
        this.thursdayChecked[this.indexValue] = this.days[4].isChecked;
        this.thursday = [{ thu_access_start_time: this.days[4].startTime, thu_access_end_time: this.days[4].endTime }];
        this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime;
      } else {
        this.allChecked = false;
        this.thursdayChecked[this.indexValue] = this.days[4].isChecked;
        this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime;
      }
    }

    if (event.target.name === 'Friday') {
      if (this.days[5].isChecked) {
        this.friAccess = this.days[5].isChecked;
        this.fridayChecked[this.indexValue] = this.days[5].isChecked;
        this.friday = [{ fri_access_start_time: this.days[5].startTime, fri_access_end_time: this.days[5].endTime }];
        this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime;
      } else {
        this.allChecked = false;
        this.fridayChecked[this.indexValue] = this.days[5].isChecked;
        this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime;
      }
    }

    if (event.target.name === 'Saturday') {
      if (this.days[6].isChecked) {
        this.satAccess = this.days[6].isChecked;
        this.saturdayChecked[this.indexValue] = this.days[6].isChecked;
        this.saturday = [{ sat_access_start_time: this.days[6].startTime, sat_access_end_time: this.days[6].endTime }];
        this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime;
      } else {
        this.allChecked = false;
        this.saturdayChecked[this.indexValue] = this.days[6].isChecked;
        this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime;
      }
    }
    this.allChecked = false;
    if (this.days[0].isChecked === true && this.days[1].isChecked === true
      && this.days[2].isChecked === true && this.days[3].isChecked === true
      && this.days[4].isChecked === true && this.days[5].isChecked === true
      && this.days[6].isChecked === true) {
      this.allChecked = true;
    }
    this.sundayValues = this.sunday;
    this.mondayValues = this.monday;
    this.tuesdayValues = this.tuesday;
    this.wednesdayValues = this.wednesday;
    this.thursdayValues = this.thursday;
    this.fridayValues = this.friday;
    this.saturdayValues = this.saturday;
  }
  dateCheck() {
    // StartDate
    if (this.startDate[this.indexValue]) {
      const stMonth = this.startDate[this.indexValue].month;
      // tslint:disable-next-line:no-shadowed-variable
      function getStartMonthFormat(stMonth) {
        return (stMonth < 10) ? '0' + stMonth : '' + stMonth;
      }
      const stDay = this.startDate[this.indexValue].day;
      // tslint:disable-next-line:no-shadowed-variable
      function getStartDateFormat(stDay) {
        return (stDay < 10) ? '0' + stDay : '' + stDay;
      }
      const sDateMonth = getStartMonthFormat(stMonth);
      const sDateDay = getStartDateFormat(stDay);
      this.sDate = this.startDate[this.indexValue].year + '-' + sDateMonth + '-' + sDateDay;
    } else {
      this.sDate = '';
    }
    // EndDate
    if (this.endDate[this.indexValue]) {
      const eMonth = this.endDate[this.indexValue].month;
      // tslint:disable-next-line:no-shadowed-variable
      function getEndMonthFormat(eMonth) {
        return (eMonth < 10) ? '0' + eMonth : '' + eMonth;
      }
      const eDay = this.endDate[this.indexValue].day;
      // tslint:disable-next-line:no-shadowed-variable
      function getEndDateFormat(eDay) {
        return (eDay < 10) ? '0' + eDay : '' + eDay;
      }
      const eDateMonth = getEndMonthFormat(eMonth);
      const eDateDay = getEndDateFormat(eDay);
      this.eDate = this.endDate[this.indexValue].year + '-' + eDateMonth + '-' + eDateDay;
    } else {
      this.eDate = '';
    }
    if (this.sDate > this.eDate) {
      alert('Please ensure that the End Date is greater than or equal to the Start Date.');
      this.endDate[this.indexValue] = '';
      this.eDate = '';
      return false;
    }
  }
  timeCheck(day) {
    const stime = day.startTime;
    const etime = day.endTime;
    if (stime > etime || stime === etime) {
      alert('Please ensure that the End Time is greater than Start Time.');
      this.invalidTime = true;
      // day.startTime = stime;
      // day.endTime = etime;
      // etime = null;
      return false;
    } else {
      this.invalidTime = false;
    }
  }
  allSelected(event) {
    const checked = event.target.checked;
    // console.log(this.days)
    const selectall: any = [];
    const days = this.days;
    days.forEach(obj => obj.isChecked = checked);
    for (const list of this.days) {
      if (list.isChecked === true) {
        list.startTime = list.startTime ? list.startTime : '00:00';
        list.endTime = list.endTime ? list.endTime : '23:59';
        selectall.push(list);
      } else {
        list.startTime = null;
        list.endTime = null;
        selectall.push(list);
      }
    }
    if (selectall.length !== 0) {
      this.days = selectall;
    } else {
      this.days = days;
    }
  }
  onSubmitTimeZone() {
    this.dateCheck();
    const userData = this.commonServices.copyObject(this.data);
    const phoneNumber = userData.phoneNumber;
    this.sunAccess = this.days[0].isChecked;
    this.monAccess = this.days[1].isChecked;
    this.tueAccess = this.days[2].isChecked;
    this.wedAccess = this.days[3].isChecked;
    this.thuAccess = this.days[4].isChecked;
    this.friAccess = this.days[5].isChecked;
    this.satAccess = this.days[6].isChecked;
    let sunday: any = [];
    let monday: any = [];
    let tuesday: any = [];
    let wednesday: any = [];
    let thursday: any = [];
    let friday: any = [];
    let saturday: any = [];
    if (this.sunAccess) {
      // sunday = this.sundayValues[0];
      sunday = [{ sun_access_start_time: this.days[0].startTime, sun_access_end_time: this.days[0].endTime }];
      this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime;
    } else {
      sunday = '';
    }
    if (this.monAccess) {
      // monday = this.mondayValues[0];
      monday = [{ mon_access_start_time: this.days[1].startTime, mon_access_end_time: this.days[1].endTime }];
      this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime;
    } else {
      monday = '';
    }
    if (this.tueAccess) {
      // tuesday = this.tuesdayValues[0];
      tuesday = [{ tue_access_start_time: this.days[2].startTime, tue_access_end_time: this.days[2].endTime }];
      this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime;
    } else {
      tuesday = '';
    }
    if (this.wedAccess) {
      // wednesday = this.wednesdayValues[0];
      wednesday = [{ wed_access_start_time: this.days[3].startTime, wed_access_end_time: this.days[3].endTime }];
      this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime;
    } else {
      wednesday = '';
    }
    if (this.thuAccess) {
      // thursday = this.thursdayValues[0];
      thursday = [{ thu_access_start_time: this.days[4].startTime, thu_access_end_time: this.days[4].endTime }];
      this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime;
    } else {
      thursday = '';
    }
    if (this.friAccess) {
      // friday = this.fridayValues[0];
      friday = [{ fri_access_start_time: this.days[5].startTime, fri_access_end_time: this.days[5].endTime }];
      this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime;
    } else {
      friday = '';
    }
    if (this.satAccess) {
      // saturday = this.saturdayValues[0];
      saturday = [{ sat_access_start_time: this.days[6].startTime, sat_access_end_time: this.days[6].endTime }];
      this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime;
    } else {
      saturday = '';
    }
    let sunSt: any;
    let sunEt: any;
    let mondaySt: any;
    let mondayEt: any;
    let tueSt: any;
    let tueEt: any;
    let wedSt: any;
    let wedEt: any;
    let thuSt: any;
    let thuEt: any;
    let friSt: any;
    let friEt: any;
    let satSt: any;
    let satEt: any;
    if (sunday !== '') {
      sunSt = sunday[0].sun_access_start_time,
        sunEt = sunday[0].sun_access_end_time;
    } else {
      sunSt = '',
        sunEt = '';
    }
    if (monday !== '') {
      mondaySt = monday[0].mon_access_start_time,
        mondayEt = monday[0].mon_access_end_time;
    } else {
      mondaySt = '',
        mondayEt = '';
    }
    if (tuesday !== '') {
      tueSt = tuesday[0].tue_access_start_time,
        tueEt = tuesday[0].tue_access_end_time;
    } else {
      tueSt = '',
        tueEt = '';
    }
    if (wednesday !== '') {
      wedSt = wednesday[0].wed_access_start_time,
        wedEt = wednesday[0].wed_access_end_time;
    } else {
      wedSt = '',
        wedEt = '';
    }
    if (thursday !== '') {
      thuSt = thursday[0].thu_access_start_time,
        thuEt = thursday[0].thu_access_end_time;
    } else {
      thuSt = '',
        thuEt = '';
    }
    if (friday !== '') {
      friSt = friday[0].fri_access_start_time,
        friEt = friday[0].fri_access_end_time;
    } else {
      friSt = '',
        friEt = '';
    }
    if (saturday !== '') {
      satSt = saturday[0].sat_access_start_time,
        satEt = saturday[0].sat_access_end_time;
    } else {
      satSt = '',
        satEt = '';
    }
    const accData = this.data.addresses;
    this.accTime = accData;
    accData.forEach((id, i) => {
      if (id.accessStartDate) {
        if (id.accessStartDate.length > 10) {
          const startDt = id.accessStartDate.slice(0, 10);
          id.accessStartDate = startDt;
        }
      }
      // else {
      //   id.accessStartDate = '';
      // }
      if (id.accessEndDate) {
        if (id.accessEndDate.length > 10) {
          const endDt = id.accessEndDate.slice(0, 10);
          id.accessEndDate = endDt;
        }
      }
      // else {
      //   id.accessEndDate = '';
      // }
      if (id.accessPoint === this.tzDeviceId) {
        if (this.accTime === '') {
          id.deviceMACId = this.tzDeviceId,
            id.phoneNumber = phoneNumber,
            id.accessStartDate = this.sDate,
            id.accessEndDate = this.eDate,
            id.monAccess = this.monAccess,
            id.tueAccess = this.tueAccess,
            id.wedAccess = this.wedAccess,
            id.thuAccess = this.thuAccess,
            id.friAccess = this.friAccess,
            id.satAccess = this.satAccess,
            id.sunAccess = this.sunAccess,
            id.mon_access_start_time = mondaySt,
            id.tue_access_start_time = tueSt,
            id.wed_access_start_time = wedSt,
            id.thu_access_start_time = thuSt,
            id.fri_access_start_time = friSt,
            id.sat_access_start_time = satSt,
            id.sun_access_start_time = sunSt,
            id.mon_access_end_time = mondayEt,
            id.tue_access_end_time = tueEt,
            id.wed_access_end_time = wedEt,
            id.thu_access_end_time = thuEt,
            id.fri_access_end_time = friEt,
            id.sat_access_end_time = satEt,
            id.sun_access_end_time = sunEt;
          this.accTime.push(id);
          // console.log(this.accTime)
        } else {
          for (const list of accData) {
            if (list.accessPoint === this.tzDeviceId) {
              list.deviceMACId = this.tzDeviceId,
                list.phoneNumber = phoneNumber;
              list.accessStartDate = this.sDate,
                list.accessEndDate = this.eDate;
              list.monAccess = this.monAccess,
                list.tueAccess = this.tueAccess,
                list.wedAccess = this.wedAccess,
                list.thuAccess = this.thuAccess,
                list.friAccess = this.friAccess,
                list.satAccess = this.satAccess,
                list.sunAccess = this.sunAccess,
                list.mon_access_start_time = mondaySt,
                list.tue_access_start_time = tueSt,
                list.wed_access_start_time = wedSt,
                list.thu_access_start_time = thuSt,
                list.fri_access_start_time = friSt,
                list.sat_access_start_time = satSt,
                list.sun_access_start_time = sunSt,
                list.mon_access_end_time = mondayEt,
                list.tue_access_end_time = tueEt,
                list.wed_access_end_time = wedEt,
                list.thu_access_end_time = thuEt,
                list.fri_access_end_time = friEt,
                list.sat_access_end_time = satEt,
                list.sun_access_end_time = sunEt;
              this.accTime = accData;
              // console.log(this.accTime)
            }
          }
        }
      }
    });
  }
  getGroupTimeAccess(address, i) {
    this.deviceGroupId = address.deviceGroupId;
    if (address.userDeviceGroupId) {
      if (address.accessStartDate) {
        const strtDate = address.accessStartDate;
        const stDt = strtDate.slice(0, 10);
        this.startDate = {
          year: parseInt(stDt.split('-')[0], 10),
          month: parseInt(stDt.split('-')[1], 10),
          day: parseInt(stDt.split('-')[2], 10)
        };
        this.startDate[this.indexValue] = this.startDate;
      } else {
        this.startDate[this.indexValue] = '';
      }
      if (address.accessEndDate) {
        this.endDate = address.accessEndDate;
        const edDt = this.endDate.slice(0, 10);
        this.endDate = {
          year: parseInt(edDt.split('-')[0], 10),
          month: parseInt(edDt.split('-')[1], 10),
          day: parseInt(edDt.split('-')[2], 10)
        };
        this.endDate[this.indexValue] = this.endDate;
      } else {
        this.endDate[this.indexValue] = '';
      }
      if (address.sunAccess && address.monAccess
        && address.tueAccess && address.wedAccess
        && address.thuAccess && address.friAccess
        && address.satAccess) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
      this.days = [
        { label: 'Sunday', startTime: address.sun_access_start_time,
          endTime: address.sun_access_end_time, isChecked: address.sunAccess },
        { label: 'Monday', startTime: address.mon_access_start_time,
          endTime: address.mon_access_end_time, isChecked: address.monAccess },
        { label: 'Tuesday', startTime: address.tue_access_start_time,
          endTime: address.tue_access_end_time, isChecked: address.tueAccess },
        { label: 'Wednesday', startTime: address.wed_access_start_time,
          endTime: address.wed_access_end_time, isChecked: address.wedAccess },
        { label: 'Thursday', startTime: address.thu_access_start_time,
          endTime: address.thu_access_end_time, isChecked: address.thuAccess },
        { label: 'Friday', startTime: address.fri_access_start_time,
          endTime: address.fri_access_end_time, isChecked: address.friAccess },
        { label: 'Saturday', startTime: address.sat_access_start_time,
          endTime: address.sat_access_end_time, isChecked: address.satAccess },
      ];
      this.onSubmitGroupTimeAccess();
    } else {
      if (this.startDate[this.indexValue] === '' || this.startDate[this.indexValue] === undefined) {
        const date = new Date(new Date().setDate(new Date().getDate() - 1));
        this.startDate[this.indexValue] = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      }
      if (this.endDate[this.indexValue] === '' || this.endDate[this.indexValue] === undefined) {
        this.endDate[this.indexValue] = { year: this.todayDate.year + 10, month: this.todayDate.month, day: this.todayDate.day };
      }
      if (this.sundayStartTime[this.indexValue] === '' || this.sundayStartTime[this.indexValue] === undefined) {
        this.sundayStartTime[this.indexValue] = '00:00';
      }
      if (this.mondayStartTime[this.indexValue] === '' || this.mondayStartTime[this.indexValue] === undefined) {
        this.mondayStartTime[this.indexValue] = '00:00';
      }
      if (this.tuesdayStartTime[this.indexValue] === '' || this.tuesdayStartTime[this.indexValue] === undefined) {
        this.tuesdayStartTime[this.indexValue] = '00:00';
      }
      if (this.wednesdayStartTime[this.indexValue] === '' || this.wednesdayStartTime[this.indexValue] === undefined) {
        this.wednesdayStartTime[this.indexValue] = '00:00';
      }
      if (this.thursdayStartTime[this.indexValue] === '' || this.thursdayStartTime[this.indexValue] === undefined) {
        this.thursdayStartTime[this.indexValue] = '00:00';
      }
      if (this.fridayStartTime[this.indexValue] === '' || this.fridayStartTime[this.indexValue] === undefined) {
        this.fridayStartTime[this.indexValue] = '00:00';
      }
      if (this.satdayStartTime[this.indexValue] === '' || this.satdayStartTime[this.indexValue] === undefined) {
        this.satdayStartTime[this.indexValue] = '00:00';
      }
      if (this.sundayEndTime[this.indexValue] === '' || this.sundayEndTime[this.indexValue] === undefined) {
        this.sundayEndTime[this.indexValue] = '23:59';
      }
      if (this.mondayEndTime[this.indexValue] === '' || this.mondayEndTime[this.indexValue] === undefined) {
        this.mondayEndTime[this.indexValue] = '23:59';
      }
      if (this.tuesdayEndTime[this.indexValue] === '' || this.tuesdayEndTime[this.indexValue] === undefined) {
        this.tuesdayEndTime[this.indexValue] = '23:59';
      }
      if (this.wednesdayEndTime[this.indexValue] === '' || this.wednesdayEndTime[this.indexValue] === undefined) {
        this.wednesdayEndTime[this.indexValue] = '23:59';
      }
      if (this.thursdayEndTime[this.indexValue] === '' || this.thursdayEndTime[this.indexValue] === undefined) {
        this.thursdayEndTime[this.indexValue] = '23:59';
      }
      if (this.fridayEndTime[this.indexValue] === '' || this.fridayEndTime[this.indexValue] === undefined) {
        this.fridayEndTime[this.indexValue] = '23:59';
      }
      if (this.satdayEndTime[this.indexValue] === '' || this.satdayEndTime[this.indexValue] === undefined) {
        this.satdayEndTime[this.indexValue] = '23:59';
      }
      if (this.groupAccTime[this.indexValue] && this.groupAccTime[this.indexValue].deviceMACId) {
        this.sundayChecked[this.indexValue] = this.groupAccTime[this.indexValue].sunAccess;
        this.mondayChecked[this.indexValue] = this.groupAccTime[this.indexValue].monAccess;
        this.tuesdayChecked[this.indexValue] = this.groupAccTime[this.indexValue].tueAccess;
        this.wednesChecked[this.indexValue] = this.groupAccTime[this.indexValue].wedAccess;
        this.thursdayChecked[this.indexValue] = this.groupAccTime[this.indexValue].thuAccess;
        this.fridayChecked[this.indexValue] = this.groupAccTime[this.indexValue].friAccess;
        this.saturdayChecked[this.indexValue] = this.groupAccTime[this.indexValue].satAccess;
      } else {
        this.sundayChecked[this.indexValue] = true;
        this.mondayChecked[this.indexValue] = true;
        this.tuesdayChecked[this.indexValue] = true;
        this.wednesChecked[this.indexValue] = true;
        this.thursdayChecked[this.indexValue] = true;
        this.fridayChecked[this.indexValue] = true;
        this.saturdayChecked[this.indexValue] = true;
      }
      this.days = [
        { label: 'Sunday', startTime: this.sundayStartTime[this.indexValue],
          endTime: this.sundayEndTime[this.indexValue], isChecked: this.sundayChecked[this.indexValue] },
        { label: 'Monday', startTime: this.mondayStartTime[this.indexValue],
          endTime: this.mondayEndTime[this.indexValue], isChecked: this.mondayChecked[this.indexValue] },
        { label: 'Tuesday', startTime: this.tuesdayStartTime[this.indexValue],
          endTime: this.tuesdayEndTime[this.indexValue], isChecked: this.tuesdayChecked[this.indexValue] },
        { label: 'Wednesday', startTime: this.wednesdayStartTime[this.indexValue],
          endTime: this.wednesdayEndTime[this.indexValue], isChecked: this.wednesChecked[this.indexValue] },
        { label: 'Thursday', startTime: this.thursdayStartTime[this.indexValue],
          endTime: this.thursdayEndTime[this.indexValue], isChecked: this.thursdayChecked[this.indexValue] },
        { label: 'Friday', startTime: this.fridayStartTime[this.indexValue],
          endTime: this.fridayEndTime[this.indexValue], isChecked: this.fridayChecked[this.indexValue] },
        { label: 'Saturday', startTime: this.satdayStartTime[this.indexValue],
          endTime: this.satdayEndTime[this.indexValue], isChecked: this.saturdayChecked[this.indexValue] },
      ];
      if (this.sundayChecked[this.indexValue] && this.mondayChecked[this.indexValue]
        && this.tuesdayChecked[this.indexValue] && this.wednesChecked[this.indexValue]
        && this.thursdayChecked[this.indexValue] && this.fridayChecked[this.indexValue]
        && this.saturdayChecked[this.indexValue]) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
      this.onSubmitGroupTimeAccess();
    }
  }
  onSubmitGroupTimeAccess() {
    if (!this.deviceGroupId) {
      return;
    }
    this.dateCheck();
    this.sunAccess = this.days[0].isChecked;
    this.monAccess = this.days[1].isChecked;
    this.tueAccess = this.days[2].isChecked;
    this.wedAccess = this.days[3].isChecked;
    this.thuAccess = this.days[4].isChecked;
    this.friAccess = this.days[5].isChecked;
    this.satAccess = this.days[6].isChecked;
    let sunday: any = [];
    let monday: any = [];
    let tuesday: any = [];
    let wednesday: any = [];
    let thursday: any = [];
    let friday: any = [];
    let saturday: any = [];
    if (this.sunAccess) {
      // sunday = this.sundayValues[0];
      sunday = [{ sun_access_start_time: this.days[0].startTime, sun_access_end_time: this.days[0].endTime }];
      this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime;
    } else {
      sunday = '';
    }
    if (this.monAccess) {
      // monday = this.mondayValues[0];
      monday = [{ mon_access_start_time: this.days[1].startTime, mon_access_end_time: this.days[1].endTime }];
      this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime;
    } else {
      monday = '';
    }
    if (this.tueAccess) {
      // tuesday = this.tuesdayValues[0];
      tuesday = [{ tue_access_start_time: this.days[2].startTime, tue_access_end_time: this.days[2].endTime }];
      this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime;
    } else {
      tuesday = '';
    }
    if (this.wedAccess) {
      // wednesday = this.wednesdayValues[0];
      wednesday = [{ wed_access_start_time: this.days[3].startTime, wed_access_end_time: this.days[3].endTime }];
      this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime;
    } else {
      wednesday = '';
    }
    if (this.thuAccess) {
      // thursday = this.thursdayValues[0];
      thursday = [{ thu_access_start_time: this.days[4].startTime, thu_access_end_time: this.days[4].endTime }];
      this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime;
    } else {
      thursday = '';
    }
    if (this.friAccess) {
      // friday = this.fridayValues[0];
      friday = [{ fri_access_start_time: this.days[5].startTime, fri_access_end_time: this.days[5].endTime }];
      this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime;
    } else {
      friday = '';
    }
    if (this.satAccess) {
      // saturday = this.saturdayValues[0];
      saturday = [{ sat_access_start_time: this.days[6].startTime, sat_access_end_time: this.days[6].endTime }];
      this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime;
    } else {
      saturday = '';
    }

    // tslint:disable:variable-name
    let sun_st: any;
    let sun_et: any;
    let monday_st: any;
    let monday_et: any;
    let tue_st: any;
    let tue_et: any;
    let wed_st: any;
    let wed_et: any;
    let thu_st: any;
    let thu_et: any;
    let fri_st: any;
    let fri_et: any;
    let sat_st: any;
    let sat_et: any;
    // tslint:disable:variable-name

    if (sunday !== '') {
      sun_st = sunday[0].sun_access_start_time,
        sun_et = sunday[0].sun_access_end_time;
    } else {
      sun_st = '',
        sun_et = '';
    }
    if (monday !== '') {
      monday_st = monday[0].mon_access_start_time,
        monday_et = monday[0].mon_access_end_time;
    } else {
      monday_st = '',
        monday_et = '';
    }
    if (tuesday !== '') {
      tue_st = tuesday[0].tue_access_start_time,
        tue_et = tuesday[0].tue_access_end_time;
    } else {
      tue_st = '',
        tue_et = '';
    }
    if (wednesday !== '') {
      wed_st = wednesday[0].wed_access_start_time;
      wed_et = wednesday[0].wed_access_end_time;
    } else {
      wed_st = '',
        wed_et = '';
    }
    if (thursday !== '') {
      thu_st = thursday[0].thu_access_start_time,
        thu_et = thursday[0].thu_access_end_time;
    } else {
      thu_st = '',
        thu_et = '';
    }
    if (friday !== '') {
      fri_st = friday[0].fri_access_start_time,
        fri_et = friday[0].fri_access_end_time;
    } else {
      fri_st = '',
        fri_et = '';
    }
    if (saturday !== '') {
      sat_st = saturday[0].sat_access_start_time,
        sat_et = saturday[0].sat_access_end_time;
    } else {
      sat_st = '',
        sat_et = '';
    }
    const accData = this.selectedItems;
    this.groupAccTime = accData;
    accData.forEach((id, i) => {
      if (id.accessStartDate) {
        if (id.accessStartDate.length > 10) {
          const startDt = id.accessStartDate.slice(0, 10);
          id.accessStartDate = startDt;
        }
      }
      // else {
      //   id.accessStartDate = '';
      // }
      if (id.accessEndDate) {
        if (id.accessEndDate.length > 10) {
          const endDt = id.accessEndDate.slice(0, 10);
          id.accessEndDate = endDt;
        }
      }
      // else {
      //   id.accessEndDate = '';
      // }
      if (id.deviceGroupId === this.deviceGroupId) {
        if (this.groupAccTime.length === 0) {
          id['accessStartDate'] = this.sDate,
            id['accessEndDate'] = this.eDate,
            id['monAccess'] = this.monAccess,
            id['tueAccess'] = this.tueAccess,
            id['wedAccess'] = this.wedAccess,
            id['thuAccess'] = this.thuAccess,
            id['friAccess'] = this.friAccess,
            id['satAccess'] = this.satAccess,
            id['sunAccess'] = this.sunAccess,
            id['mon_access_start_time'] = monday_st,
            id['tue_access_start_time'] = tue_st,
            id['wed_access_start_time'] = wed_st,
            id['thu_access_start_time'] = thu_st,
            id['fri_access_start_time'] = fri_st,
            id['sat_access_start_time'] = sat_st,
            id['sun_access_start_time'] = sun_st,
            id['mon_access_end_time'] = monday_et,
            id['tue_access_end_time'] = tue_et,
            id['wed_access_end_time'] = wed_et,
            id['thu_access_end_time'] = thu_et,
            id['fri_access_end_time'] = fri_et,
            id['sat_access_end_time'] = sat_et,
            id['sun_access_end_time'] = sun_et
          this.groupAccTime.push(id);
          // console.log(this.accTime)
        } else {
          for (const list of accData) {
            if (list.deviceGroupId === this.deviceGroupId) {
              list.accessStartDate = this.sDate,
                list.accessEndDate = this.eDate;
              list.monAccess = this.monAccess,
                list.tueAccess = this.tueAccess,
                list.wedAccess = this.wedAccess,
                list.thuAccess = this.thuAccess,
                list.friAccess = this.friAccess,
                list.satAccess = this.satAccess,
                list.sunAccess = this.sunAccess,
                list.mon_access_start_time = monday_st,
                list.tue_access_start_time = tue_st,
                list.wed_access_start_time = wed_st,
                list.thu_access_start_time = thu_st,
                list.fri_access_start_time = fri_st,
                list.sat_access_start_time = sat_st,
                list.sun_access_start_time = sun_st,
                list.mon_access_end_time = monday_et,
                list.tue_access_end_time = tue_et,
                list.wed_access_end_time = wed_et,
                list.thu_access_end_time = thu_et,
                list.fri_access_end_time = fri_et,
                list.sat_access_end_time = sat_et,
                list.sun_access_end_time = sun_et;
              this.groupAccTime = accData;
              // console.log(this.accTime)
            }
          }
        }
      }
    });
  }
  getInitalTimeAccess(address, i) {
    // var index = i
    this.indexValue = i;
    this.tzDeviceId = address.accessPoint;
    if (this.data.id && this.data.id !== '0' && address.userAccessDurationId) {
      // && this.tzDeviceId === this.data.listGetUserAccess[i].accessPoint
      for (const list of this.data.listGetUserAccess) {

        if (this.tzDeviceId === list.accessPoint) {
          if (address.accessStartDate) {
            const strtDate = address.accessStartDate;
            const stDt = strtDate.slice(0, 10);
            this.startDate = { year: parseInt(stDt.split('-')[0], 10),
              month: parseInt(stDt.split('-')[1], 10),
              day: parseInt(stDt.split('-')[2], 10) };
            this.startDate[this.indexValue] = this.startDate;
          } else {
            this.startDate[this.indexValue] = '';
          }
          if (address.accessEndDate) {
            this.endDate = address.accessEndDate;
            const edDt = this.endDate.slice(0, 10);
            this.endDate = { year: parseInt(edDt.split('-')[0], 10),
              month: parseInt(edDt.split('-')[1], 10),
              day: parseInt(edDt.split('-')[2], 10) };
            this.endDate[this.indexValue] = this.endDate;
          } else {
            this.endDate[this.indexValue] = '';
          }
          if (address.sunAccess
            && address.monAccess
            && address.tueAccess
            && address.wedAccess
            && address.thuAccess
            && address.friAccess
            && address.satAccess) {
            this.allChecked = true;
          } else {
            this.allChecked = false;
          }
          this.days = [
            { label: 'Sunday', startTime: address.sun_access_start_time,
              endTime: address.sun_access_end_time, isChecked: address.sunAccess },
            { label: 'Monday', startTime: address.mon_access_start_time,
              endTime: address.mon_access_end_time, isChecked: address.monAccess },
            { label: 'Tuesday', startTime: address.tue_access_start_time,
              endTime: address.tue_access_end_time, isChecked: address.tueAccess },
            { label: 'Wednesday', startTime: address.wed_access_start_time,
              endTime: address.wed_access_end_time, isChecked: address.wedAccess },
            { label: 'Thursday', startTime: address.thu_access_start_time,
              endTime: address.thu_access_end_time, isChecked: address.thuAccess },
            { label: 'Friday', startTime: address.fri_access_start_time,
              endTime: address.fri_access_end_time, isChecked: address.friAccess },
            { label: 'Saturday', startTime: address.sat_access_start_time,
              endTime: address.sat_access_end_time, isChecked: address.satAccess },
          ];
          this.onSubmitTimeZone();
        }
      }
    } else {
      if (this.startDate[this.indexValue] === '' || this.startDate[this.indexValue] === undefined) {
        const date = new Date(new Date().setDate(new Date().getDate() - 1));
        this.startDate[this.indexValue] = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      }
      if (this.endDate[this.indexValue] === '' || this.endDate[this.indexValue] === undefined) {
        this.endDate[this.indexValue] = { year: this.todayDate.year + 10, month: this.todayDate.month, day: this.todayDate.day };
      }
      if (this.sundayStartTime[this.indexValue] === '' || this.sundayStartTime[this.indexValue] === undefined) {
        this.sundayStartTime[this.indexValue] = '00:00';
      }
      if (this.mondayStartTime[this.indexValue] === '' || this.mondayStartTime[this.indexValue] === undefined) {
        this.mondayStartTime[this.indexValue] = '00:00';
      }
      if (this.tuesdayStartTime[this.indexValue] === '' || this.tuesdayStartTime[this.indexValue] === undefined) {
        this.tuesdayStartTime[this.indexValue] = '00:00';
      }
      if (this.wednesdayStartTime[this.indexValue] === '' || this.wednesdayStartTime[this.indexValue] === undefined) {
        this.wednesdayStartTime[this.indexValue] = '00:00';
      }
      if (this.thursdayStartTime[this.indexValue] === '' || this.thursdayStartTime[this.indexValue] === undefined) {
        this.thursdayStartTime[this.indexValue] = '00:00';
      }
      if (this.fridayStartTime[this.indexValue] === '' || this.fridayStartTime[this.indexValue] === undefined) {
        this.fridayStartTime[this.indexValue] = '00:00';
      }
      if (this.satdayStartTime[this.indexValue] === '' || this.satdayStartTime[this.indexValue] === undefined) {
        this.satdayStartTime[this.indexValue] = '00:00';
      }
      if (this.sundayEndTime[this.indexValue] === '' || this.sundayEndTime[this.indexValue] === undefined) {
        this.sundayEndTime[this.indexValue] = '23:59';
      }
      if (this.mondayEndTime[this.indexValue] === '' || this.mondayEndTime[this.indexValue] === undefined) {
        this.mondayEndTime[this.indexValue] = '23:59';
      }
      if (this.tuesdayEndTime[this.indexValue] === '' || this.tuesdayEndTime[this.indexValue] === undefined) {
        this.tuesdayEndTime[this.indexValue] = '23:59';
      }
      if (this.wednesdayEndTime[this.indexValue] === '' || this.wednesdayEndTime[this.indexValue] === undefined) {
        this.wednesdayEndTime[this.indexValue] = '23:59';
      }
      if (this.thursdayEndTime[this.indexValue] === '' || this.thursdayEndTime[this.indexValue] === undefined) {
        this.thursdayEndTime[this.indexValue] = '23:59';
      }
      if (this.fridayEndTime[this.indexValue] === '' || this.fridayEndTime[this.indexValue] === undefined) {
        this.fridayEndTime[this.indexValue] = '23:59';
      }
      if (this.satdayEndTime[this.indexValue] === '' || this.satdayEndTime[this.indexValue] === undefined) {
        this.satdayEndTime[this.indexValue] = '23:59';
      }
      if (this.accTime[this.indexValue] && this.accTime[this.indexValue].deviceMACId) {
        this.sundayChecked[this.indexValue] = this.accTime[this.indexValue].sunAccess;
        this.mondayChecked[this.indexValue] = this.accTime[this.indexValue].monAccess;
        this.tuesdayChecked[this.indexValue] = this.accTime[this.indexValue].tueAccess;
        this.wednesChecked[this.indexValue] = this.accTime[this.indexValue].wedAccess;
        this.thursdayChecked[this.indexValue] = this.accTime[this.indexValue].thuAccess;
        this.fridayChecked[this.indexValue] = this.accTime[this.indexValue].friAccess;
        this.saturdayChecked[this.indexValue] = this.accTime[this.indexValue].satAccess;
      } else {
        this.sundayChecked[this.indexValue] = true;
        this.mondayChecked[this.indexValue] = true;
        this.tuesdayChecked[this.indexValue] = true;
        this.wednesChecked[this.indexValue] = true;
        this.thursdayChecked[this.indexValue] = true;
        this.fridayChecked[this.indexValue] = true;
        this.saturdayChecked[this.indexValue] = true;
      }
      this.days = [
        { label: 'Sunday', startTime: this.sundayStartTime[this.indexValue],
          endTime: this.sundayEndTime[this.indexValue], isChecked: this.sundayChecked[this.indexValue] },
        { label: 'Monday', startTime: this.mondayStartTime[this.indexValue],
          endTime: this.mondayEndTime[this.indexValue], isChecked: this.mondayChecked[this.indexValue] },
        { label: 'Tuesday', startTime: this.tuesdayStartTime[this.indexValue],
          endTime: this.tuesdayEndTime[this.indexValue], isChecked: this.tuesdayChecked[this.indexValue] },
        { label: 'Wednesday', startTime: this.wednesdayStartTime[this.indexValue],
          endTime: this.wednesdayEndTime[this.indexValue], isChecked: this.wednesChecked[this.indexValue] },
        { label: 'Thursday', startTime: this.thursdayStartTime[this.indexValue],
          endTime: this.thursdayEndTime[this.indexValue], isChecked: this.thursdayChecked[this.indexValue] },
        { label: 'Friday', startTime: this.fridayStartTime[this.indexValue],
          endTime: this.fridayEndTime[this.indexValue], isChecked: this.fridayChecked[this.indexValue] },
        { label: 'Saturday', startTime: this.satdayStartTime[this.indexValue],
          endTime: this.satdayEndTime[this.indexValue], isChecked: this.saturdayChecked[this.indexValue] },
      ];
      if (this.sundayChecked[this.indexValue] && this.mondayChecked[this.indexValue]
        && this.tuesdayChecked[this.indexValue] && this.wednesChecked[this.indexValue]
        && this.thursdayChecked[this.indexValue] && this.fridayChecked[this.indexValue]
        && this.saturdayChecked[this.indexValue]) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
      this.onSubmitTimeZone();
    }
  }
  checkDuplication(subloc, index, address, subLocDup: TemplateRef<any>) {
    if (this.userAddress.length > 0) {
      this.addressSubLocations = [];
    }
    for ( const item of this.userAddress) {
      this.addressSubLocations.push(item.accessPoint);
    }
    this.addressSubLocations  = this.addressSubLocations.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    const changeSubLoc = this.addressSubLocations;
    let subLocModal: boolean;
    if (changeSubLoc.length > 0) {
      changeSubLoc.forEach(list => {
        if (subloc === list) {
          subLocModal = true;
          return;
        } else {
          this.getAccess(subloc, index);
        }
      });
    } else {
      this.getAccess(subloc, index);
    }

    if (subLocModal) {
      if (this.data.addresses[index].id) {
        const dataAddress = this.commonServices.copyObject(this.data.addresses[index]);
        const datarow = { id: dataAddress.id, userAccessDurationId: dataAddress.userAccessDurationId };
        this.data.addresses[index] = datarow;
      } else {
        this.data.addresses[index] = {};
      }
      this.subLocations[index] = [];
      this.addressSubLocations[index] = '';
      this.subLocModal = this.modalServices.show(subLocDup, { class: 'modal-sm', backdrop: false });
    }
    this.getInitalTimeAccess(address, index);
    this.startDate[this.indexValue] = '';
    this.endDate[this.indexValue] = '';
    this.sundayStartTime[index] = '';
    this.mondayStartTime[index] = '';
    this.tuesdayStartTime[index] = '';
    this.wednesdayStartTime[index] = '';
    this.thursdayStartTime[index] = '';
    this.fridayStartTime[index] = '';
    this.satdayStartTime[index] = '';
    this.sundayEndTime[index] = '';
    this.mondayEndTime[index] = '';
    this.tuesdayEndTime[index] = '';
    this.wednesdayEndTime[index] = '';
    this.thursdayEndTime[index] = '';
    this.fridayEndTime[index] = '';
    this.satdayEndTime[index] = '';
    if (this.accTime[index]) {
      this.accTime[index].sun_access_start_time = '00:00';
      this.accTime[index].mon_access_start_time = '00:00';
      this.accTime[index].tue_access_start_time = '00:00';
      this.accTime[index].wed_access_start_time = '00:00';
      this.accTime[index].thu_access_start_time = '00:00';
      this.accTime[index].fri_access_start_time = '00:00';
      this.accTime[index].sat_access_start_time = '00:00';

      this.accTime[index].sun_access_end_time = '23:59';
      this.accTime[index].mon_access_end_time = '23:59';
      this.accTime[index].tue_access_end_time = '23:59';
      this.accTime[index].wed_access_end_time = '23:59';
      this.accTime[index].thu_access_end_time = '23:59';
      this.accTime[index].fri_access_end_time = '23:59';
      this.accTime[index].sat_access_end_time = '23:59';
      // checkboxes
      this.accTime[index].sunAccess = true;
      this.accTime[index].monAccess = true;
      this.accTime[index].tueAccess = true;
      this.accTime[index].wedAccess = true;
      this.accTime[index].thuAccess = true;
      this.accTime[index].friAccess = true;
      this.accTime[index].satAccess = true;
    }
  }
  onUserSubmit(event, form: NgForm) {
    this.buttonDisableByEvent(event);
    const userData = this.commonServices.copyObject(this.data);
    const userAccess = this.commonServices.copyObject(this.data.addresses);
    const userAccessList = [];
    userAccess.forEach(acc => {
      const accobj = {} as IUserAccessModel;
      let accessTime = [];
      accessTime = this.accTime;
      // console.log(accessTime)
      if (accessTime.length === 0) {
        accessTime = userAccess;
      }
      for (const list of accessTime) {
        if (acc.accessPoint === list.accessPoint) {
          accobj.id = acc.id ? acc.id : '0';
          accobj.accessCode = acc.badgeId ? acc.badgeId : null;
          accobj.accessPoint = acc.accessPoint;
          accobj.facilityId = acc.facilityId ? acc.facilityId : null;
          accobj.credentialIdentifier = acc.credentialIdentifier ? acc.credentialIdentifier : null;
          if (acc.range === null || acc.range === '') {
            accobj.range = '10';
          } else {
            accobj.range = acc.range;
          }
          accobj.cardFormat = acc.cardFormat ? acc.cardFormat : null;
          if (acc.antiPassBack === null || acc.antiPassBack === '') {
            accobj.antiPassBack = '0';
          } else {
            accobj.antiPassBack = acc.antiPassBack;
          }
          if (accobj.id && accobj.id !== '0') {
            accobj.active = acc.access ? acc.access : true;
            accobj.userAccessDurationId = acc.userAccessDurationId;
          } else {
            accobj.userAccessDurationId = '0';
          }
          accobj.mon_access_start_time = list.mon_access_start_time;
          accobj.tue_access_start_time = list.tue_access_start_time;
          accobj.wed_access_start_time = list.wed_access_start_time;
          accobj.thu_access_start_time = list.thu_access_start_time;
          accobj.fri_access_start_time = list.fri_access_start_time;
          accobj.sat_access_start_time = list.sat_access_start_time;
          accobj.sun_access_start_time = list.sun_access_start_time;
          accobj.mon_access_end_time = list.mon_access_end_time;
          accobj.tue_access_end_time = list.tue_access_end_time;
          accobj.wed_access_end_time = list.wed_access_end_time;
          accobj.thu_access_end_time = list.thu_access_end_time;
          accobj.fri_access_end_time = list.fri_access_end_time;
          accobj.sat_access_end_time = list.sat_access_end_time;
          accobj.sun_access_end_time = list.sun_access_end_time;
          accobj.accessStartDate = list.accessStartDate;
          accobj.accessEndDate = list.accessEndDate;
          accobj.monAccess = list.monAccess;
          accobj.tueAccess = list.tueAccess;
          accobj.wedAccess = list.wedAccess;
          accobj.thuAccess = list.thuAccess;
          accobj.friAccess = list.friAccess;
          accobj.satAccess = list.satAccess;
          accobj.sunAccess = list.sunAccess;
          if (this.groupedDevices.deviceGroupId) {
            accobj.isGroupedDevice = true;
          }
          userAccessList.push(accobj);
        }
      }
    });
    const data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      refreshCredentialFrequency: userData.refreshCredentialFrequency ? userData.refreshCredentialFrequency : 48,
      image: null,
      accessCode: userData.accessCode,
      cardFormat: userData.cardFormat,
      facilityId: userData.facilityId,
      // isSafeTrustUser: this.isSafeTrustUser,
      listAddUserAccess: userAccessList,
      phoneNumber: userData.phoneNumber,
      id: undefined,
      removedExistingAccess: undefined,
      removeExistingAccessDuration: undefined
    };
    if (!userData.id) {
      if (data.phoneNumber.internationalNumber) {
        data.phoneNumber = (data.phoneNumber.internationalNumber).replace(/[- )(]/g, '');
      } else {
        data.phoneNumber = (data.phoneNumber).replace(/[- )(]/g, '');
      }
    }
    const token = localStorage.getItem('zerv');
    let serviceUrl;
    if (userData && userData.id) {
      data.id = userData.id;
      data.removedExistingAccess = this.removedExistingAccess;
      data.removeExistingAccessDuration = this.removeExistingAccessDuration;
      serviceUrl = this.commonServices.updateUser(token, data);
    } else {
      serviceUrl = this.commonServices.postUser(token, data);
    }
    this.spinnerService.show();
    serviceUrl.subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.removedExistingAccess = [];
          this.removeExistingAccessDuration = [];
          this.commonServices.showSuccess(res.message, 'Success');
          if (userData.id) {
            const phNum = userData.phoneNumber;
            this.getUserById(userData.phoneNumber);
          } else {
            let phNum = data.phoneNumber;
            if (data.phoneNumber.internationalNumber) {
              phNum = (data.phoneNumber.internationalNumber).replace(/[- )(]/g, '');
            } else {
              phNum = (data.phoneNumber).replace(/[- )(]/g, '');
            }
            this.getUserById(phNum);
          }
        }
        // this.resetForm(form);
      },
      err => {
        console.log(err);
        this.isEdit = true;
        this.removedExistingAccess = [];
        this.removeExistingAccessDuration = [];
        this.spinnerService.hide();
        // this.onSubmitLoading = false;
        this.commonServices.showError('Failed to save user details', 'Failed');
        // this.phExists = true;
      }
    );
    // console.log(data)
  }
  onUserInfoSubmit(form: NgForm, userSaveBtn) {
    this.buttonDisable(userSaveBtn);
    if (form.invalid) {
      return;
    }
    const data =  form.value;
    data.firstName = data.firstName.trim();
    data.lastName = data.lastName.trim();
    data.email = data.email.trim();
    const token = localStorage.getItem('zerv');
    let serviceUrl;
    if (this.isEdit) {
      data.phoneNumber = this.data.phoneNumber;
      data.id = this.data.id;
      serviceUrl = this.commonServices.updateUser(token, data);
    } else {
      if (data.phoneNumber.internationalNumber) {
        data.phoneNumber = (data.phoneNumber.internationalNumber).replace(/[- )(]/g, '');
      } else {
        data.phoneNumber = (data.phoneNumber).replace(/[- )(]/g, '');
      }
      serviceUrl = this.commonServices.postUser(token, data);
    }
    this.spinnerService.show();
    serviceUrl.subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.isEdit = true;
          this.removedExistingAccess = [];
          this.removeExistingAccessDuration = [];
          this.commonServices.showSuccess(res.message, 'Success');
          this.getUserById(data.phoneNumber);
        } else {
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        this.spinnerService.hide();
        this.commonServices.showError('Failed to save user details', 'Failed');
      }
    );
  }

  buttonDisableByEvent(event) {
    this.buttonDisable(event.target);
  }
  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }
  phNumberNotInList(ev) {
    if (!ev) {
      return;
    }
    this.phNumber = null;
    let phoneNumber = ev.dialCode + '' + ev.number;
    phoneNumber = phoneNumber.replace(/[- )(]/g, '');
    phoneNumber = phoneNumber.replace('+', '');
    return (this.userDetails || []).filter(item => {
      if (phoneNumber === item.phoneNumber) {
        this.phNumber = item.phoneNumber;
      }
    });
  }
}
