export class GlobalConstants {

  public static customerName = 'Zerv';
  public static customerNameFirstLetter = 'G';
  public static ItemPerPage = 25;
  public static PageSize: number[] = [10, 25, 50, 100, 200, 500];
  public static AllowFiltering = true;

  public static DEVICE = {
    cols: [
      { field: 'deviceMACId', header: 'MAC ID', width: '150px'},
      { field: 'deviceKey', header: 'Key', width: '150px' },
      { field: 'customerName', header: 'Customer Name', width: '150px'},
      { field: 'firmwareVersion', header: 'Firmware Version ', width: '170px' },
      // { field: 'universalAccessCode', header: 'Access Code', width: '170px' },
      // { field: 'cardType', header: 'CardType', width: '100px' },
      { field: 'locationName', header: 'Location Name', width: '180px' },
      // { field: 'locationFriendlyName', header: 'Location Friendly Name', width: '200px' },
      { field: 'subLocationName', header: 'Sub Location Name', width: '180px' },
      { field: 'assosiatedNFCTagUid', header: 'Associated NFC Tag Uid', width: '180px' },
      // { field: 'subLocationFriendlyName', header: 'Sub Location Friendly Name', width: '200px' },
      { field: 'deviceManufacturer', header: 'Manufacturer', width: '150px' },
      // { field: 'active', header: 'Active', width: '80px' }
    ]
  };

  public static CLIENT_ADMIN = {
    DEVICE_COLS: [
      { field: 'deviceMACId', header: 'MAC ID', width: '150px'},
      { field: 'firmwareVersion', header: 'Firmware Version ', width: '170px' },
      { field: 'universalAccessCode', header: 'Access Code', width: '130px' },
      { field: 'locationName', header: 'Location Name', width: '180px' },
      { field: 'locationFriendlyName', header: 'Location Friendly Name', width: '200px' },
      { field: 'subLocationName', header: 'Sub Location Name', width: '180px' },
      { field: 'subLocationFriendlyName', header: 'Sub Location Friendly Name', width: '200px' },
      { field: 'cardType', header: 'CardType', width: '100px' },
    ],
    USER_COLS: [
      { field: 'name', header: 'Name', width: '150px'},
      { field: 'phoneNumber', header: 'Phone Number', width: '120px' },
      { field: 'email', header: 'Email', width: '150px' },
    ],

    USER_NEW_GROUP_DEFAULT_DATA: {
      isNewGroup: true,
      newGroupId: 'adding_newgroup',
      timeAccessId: 'new_link_timeAccessId',
      marginBottomClass: 'mb-40'
    }
  };

  public static ZERV_ADMIN = {
    USER_COLS: [
      { field: 'name', header: 'Name', width: '150px'},
      { field: 'phoneNumber', header: 'Phone Number', width: '120px' },
      { field: 'email', header: 'Email', width: '150px' },
    ],
    ADMIN_COLS: [
      { field: 'customerName', header: 'Customer Name', width: '150px' },
    ]
  };

  public static ACCESS = {
    cols: [
      { field: 'customerName', header: 'Customer Name', width: '120px', showFilter: true },
      { field: 'phoneNumber', header: 'Phone Number', width: '120px', showFilter: true},
      { field: 'deviceMACId', header: 'Device Mac ID', width: '120px', showFilter: true },
      { field: 'locationName', header: 'Location', width: '100px' },
      { field: 'subLocationName', header: 'Sub-Location', width: '100px' },
      { field: 'event', header: 'Event', width: '280px' },
      { field: 'eventTimestamp', header: 'Access Time', width: '200px' },
      { field: 'userName', header: 'User Name', width: '150px' },
      { field: 'mobileOSVersion', header: 'Mobile OS Version', width: '80px' },
      { field: 'range', header: 'Range', width: '150px' },
      { field: 'rssi', header: 'RSSI', width: '150px' },
      { field: 'sdkVersion', header: 'SDK Version', width: '80px' },
      { field: 'credentialType', header: 'Credential Type', width: '80px' }
    ]
  };

  public static AUDITTRAILS = {
    cols: [
      { field: 'userName', header: 'User Name', width: '180px' },
      { field: 'customerName', header: 'Customer Name', width: '120px'},
      { field: 'message', header: 'Audit Log Message', width: '220px' },
      { field: 'actionValue', header: 'Audit Log Value', width: '180px' },
      { field: 'auditTimestamp', header: 'Audit TimeStamp', width: '180px' },

    ]
  };

  public static LOCATIONGROUPS = {
    cols: [
      { field: 'deviceGroupName', header: 'Location Group Name', width: '150px'},
      { field: 'description', header: 'Description', width: '150px' },
    ]
  };

  public static ADMINLOCATIONGROUPS = {
    cols: [
      { field: 'deviceGroupName', header: 'Location Group Name', width: '150px'},
      { field: 'description', header: 'Description', width: '150px' },
      { field: 'customerid', header: 'Customer Name', width: '150px' },
    ]
  };

  public static GROUPEDDEVICES = {
    cols: [
      { field: 'deviceMACId', header: 'Device MAC ID', width: '120px'},
      { field: 'installLocation', header: 'Location', width: '120px' },
      { field: 'installSubLocation', header: 'Sub-Location', width: '120px' },
    ]
  };
}
