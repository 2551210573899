import { Component, OnInit, ViewChild, EventEmitter, ElementRef, ViewEncapsulation  } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { CommonServices } from "../../services/commonservice";
import { GlobalConstants } from "src/app/constants/global-constants";

@Component({
  selector: 'app-location-group',
  templateUrl: './location-group.component.html',
  styleUrls: ['./location-group.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CommonServices]
})
export class LocationGroupComponent implements OnInit {
  showViewDeviceModal: boolean;
  form: FormGroup;
  deviceGroup: any={};
  listofGroups: any=[];
  noData: string;
  groupInfoById: any = {};
  deviceGroupId: any;
  groupDetails: any ={};
  listofGroupsbyGroupType: any = [];

  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  columns = GlobalConstants.ADMINLOCATIONGROUPS.cols;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private CommonServices: CommonServices,
  ) { }

  //  //sorting
  //  key: string = "name"; //set default
  //  reverse: boolean = false;
  //  sort(key) {
  //    this.key = key;
  //    this.reverse = !this.reverse;
  //  }
 
   //initializing p to one
  //  p: number = 1;

  ngOnInit() {
    // this.form = this.fb.group({
    //   deviceGroupName: ["", Validators.required],
    //   description: ["", Validators.required],
    //   // location: ["", Validators.required],
    // });
    this.getListofGroups();
  }

  getListofGroups() {
    this.spinnerService.show();
    let groupType = 'Location-Type';
    let token = localStorage.getItem("zerv");
    let val = token.split(".")[1];
    let role1 = atob(val);
    let value = JSON.parse(role1);
    let customerId = value['custom:customerId'];
    this.CommonServices.getallZervAdminGroups(token,groupType).subscribe(
      res => {
        if (res.code == 200) {
          this.listofGroups = res.listGetAllDeviceGroup;
          if (this.listofGroups.length == 0) {
            this.noData = "No Data Found!";
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  // getGroupById(Id,Modal) {
  //   this.spinnerService.show();
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.getGroupById(token, Id).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.groupInfoById = res;
  //         this.deviceGroup["deviceGroupName"] = res.deviceGroupName;
  //         this.deviceGroup["description"] = res.description;
  //         this.getListofGroups()
  //         this.spinnerService.hide();
  //         Modal.show();
  //       } else {
  //         this.spinnerService.hide();
  //         Modal.show()
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //       this.spinnerService.hide();
  //       Modal.show();
  //     }
  //   );
  // }

  viewDevicesModal(items) {
    this.groupDetails = items;
    this.showViewDeviceModal = true;
  }

  // addGroup(addNewGroup) {
  //   let formData = this.deviceGroup;
  //   let data: any;
  //   data = {
  //         deviceGroupName: formData.deviceGroupName,
  //         description: formData.description,
  //         location: formData.location,
  //   };
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.addGroupDevices(token, data).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.form.reset();
  //         addNewGroup.hide();
  //         this.CommonServices.showSuccess("DeviceGroup has been added successfully", "Group Created")
  //         this.getListofGroups()
  //       } else {
  //         this.form.reset();
  //         addNewGroup.hide();
  //         this.CommonServices.showError("DeviceGroup was not Created", "Try Again")
  //         this.getListofGroups()
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  //   console.log(data);

  // }

  // updateGroup(addNewGroup,Id) {
  //   let formData = this.deviceGroup;
  //   let data: any;
  //   data = {
  //         deviceGroupName: formData.deviceGroupName,
  //         description: formData.description,
  //         location: formData.location,
  //   };
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.updateGroupInfo(token, Id, data).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.form.reset();
  //         addNewGroup.hide();
  //         this.CommonServices.showSuccess("DeviceGroup has been Updated successfully", "Group Updated")
  //         this.getListofGroups()
  //       } else {
  //         this.form.reset();
  //         addNewGroup.hide();
  //         this.getListofGroups()
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  //   console.log(data);

  // }

  onClickBack(){
    this.showViewDeviceModal = false;
    this.groupDetails = "";
  }

  // getGroupId(Id){
  //   this.deviceGroupId = Id
  // }

  // deleteGroup(Id){
  //   this.spinnerService.show();
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.DeleteGroup(token, Id).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.spinnerService.hide();
  //         this.getListofGroups();
  //         this.CommonServices.showSuccess("Group Deleted successfully", "Device Deleted");
  //         // this.onSubmitLoading = false;
  //       } else {
  //         this.spinnerService.hide();
  //         this.CommonServices.showError("Group was not Deleted", "Try Again");
  //         // this.onSubmitLoading = false;
  //       }
  //     },
  //     error => {
  //       // console.log(error);
  //       this.spinnerService.hide();
  //     }
  //   );
  // }

}
