import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: "app-dashboard-zerv-admin",
  templateUrl: "./dashboard-zerv-admin.component.html",
  styleUrls: ["./dashboard-zerv-admin.component.css"],
  providers: [CommonServices]
})
export class DashboardZervAdminComponent implements OnInit {
  onSubmitLoading: boolean;
  dashboard: any = {};
  auditInfo: any = [];
  time: string = "";
  noData: any;
  constructor(
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.spinnerService.show();
    this.getDashboardCount();
    this.getCustAuditinfo();
  }

  getDashboardCount() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDashboardCount(token).subscribe(
      res => {
        if (res.code == 200) {
          this.dashboard = res;
          this.spinnerService.hide();
          // console.log(this.dashboard);
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
  getCustAuditinfo() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustAuditinfo(token).subscribe(
      res => {
        if (res.code == 200) {
          this.auditInfo = res.listUserAudit;
          if (this.auditInfo.length == 0) {
            this.noData = "No Data Found!"
          }
          this.spinnerService.hide();
          // console.log(this.auditInfo);
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
}
