import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { CommonServices } from "src/app/services/commonservice";
import {
  SearchCountryField,
  CountryISO
} from "ngx-intl-tel-input";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-addmore-admin',
  templateUrl: './addmore-admin.component.html',
  styleUrls: ["../customers.component.css"],
  providers: [CommonServices]
})
export class AddmoreAdminComponent implements OnInit {
  form: FormGroup;
  @Input() customer: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('content',
    { static: true }
  ) content: ElementRef;
  addAdminModal: BsModalRef;
  updateAdminModal: BsModalRef;
  customerData: any = {};
  customersById: any;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  showAddmoreAdminModal: boolean;
  separateDialCode = true;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  messageModal: BsModalRef;
  invitationMessage: any;
  isChecked: boolean = false;
  welcomeTo: any = "Welcome to ";
  messageContent: any = ". You have been granted permission for mobile access to the organization's facilities. Please download the app from iOS:  OR   Android:  "
  adminList:any = [];
  custName:boolean = false;
  Questionnaire = false;

  constructor(
    private fb: FormBuilder,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalService: NgbModal,
    private modalServices: BsModalService
  ) { }

  ngOnInit() {
    if(this.customer && this.customer.customerId){
      this.getCustomerById(this.customer.customerId)
      this.getAdminListById(this.customer.customerId)
    this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
  }
  this.form = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    customerName: [""],
    address: [""],
    phoneNumber: ["", [Validators.minLength(10), Validators.required]],
    email: ["", [Validators.required, Validators.email]],
    temporaryPassword: ["", [Validators.minLength(6), Validators.required]],
    confirmPassword: ["", Validators.required],
  });
  this.matchpassword()
  this.isChecked = false
  }

  spaceValidation(event){
    if(event.code === "Space"){
      event.preventDefault();
    }
  }

  getCustomerById(Id) {
    // this.custId = Id;
    this.customersById = "";
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustomerById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.customersById = res;
        } else {
          // this.customersById = "";
        }
      },
    );
  }
  closeAdminListModal() {
    this.modalService.dismissAll(this.content);
    this.onSubmit.emit(false);
  }
  openAddAdminModal(addAdmin:TemplateRef<any>){
    this.addAdminModal = this.modalServices.show(addAdmin, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  openUpdateAdminModal(addAdmin:TemplateRef<any>){
    this.updateAdminModal = this.modalServices.show(addAdmin, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  closeAddAdminModal(): void {
    this.form.reset();
    this.addAdminModal.hide();
  }
  matchpassword() {
    this.form.controls["confirmPassword"].setAsyncValidators([
      this.confirmPassword()
    ]);
  }
  confirmPassword(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn: boolean = true;
      if (
        this.form.controls["confirmPassword"].value != this.form.controls["temporaryPassword"].value
      ) {
        bReturn = false;
      }
      let err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  showMessageModal(InviteMessage: TemplateRef<any>) {
    this.messageModal = this.modalServices.show(InviteMessage, { class: 'modal-md', backdrop: 'static', keyboard: false });
  }
  closeMessageModal(event) {
    this.invitationMessage = null;
    // this.selectMessage = false;
    this.isChecked = false;
    this.messageModal.hide();
  }
  checkMessage($event, InviteMessage, custName) {
    this.isChecked = $event.target.checked;
    if ($event.target.checked) {
      this.showMessageModal(InviteMessage)
      this.invitationMessage = this.welcomeTo + custName + this.messageContent
    } else {
      this.invitationMessage = null
    }
  }
  getAdminListById(Id) {
    let token = localStorage.getItem("zerv");
    this.spinnerService.show();
    this.CommonServices.getAdminListById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.adminList = res.listCustomerAdmins;
          if (this.adminList.length == 0) {
            this.adminList = "No Data Found!"
          }
          this.spinnerService.hide();
        }
        err => {
          // console.log(err);
          this.spinnerService.hide();
        }
      },
    );
  }
  verifyQuestionnaire($event){
    this.Questionnaire = $event.target.checked;
  }
  addAddmin(custName,custAddress) {
    this.avoidDuplication();
    this.spinnerService.show();
    this.submitted = true;
    if (this.custName){
      return;
    }
    let formData = this.customerData;
    let phoneNumber =
      formData.phoneNumber.dialCode + formData.phoneNumber.number;
    phoneNumber = phoneNumber.replace(/[- )(]/g, "");
    let token = localStorage.getItem("zerv");
    let obj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      customerName: custName,
      address: custAddress,
      phoneNumber: phoneNumber,
      email: formData.email,
      questionnaireEnable: this.Questionnaire,
      temporaryPassword: formData.temporaryPassword,
      isSuperAdmin: "false",
      invitationMessage: this.invitationMessage ? this.invitationMessage: "",
      sendInvite: this.isChecked
    };
    this.CommonServices.postCustomer(token, obj).subscribe(
      res => {
        this.closeAddAdminModal()
        this.getAdminListById(this.customer.customerId)
        if (res.code == 200) {
          this.spinnerService.hide();
        this.CommonServices.showSuccess("Admin Added Successfully", "Admin Added ")
        }
        else {
          this.spinnerService.hide();
          this.CommonServices.showError("Failed to Add Admin", "Failed")
          this.form.reset();
        }
      },
      err => {
        this.spinnerService.hide();
        this.CommonServices.showError("Failed to Add Admin", "Failed")
      }
    );
  }
  avoidDuplication(){
    this.custName = false;
    let cName = this.customersById.customerName;
    let fName = this.customerData.firstName;
    let lastName = this.customerData.lastName;
    let userId = cName+'_'+fName+'_'+lastName;
    if(this.adminList){
      return (this.adminList || []).filter(item => {
        if (userId == item.customerName) {
          this.custName = true;
          alert("UserId is already exists. Please enter a different user name")
        }
      });
    }
  }
}
