import { Injectable } from '@angular/core';
import { CommonConfigService } from 'src/app/services/common-config.service';
import { XhrService } from 'src/app/services/xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private commonConfigService: CommonConfigService,
    private xhrService: XhrService) { }
  login(obj: { data: any, params?: any, headers?: any }) {
    const url = this.commonConfigService.getApiUrl('login');
    return this.xhrService.xhr({ url, method: 'P', ...obj });
  }
}
