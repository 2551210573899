import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalConstants } from 'src/app/constants/global-constants';
import { CommonServices } from '../../../services/commonservice';

@Component({
  selector: 'app-view-loc-client',
  templateUrl: './view-loc-client.component.html',
  styleUrls: ['./view-loc-client.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ViewLocClientComponent implements OnInit {
  devices: any = [];
  noData: string;
  devicesList: any = [];
  @Input() groupDetails: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  allGroupedDevices: any = [];
  assign: boolean;
  unAssignId: any;
  disabled = false;

  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  columns = GlobalConstants.GROUPEDDEVICES.cols;

  constructor(
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
  ) { }

  ngOnInit() {
    this.assignedDevices();
  }

  assignedDevices() {
    this.spinnerService.show();
    const Id = this.groupDetails.deviceGroupId;
    const token = localStorage.getItem('zerv');
    this.commonServices.getallGroupedDevices(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.allGroupedDevices = res.listGetAllDeviceGroupMapping;
          if (this.allGroupedDevices.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        this.spinnerService.hide();
      }
    );
  }

  onClickBack() {
  // window.location.reload()
  this.onSubmit.emit(false);
  }
}
