import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { environment } from "../../environments/environment";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CommonServices {
  constructor(private http: Http, private toastr: ToastrService) { }
  private urlLogin = environment.API_URL + "v1/portal/login";
  private urlGetUsers = environment.API_URL + "/v1/portal/getusers";
  private urlGetUsersbyId = environment.API_URL + "/v1/portal/user/getuserwithtimezone";
  private urlpostUser = environment.API_URL + "/v1/portal/user/adduserwithtimezone";
  private urlCustomer = environment.API_URL + "/v1/portal/customer";
  private urlLocation = environment.API_URL + "/v1/portal/location";
  private urlDevice = environment.API_URL + "/v1/portal/device";
  private urlAssociate = environment.API_URL + "/v1/portal/device/associate";
  private urlClientDevices = environment.API_URL + "/v1/portal/clientdevice";
  private urlAssignDevice = environment.API_URL + "/v1/portal/assigndevice";
  private urlForgotPassword = environment.API_URL + "/v1/forgotpassword";
  private urlConfirmForgotPwd =
    environment.API_URL + "/v1/confirmforgotpassword";
  private urlGetAllUsers = environment.API_URL + "/v1/portal/getallusers";
  private urlGetDashboardCounts =
    environment.API_URL + "/v1/portal/dashboard/getoverview";
  private urlGetZervAuditInfo =
    environment.API_URL + "/v1/portal/zervadmin/useraudit";
  private urlGetClientAuditInfo = environment.API_URL + "/v1/portal/useraudit";
  private urlGetAllZervAuditInfo =
    environment.API_URL + "/v1/portal/zervadmin/useraudit/all";
  private urlGetAllClientAuditInfo =
    environment.API_URL + "/v1/portal/useraudit/all";
  private urlGetDeleteAddress = environment.API_URL + "/v1/portal/useraccess";
  private urlLocationAccess =
    environment.API_URL + "/v1/portal/active-or-inactive/useraccess";
  private urluserActive_Inactive =
    environment.API_URL + "/v1/portal/active-or-inactive/user";
  private urlpostSwitchDevice =
    environment.API_URL + "/v1/portal/active-or-inactive/device";
  // private urlupdateUser = environment.API_URL + "v1/portal/user/{Id}";
  private urlupdateDevice = environment.API_URL + "/v1/portal/updatedevice";
  private updateuserandtimezone = environment.API_URL + "/v1/portal/user/updateuserandtimezone/{Id}";
  private urluserAccessDuration = environment.API_URL + "/v1/portal/user/useraccessduration";
  private urlsafeTrustDeviceSync = environment.API_URL + "/v1/safetrustdevicessync";
  private urlDeleteUser = environment.API_URL + "/v1/portal/user/deleteuser";
  private urlUpdateCustomer = environment.API_URL + "/v1/updatecustomer";
  private urlGetMultipleSubLoc = environment.API_URL + "/v1/portal/getsublocations";
  private urlGetDeviceUsersById = environment.API_URL + "/v1/getdeviceuser"
  private urlCustomerAdmins = environment.API_URL + "/v1/getcustomeradmins";
  private urlCardFormatList = environment.API_URL + "/v1/portal/getcardtype";
  private urladdGroupDevices = environment.API_URL + "/v1/addgroup";
  private urlgroupsList = environment.API_URL + "/v1/portal/getalldevicegroups";
  private urlZervAdminGroupList = environment.API_URL + "/v1/portal/zervadmin/gettotaldevicegroups";
  private urlUpdateGroupinfo = environment.API_URL + "/v1/updatedevicegroup/{Id}";
  private urlgroupsListById = environment.API_URL + "/v1/getdevicegroup";
  private urladdDevicestoGroup = environment.API_URL + "/v1/adddevicestogroup";
  private allGroupedDevices = environment.API_URL + "/v1/getalldevicegroupmapping/{devicegroupid}";
  private deleteDevicefromGroup = environment.API_URL + "/v1/portal/unassigndevicefromdevicegroup/{id}";
  private deleteGroup = environment.API_URL + "/v1/portal/deletedevicegroup/{id}";
  private urlassociateDeviceGroup = environment.API_URL + "/v1/portal/associatedevicegroup";
  private urlavailableDevices = environment.API_URL + "/v1/portal/clientdevice";
  private urlGetUserDeviceGroups = environment.API_URL + "/v1/portal/userdevicegroups/{id}";
  private urladdDeviceGroupstoUser = environment.API_URL + "/v1/portal/adddevicegroupstouser";
  private urlUpdateDeviceGroupstoUser = environment.API_URL + "/v1/portal/updateuserdevicegroup/{phonenumber}";
  private urlGetAuditLogs = environment.API_URL + "/v1/portal/useraudits";
  private urlupdateDeviceMacIdInfo = environment.API_URL + "/v1/portal/editdevice";
  private urlShareDevicesToCustomers = environment.API_URL + "/v1/share/devices";
  private urlCustomerRelations = environment.API_URL + "/v1/customer/relations";
  private urlGetCustomerRelations = environment.API_URL + "/v1/customer/{customerId}/relations";
  private urlUnlockDevice = environment.API_URL + "/v1/accessreader";
  private urlPingDevice = environment.API_URL + "/v1/pingdevice";
  private urlRebootDevice = environment.API_URL + "/v1/rebootdevice";
  private urlSharedDeviceByTenants = environment.API_URL + "/v1/getshareddevicesbytenants";
  private urlSharedDeviceToTenants = environment.API_URL + "/v1/getshareddevicetotenants";
  private urlGetDeviceSharedTo = environment.API_URL + "/v1/getdevicesharedto/{deviceid}";
  private urlUnShareDevice = environment.API_URL + "/v1/unshare/devices";
  private urlgetDeviceManufacturerType = environment.API_URL + "/v1/getdevicemaufacturertype";
  private urlGetUserAuditTrailLogs = environment.API_URL + "/v1/usertrail";

  showSuccess(message, title) {
    this.toastr.success(message, title)
  }

  showError(message, title) {
    this.toastr.error(message, title)
  }
  showWarning(message, title) {
    this.toastr.warning(message, title)
  }
  copyObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  login(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlLogin}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUser(key, limit, skip, sortColumns, filterColumns)  {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = `${this.urlGetUsers}?limit=${limit}&skip=${skip}&sortColumns=${sortColumns}`;
    if (filterColumns !== '') {
      url = url + `&filterColumns=${filterColumns}`;
    }


    return this.http
      .get(url, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDashboardCount(key) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetDashboardCounts}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetZervAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getClientAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetClientAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getClientAuditLogs(key,customerId,FromDate,ToDate,deviceMacIds,phoneNumbers,page,size,sortParam,sortDir) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAuditLogs}?fromDate=${FromDate}&toDate=${ToDate}&customerIds=${customerId}&deviceMacIds=${deviceMacIds}&phoneNumbers=${phoneNumbers}&page=${page}&size=${size}&sortParam=${sortParam}&sortDir=${sortDir}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllCustAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAllZervAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllClientAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAllClientAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postUser(key, data) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlpostUser}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUserById(key, Id, custId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlGetUsersbyId}/${Id}?customerId=${custId}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustomer(key) {

    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlCustomer}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustomerById(key, Id) {

    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlCustomer}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postCustomer(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlCustomer}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getLocation(key) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlLocation}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSubLocation(key, name) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlLocation}/${name}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDevices(key, limit, skip, sortColumns, filterColumns)  {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlDevice}?limit=${limit}&skip=${skip}&sortColumns=${sortColumns}&filterColumns=${filterColumns}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postDevice(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlDevice}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  associate(key, obj) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlAssociate}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllClientDevices(key)  {
    const url = `${this.urlClientDevices}`;
    const headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    const options = new RequestOptions({ headers }); // Create a request option
    return this.http
      .get(url, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getClientDevices(key, limit, skip, sortColumns, filterColumns)  {
    const headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    const options = new RequestOptions({ headers }); // Create a request option
    const url = `${this.urlClientDevices}?limit=${limit}&skip=${skip}&sortColumns=${sortColumns}&filterColumns=${filterColumns}`;
    return this.http
      .get(url, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  assignDevice(key, data) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlAssignDevice}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  forgotPwd(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlForgotPassword}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  confirmForgotPwd(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlConfirmForgotPwd}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceById(key, Id) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlDevice}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllUsers(key, limit, skip, sortColumns, filterColumns)  {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    let url = `${this.urlGetAllUsers}?limit=${limit}&skip=${skip}&sortColumns=${sortColumns}`;
    if (filterColumns !== '') {
      url = url + `&filterColumns=${filterColumns}`;
    }
    return this.http
      .get(url, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeleteAddress(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .delete(`${this.urlGetDeleteAddress}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  locationAccess(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlLocationAccess}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  userActive_Inactive(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urluserActive_Inactive}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postSwitchDevice(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlpostSwitchDevice}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateUser(key, data) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.updateuserandtimezone.replace('{Id}', data.id);
    // {Id}
    return this.http
      .put(`${url}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateDevice(key, data, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .put(`${this.urlupdateDevice}/${Id}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  userAccessDuration(key, obj) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urluserAccessDuration}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSafeTrustDeviceSync(key) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlsafeTrustDeviceSync}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  DeleteUser(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .delete(`${this.urlDeleteUser}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateCustomerInfo(key, data, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .put(`${this.urlUpdateCustomer}/${Id}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  multipleSubLocations(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlGetMultipleSubLoc}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceUsersById(key, Id) {
    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlGetDeviceUsersById}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAdminListById(key, Id) {
    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlCustomerAdmins}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCardFormatList(key) {
    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlCardFormatList}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  addGroupDevices(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urladdGroupDevices}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
   getallZervAdminGroups(key,type) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlZervAdminGroupList}/${type}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getallclientDevicegroups(key,type,customerId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlgroupsList}/${type}?customerId=${customerId}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getGroupById(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlgroupsListById}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateGroupInfo(key, id, data) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlUpdateGroupinfo.replace('{Id}', id);
    // {Id}
    return this.http
      .put(`${url}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  addDevicestoGroup(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urladdDevicestoGroup}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getallGroupedDevices(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.allGroupedDevices.replace('{devicegroupid}', Id);
    return this.http
      .get(`${url}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  unassignDevicefromGroup(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.deleteDevicefromGroup.replace('{id}', Id);

    return this.http
      .delete(`${url}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  DeleteGroup(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.deleteGroup.replace('{id}', Id);

    return this.http
      .delete(`${url}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  associateDeviceGroup(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlassociateDeviceGroup}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getavailableDevices(key,custId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlavailableDevices}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUserGroupsList(key, phNumber, custId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlGetUserDeviceGroups.replace('{id}', phNumber);
    return this.http
      .get(`${url}?customerId=${custId}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  addDeviceGrouptoUser(key, data) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urladdDeviceGroupstoUser}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  UpdateDeviceGroupstoUser(key, data, phonenumber ) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlUpdateDeviceGroupstoUser.replace('{phonenumber}', phonenumber);
    // {Id}
    return this.http
      .put(`${url}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateDeviceMacIdInfo(key, data) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .put(`${this.urlupdateDeviceMacIdInfo}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  shareDevicesToCustomers(key, data) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlShareDevicesToCustomers}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSharedDevicesWithCustomers(key,sharedByCustIds,sharedWithCustIds,deviceMacIds) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlShareDevicesToCustomers}?sharedByCustIds=${sharedByCustIds}&sharedWithCustIds=${sharedWithCustIds}&deviceMacIds=${deviceMacIds}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  customerRelations(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlCustomerRelations}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustomerRelations(key,custId){
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlGetCustomerRelations.replace('{customerId}', custId);
    return this.http
      .get(`${url}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  unlockDevice(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlUnlockDevice}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  pingDevice(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlPingDevice}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  rebootDevice(key, data){
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlRebootDevice}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSharedDevicesByTenants(key){
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlSharedDeviceByTenants}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSharedDevicesToTenants(key){
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlSharedDeviceToTenants}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceSharedTo(key,deviceMacId){
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlGetUserDeviceGroups.replace('{deviceid}', deviceMacId);
    return this.http
      .get(`${url}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  unShareDevice(key, data) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({
      headers: headers,
      body: data }); // Create a request option

    return this.http
      .delete(`${this.urlUnShareDevice}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceManufacturerType(key){
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlgetDeviceManufacturerType}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUserAuditTrail(key,customerId,FromDate,ToDate,page,size,sortParam,sortDir){
     let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetUserAuditTrailLogs}?fromDate=${FromDate}&toDate=${ToDate}&customerIds=${customerId}&page=${page}&size=${size}&sortParam=${sortParam}&sortDir=${sortDir}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateCustomerRelation(key, data, custId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.urlGetCustomerRelations.replace('{customerId}', custId);
    return this.http
      .put(`${url}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
}

