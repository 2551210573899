import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {CommonServices} from '../../services/commonservice';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import * as moment from 'moment';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GlobalConstants} from '../../constants/global-constants';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-audit-logs-client',
  templateUrl: './audit-logs-client.component.html',
  styleUrls: ['./audit-logs-client.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CommonServices],
})
export class AuditLogsClientComponent implements OnInit {

  onSubmitLoading: boolean;
  auditInfo: any = [];
  text: any;
  noData: any;
  dropdownList = [];
  selectedItems = [];
  devicesDropdownSettings = {};
  phNumDropdownSettings = {};
  devices: any = [];
  deviceMACIDlist: any = [];
  phoneNumberList: any = [];
  userData: any = [];
  selectedDeviceMacIds: any = [];
  FromDate: any;
  ToDate: any;
  listofSelectedDeviceMacIds: any = [];
  listofSelectedPhoneNumbers: any = [];
  todayDate: NgbDateStruct = undefined;
  totalRecords: any = [];
  paginationRefresh: boolean;

  _selectedColumns: any = [];
  cols = GlobalConstants.ACCESS.cols;
  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  sortingObj: any = {};
  currentDate = new Date();
  data: any = {};
  deviceMACId: any = {};
  phoneNumber: any = {};
  dateValidationError = false;
  mainAuditInfo: any = [];
  mainTotalRecords: number;

  constructor(private router: Router,
              private commonServices: CommonServices,
              private spinnerService: Ng4LoadingSpinnerService,
              private calendar: NgbCalendar) {
    // this.todayDate = calendar.getToday();
    for (let i = 0; i < 6; i++) {
      this._selectedColumns.push(this.cols[i]);
    }
  }

  ngOnInit() {
    if (localStorage.getItem('selectedAccessColumns') !== null) {
      this._selectedColumns = JSON.parse(
        localStorage.getItem('selectedAccessColumns')
      );
    }
    this.data.toDate = this.currentDate;
    this.data.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.listofSelectedPhoneNumbers = [];
    this.listofSelectedDeviceMacIds = [];
    this.getDevices();
    this.getUsersList();
  }

  onChangeDeviceId($event) {
    this.deviceMACIDlist = [];
    this.listofSelectedDeviceMacIds = $event;
    for (const list of this.listofSelectedDeviceMacIds) {
      this.deviceMACIDlist.push(list.deviceMACId);
    }
  }

  onChangePhNumber($event) {
    this.phoneNumberList = [];
    this.listofSelectedPhoneNumbers = $event;
    for (const list of this.listofSelectedPhoneNumbers) {
      this.phoneNumberList.push(list.phoneNumber);
    }
  }

  validation() {
    if (this.data.fromDate > this.data.toDate) {
      this.dateValidationError = true;
      // this.data.toDate = "";
    } else {
      this.dateValidationError = false;
    }
  }

  getDevices() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getClientDevices(token, '', '', '', '').subscribe(
      (res) => {
        if (res.code === '200') {
          this.devices = res.listGetDevices;
        }
        this.devices.forEach((o, i) => (o.id = i + 1));
        // console.log(this.deviceMACIDlist)
      },
      (err) => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
  getUsersList() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getUser(token, '', '', '', '').subscribe(
      (res) => {
        if (res.code === '200') {
          this.userData = res.listUsers;
        }
        // for(var list of this.userData){
        //   this.phoneNumberList.push(list.phoneNumber)
        // }
        this.userData.forEach((o, i) => (o.id = i + 1));
        // console.log(this.phoneNumberList)
      },
      (err) => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  dateValidation() {
    let fDate;
    let tDate;
    function getFormat(val) {
      return val < 10 ? '0' + val : '' + val;
    }
    if (this.FromDate) {
      const stMonth = this.FromDate.month;
      const stDay = this.FromDate.day;
      const sDateMonth = getFormat(stMonth);
      const sDateDay = getFormat(stDay);
      fDate = this.FromDate.year + '-' + sDateMonth + '-' + sDateDay;
    }
    // EndDate
    if (this.ToDate) {
      const eMonth = this.ToDate.month;
      const eDay = this.ToDate.day;
      const eDateMonth = getFormat(eMonth);
      const eDateDay = getFormat(eDay);
      tDate = this.ToDate.year + '-' + eDateMonth + '-' + eDateDay;
    }
    if (fDate > tDate) {
      alert(
        'Please ensure that To Date is greater than or equal to the From Date.'
      );
      this.ToDate = '';
    }
  }

  getAllClientAuditinfo(event: LazyLoadEvent) {
    if (event !== undefined) {
      if (event.sortField === undefined) {
        this.sortingObj = { field: 'phoneNumber', order: 'DESC' };
      } else {
        if (event.sortField === 'phoneNumber' && event.sortOrder === -1) {
          this.sortingObj = { field: 'phoneNumber', order: 'DESC' };
        }
        if (event.sortField === 'phoneNumber' && event.sortOrder === 1) {
          this.sortingObj = { field: 'phoneNumber', order: 'ASC' };
        }

        if (event.sortField === 'deviceMACId' && event.sortOrder === -1) {
          this.sortingObj = { field: 'deviceMACId', order: 'DESC' };
        }
        if (event.sortField === 'deviceMACId' && event.sortOrder === 1) {
          this.sortingObj = { field: 'deviceMACId', order: 'ASC' };
        }

        if (event.sortField === 'locationName' && event.sortOrder === -1) {
          this.sortingObj = { field: 'locationName', order: 'DESC' };
        }
        if (event.sortField === 'locationName' && event.sortOrder === 1) {
          this.sortingObj = { field: 'locationName', order: 'ASC' };
        }

        if (event.sortField === 'subLocationName' && event.sortOrder === -1) {
          this.sortingObj = { field: 'subLocationName', order: 'DESC' };
        }
        if (event.sortField === 'subLocationName' && event.sortOrder === 1) {
          this.sortingObj = { field: 'subLocationName', order: 'ASC' };
        }
        if (event.sortField === 'event' && event.sortOrder === -1) {
          this.sortingObj = { field: 'event', order: 'DESC' };
        }
        if (event.sortField === 'event' && event.sortOrder === 1) {
          this.sortingObj = { field: 'event', order: 'ASC' };
        }
        if (event.sortField === 'eventTimestamp' && event.sortOrder === -1) {
          this.sortingObj = { field: 'eventTimestamp', order: 'DESC' };
        }
        if (event.sortField === 'eventTimestamp' && event.sortOrder === 1) {
          this.sortingObj = { field: 'eventTimestamp', order: 'ASC' };
        }
        if (event.sortField === 'customerId' && event.sortOrder === -1) {
          this.sortingObj = { field: 'customerId', order: 'DESC' };
        }
        if (event.sortField === 'customerId' && event.sortOrder === 1) {
          this.sortingObj = { field: 'customerId', order: 'ASC' };
        }

        if (event.sortField === 'customerName' && event.sortOrder === -1) {
          this.sortingObj = { field: 'customerName', order: 'DESC' };
        }
        if (event.sortField === 'customerName' && event.sortOrder === 1) {
          this.sortingObj = { field: 'customerName', order: 'ASC' };
        }
        if (event.sortField === 'userName' && event.sortOrder === -1) {
          this.sortingObj = { field: 'userName', order: 'DESC' };
        }
        if (event.sortField === 'userName' && event.sortOrder === 1) {
          this.sortingObj = { field: 'userName', order: 'ASC' };
        }
        if (event.sortField === 'mobileOSVersion' && event.sortOrder === -1) {
          this.sortingObj = { field: 'mobileOSVersion', order: 'DESC' };
        }
        if (event.sortField === 'mobileOSVersion' && event.sortOrder === 1) {
          this.sortingObj = { field: 'mobileOSVersion', order: 'ASC' };
        }
        if (event.sortField === 'phoneModel' && event.sortOrder === -1) {
          this.sortingObj = { field: 'phoneModel', order: 'DESC' };
        }
        if (event.sortField === 'phoneModel' && event.sortOrder === 1) {
          this.sortingObj = { field: 'phoneModel', order: 'ASC' };
        }
        if (event.sortField === 'range' && event.sortOrder === -1) {
          this.sortingObj = { field: 'range', order: 'DESC' };
        }
        if (event.sortField === 'range' && event.sortOrder === 1) {
          this.sortingObj = { field: 'range', order: 'ASC' };
        }
        if (event.sortField === 'rssi' && event.sortOrder === -1) {
          this.sortingObj = { field: 'rssi', order: 'DESC' };
        }
        if (event.sortField === 'rssi' && event.sortOrder === 1) {
          this.sortingObj = { field: 'rssi', order: 'ASC' };
        }
        if (event.sortField === 'sdkVersion' && event.sortOrder === -1) {
          this.sortingObj = { field: 'sdkVersion', order: 'DESC' };
        }
        if (event.sortField === 'sdkVersion' && event.sortOrder === 1) {
          this.sortingObj = { field: 'sdkVersion', order: 'ASC' };
        }
        if (event.sortField === 'credentialType' && event.sortOrder === -1) {
          this.sortingObj = { field: 'credentialType', order: 'DESC' };
        }
        if (event.sortField === 'credentialType' && event.sortOrder === 1) {
          this.sortingObj = { field: 'credentialType', order: 'ASC' };
        }
      }
    }

    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    const deviceMacIds = this.deviceMACIDlist.join(',');
    const phoneNumbers = this.phoneNumberList.join(',');
    let page: any;
    let size: any;
    const FDate = moment(this.data.fromDate).format('YYYY-MM-DD');
    const TDate = moment(this.data.toDate).format('YYYY-MM-DD');

    if (event !== undefined) {
      // this.event = { rows: event.rows, first: event.first };
      size = event.rows;
      page = event.first / event.rows;
    } else {
      size = 25;
      page = 0;
      this.sortingObj = { field: 'phoneNumber', order: 'DESC' };
    }
    const sortParam = this.sortingObj.field;
    const sortDir = this.sortingObj.order;
    //   if( this.paginationRefresh){
    //     page = this.page - 1;
    // }else{
    //     page = 0;
    // }
    this.commonServices.getClientAuditLogs(
      token,
      customerId,
      FDate,
      TDate,
      deviceMacIds,
      phoneNumbers,
      page,
      size,
      sortParam,
      sortDir
    ).subscribe(
      (res) => {
        if (res.code === '200') {
          this.auditInfo = res.userAudits;
          this.totalRecords = res.totalElements;
          this.mainAuditInfo = res.userAudits;
          this.mainTotalRecords = res.totalElements;
          // console.log(this.auditInfo);
          this.paginationRefresh = false;
          if (this.auditInfo.length === 0) {
            this.noData = 'No Data Found!';
            this.totalRecords = 0;
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      (err) => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
        this.text = 'No Data Found';
      }
    );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((el) => {
      return val.find((element) => {
        return element.field === el.field;
      });
    });
    // restore original order
    // this._selectedColumns = this.cols.filter((a) => val.includes(a));
    localStorage.setItem(
      'selectedAccessColumns',
      JSON.stringify(this._selectedColumns)
    );
  }

  update(dt: Table) {
    dt.reset();
}

  onClearSearch(event) {
    const value = event.target.value;
      this.onClickSearch(value);
  }

  onClickSearch(value) {
    if (!value) {
      this.mainAuditInfo = this.auditInfo;
      this.mainTotalRecords = this.totalRecords;
    } else {
      this.mainAuditInfo =  this.auditInfo.filter(obj => Object.values(obj)
        .some(val => val !== null ? val.toString().trim().toLowerCase()
          .includes(value.trim().toLowerCase()) : ''));
      this.mainTotalRecords = this.mainAuditInfo.length;
    }

  }

}
