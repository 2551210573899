import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {NgxPaginationModule} from 'ngx-pagination';

import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {Ng2OrderModule} from 'ng2-order-pipe';
//
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';

import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './client-admin/dashboard/dashboard.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './layout/header/header.component';
import {AuthGuard} from './auth/auth.guard';
import {AuthService} from './auth/auth.service';
import {AppMaterialModule} from './app-material/app-material.module';
import {UsersComponent} from './client-admin/users/users.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DevicesComponent} from './client-admin/devices/devices.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DashboardZervAdminComponent} from './zerv-admin/dashboard-zerv-admin/dashboard-zerv-admin.component';
import {CustomersComponent} from './zerv-admin/customers/customers.component';
import {DeviceZervAdminComponent} from './zerv-admin/device-zerv-admin/device-zerv-admin.component';
import {UsersZervAdminComponent} from './zerv-admin/users-zerv-admin/users-zerv-admin.component';
import {Sidemenu1Component} from './layout/sidemenu1/sidemenu1.component';
import {Sidemenu2Component} from './layout/sidemenu2/sidemenu2.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {SideBarComponent} from './layout/side-bar/side-bar.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ImageUploaderComponent} from './client-admin/users/image-uploader/image-uploader.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {ConfirmForgotPwdComponent} from './confirm-forgot-pwd/confirm-forgot-pwd.component';
import {CookieService} from 'ngx-cookie-service';
import {AuditLogsComponent} from './zerv-admin/audit-logs/audit-logs.component';
import {AuditLogsClientComponent} from './client-admin/audit-logs-client/audit-logs-client.component';
import {CreateUserComponent} from './client-admin/users/create-user/create-user.component';
import {SearchPipe} from './filters/search.pipe';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ViewUserComponent} from './client-admin/users/view-user/view-user.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {ToastrModule} from 'ngx-toastr';
import {CommonService} from './services/common.service';
import {DateTimePickerModule} from 'ngx-datetime-picker';
// import { Ng2TableModule } from 'ng2-table/ng2-table';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {AddmoreAdminComponent} from './zerv-admin/customers/addmore-admin/addmore-admin.component';
import {DevicesGroupComponent} from './zerv-admin/devices-group/devices-group.component';
import {ViewDevicesComponent} from './zerv-admin/devices-group/view-devices/view-devices.component';
import {DevicesGroupClientComponent} from './client-admin/devices-group-client/devices-group-client.component';
import {ViewDevicesClientComponent} from './client-admin/devices-group-client/view-devices-client/view-devices-client.component';
import {LocationGroupComponent} from './zerv-admin/location-group/location-group.component';
import {ViewLocComponent} from './zerv-admin/location-group/view-loc/view-loc.component';
import {UserAccessGroupsComponent} from './client-admin/users/user-access-groups/user-access-groups.component';
import {ViewGroupedDevicesComponent} from './client-admin/users/user-access-groups/view-grouped-devices/view-grouped-devices.component';
import {LocationGroupClientComponent} from './client-admin/location-group-client/location-group-client.component';
import {ViewLocClientComponent} from './client-admin/location-group-client/view-loc-client/view-loc-client.component';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToolbarModule} from 'primeng/toolbar';
import {CheckboxModule} from 'primeng/checkbox';
import {FileUploadModule} from 'primeng/fileupload';
import {TranslocoRootModule} from './transloco/transloco-root.module';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MenuModule} from 'primeng/menu';
import { AuditTrailLogsComponent } from './zerv-admin/audit-trail-logs/audit-trail-logs.component';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import {MessageService} from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {TooltipModule} from 'primeng/tooltip';
import {TagModule} from 'primeng/tag';
import {SliderModule} from 'primeng/slider';
import {ChipsModule} from 'primeng/chips';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    UsersComponent,
    DevicesComponent,
    DashboardZervAdminComponent,
    CustomersComponent,
    DeviceZervAdminComponent,
    UsersZervAdminComponent,
    Sidemenu1Component,
    Sidemenu2Component,
    SideBarComponent,
    ImageUploaderComponent,
    ForgotPasswordComponent,
    // DoorsComponent,
    ConfirmForgotPwdComponent,
    AuditLogsComponent,
    AuditLogsClientComponent,
    CreateUserComponent,
    ViewUserComponent,
    SearchPipe,
    AddmoreAdminComponent,
    DevicesGroupComponent,
    ViewDevicesComponent,
    DevicesGroupClientComponent,
    ViewDevicesClientComponent,
    LocationGroupComponent,
    ViewLocComponent,
    UserAccessGroupsComponent,
    ViewGroupedDevicesComponent,
    LocationGroupClientComponent,
    ViewLocClientComponent,
    AuditTrailLogsComponent
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    DropdownModule,
    HttpModule,
    BrowserModule,
    NgxIntlTelInputModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    // Ng2TableModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    DateTimePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    // PaginationModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // NgMultiSelectDropDownModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    ButtonModule,
    TableModule,
    MultiSelectModule,
    ToolbarModule,
    CheckboxModule,
    FileUploadModule,
    TranslocoRootModule,
    TabViewModule,
    CalendarModule,
    SplitButtonModule,
    MenuModule,
    ToastModule,
    RippleModule,
    DialogModule,
    PaginatorModule,
    PanelModule,
    TooltipModule,
    TagModule,
    SliderModule,
    ChipsModule
  ],
  providers: [AuthService, AuthGuard, CookieService, CommonService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
