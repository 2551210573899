import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonServices } from "../../../services/commonservice";
import {IDevice} from '../../../model/device.model';



@Component({
  selector: 'app-view-devices',
  templateUrl: './view-devices.component.html',
  styleUrls: ['./view-devices.component.css'],
  providers: [CommonServices]

})
export class ViewDevicesComponent implements OnInit {
  devices: any =[];
  noData: string;
  devicesList: any = [];
  @Input() groupDetails: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  allGroupedDevices: any = [];
  assign: boolean;
  unAssignId: any;
  disabled: boolean = false;
  availableDevice = false;


  constructor(
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
  ) { }
  //sorting
  key: string = "name"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing p to one
  paginate2: number = 1;
  paginate1: number = 1;
  ngOnInit() {
    this.assignedDevices();
    // this.getDevices();
  }
  getDevices() {
    this.spinnerService.show();
    this.availableDevice = true;
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDevices(token, '', '', '', '').subscribe(
      res => {
        if (res.code == 200) {
          this.devices = res.listGetAllDevices;
          if (this.devices.length == 0) {
            this.noData = "No Data Found!";
          }
          for(var list of this.allGroupedDevices){
            for(var item of this.devices){
              if(list.deviceMACId == item.deviceMACId){
                this.disabled = true;
                item["disable"] = this.disabled;
              }
            }
          }
          console.log(this.devices)
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  onChange(items, event) {
    const checked = event.target.checked;
    if (checked === true) {
      this.assign = true;
      const deviceObj = {} as IDevice;
      deviceObj.deviceMACId = items.deviceMACId ? items.deviceMACId : null;
      deviceObj.customerId = items.customerId ? items.customerId : null;
      deviceObj.active = items.active ? items.active : null;
      deviceObj.installLocation = items.locationName ? items.locationName : null;
      deviceObj.installSubLocation = items.subLocationName ? items.subLocationName : null;
      // deviceObj.installLocationFriendlyName = items.DeviceKey ? items.DeviceKey : null;
      deviceObj.uniqueName =  items.uniqueName ? items.uniqueName: null;
      // deviceObj.installSubLocationFriendlyName = items.FirmwareVersion ? items.FirmwareVersion : null;
      deviceObj.deviceGroupId = this.groupDetails.deviceGroupId;
      this.devicesList.push(deviceObj);
    }
    if (checked === false) {
      this.assign = false;
      for ( let i = 0; i < this.devicesList.length; i++) {
        if ( this.devicesList[i].deviceMACId === items.deviceMACId) {
          this.devicesList.splice(i, 1);
        }
     }
    }
    if (this.devicesList.length > 0) {
        this.assign = true;
    } else {
      this.assign = false;
    }
    // console.log(this.devicesList)
  }
  addDevicestoGroup(){
    this.spinnerService.show();
   let data = {
      "listAddDevicesToGroup" : this.devicesList
    }
    let token = localStorage.getItem("zerv");
    this.CommonServices.addDevicestoGroup(token, data).subscribe(
      res => {
        if (res.code == 200) {
          this.assignedDevices();
          this.getDevices();
          this.spinnerService.hide();
          this.assign = false;
          this.CommonServices.showSuccess(res.message, "Success")
        } else {
          this.CommonServices.showError(res.message, "Failed")
          this.getDevices();
          this.spinnerService.hide();
          this.assign = false;
        }
      },
      err => {
        this.assign = false;
        this.spinnerService.hide();
        this.CommonServices.showError("Selected devices belongs to other customers", "Failed")
      }
    );
  }
  assignedDevices() {
    this.spinnerService.show();
    this.availableDevice = false;
    this.devicesList = [];
    let Id = this.groupDetails.deviceGroupId
    let token = localStorage.getItem("zerv");
    this.CommonServices.getallGroupedDevices(token,Id).subscribe(
      res => {
        if (res.code == 200) {
          this.allGroupedDevices = res.listGetAllDeviceGroupMapping;
          if (this.allGroupedDevices.length == 0) {
            this.noData = "No Data Found!";
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  onClickBack(){
  // window.location.reload()
  this.onSubmit.emit(false);
  }
  getUnAssignId(Id){
    this.unAssignId = Id
  }
  removeDevicefromGroup(Id){
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.unassignDevicefromGroup(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.CommonServices.showSuccess("Device Deleted from Group successfully", "Device Deleted");
          this.spinnerService.hide();
          this.assignedDevices();
        } else {
          this.spinnerService.hide();
          this.CommonServices.showError("Device was not Deleted from Group", "Try Again");
        }
      },
      error => {
        this.spinnerService.hide();
      }
    );
  }
}
