import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthGuard } from 'src/app/auth/auth.guard';
import {GlobalConstants} from '../../constants/global-constants';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  value: any;
  type: any;
  currentUser: any;
  customerName: any;
  constructor(private authService: AuthService, private authGuard: AuthGuard, private router: Router, private route: ActivatedRoute) {
    this.currentUser = this.authGuard.role;
    // this.currentUser = this.authGuard.decodedToken['custom:role']
  }

  ngOnInit() {
    let token = localStorage.getItem("zerv");
    let val = token.split(".")[1];
    let role1 = atob(val);
    this.value = JSON.parse(role1);
    this.customerName = this.value['cognito:username'];
    this.type = this.currentUser;
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('zerv');
    this.router.navigate(["/login"]);
    // window.location.reload()
    // localStorage.removeItem('Role');
    // window.location.replace("/login");
    // location.reload();
    // let currentUrl = this.router.url;
    // this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
    //   this.router.navigate(['/login']);
    // });
    // location.reload();
    // this.router.navigate(['/login'], { relativeTo: this.route });
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    // window.location.reload()
    // this.router.navigate(["/login"]);
  }

  getCompanyFirstLetter() {
    const companyName = this.customerName.split('_')[0];
    GlobalConstants.customerName = companyName;
    GlobalConstants.customerNameFirstLetter = companyName.charAt(0).toUpperCase().toString();
    return GlobalConstants.customerNameFirstLetter;
  }
}
