import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {CommonServices} from '../../services/commonservice';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import * as moment from 'moment';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GlobalConstants} from '../../constants/global-constants';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CommonServices]
})
export class AuditLogsComponent implements OnInit {

  onSubmitLoading: boolean;
  auditInfo: any = [];
  text: any;
  searchText;
  noData: any;
  dropdownList = [];
  selectedItems = [];
  devicesDropdownSettings = {};
  phNumDropdownSettings = {};
  devices = [];
  devicesMacIds: any = [];
  deviceMACIDlist: any = [];
  phoneNumberList: any = [];
  customersList: any = [];
  userData: any = [];
  phoneNumbers: any = [];
  selectedDeviceMacIds: any = [];
  FromDate: any;
  ToDate: any;
  listofSelectedDeviceMacIds: any = [];
  listofSelectedPhoneNumbers: any = [];
  listofSelectedCustomers: any = [];
  currentPage = {first: 0, rows: GlobalConstants.ItemPerPage};
  sortField = 0;
  customersInfo: any = [];

  todayDate: NgbDateStruct = undefined;
  totalRecords: any = [];
  paginationRefresh: boolean;
  _selectedColumns: any = [];
  cols = GlobalConstants.ACCESS.cols;
  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  sortingObj = {field: 'phoneNumber', order: 'DESC'};
  currentDate = new Date();
  data: any = {};
  deviceMACId: any = {};
  phoneNumber: any = {};
  customerName: any = {};
  dateValidationError = false;
  mainAuditInfo: any = [];
  mainTotalRecords: number;
  isAccessLogsLoading = false;

  constructor(private router: Router,
              private commonServices: CommonServices,
              private spinnerService: Ng4LoadingSpinnerService,
              private calendar: NgbCalendar) {
    for (let i = 0; i < 6; i++) {
      this._selectedColumns.push(this.cols[i]);
    }
  }

  ngOnInit() {
    if (localStorage.getItem('selectedAccessColumns') !== null) {
      this._selectedColumns = JSON.parse(
        localStorage.getItem('selectedAccessColumns')
      );
    }
    this.data.toDate = this.currentDate;
    this.data.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.listofSelectedPhoneNumbers = [];
    this.listofSelectedDeviceMacIds = [];
    this.getDevices();
    this.getUsersList();
    this.getCustomer();
    this.getAllClientAuditinfo();
  }


  validation() {
    if (this.data.fromDate > this.data.toDate) {
      this.dateValidationError = true;
    } else {
      this.dateValidationError = false;
    }
  }

  onChangeCustomer($event) {
    this.customersList = [];
    if (this.listofSelectedCustomers.length > 10) {
      this.commonServices.showWarning('Can not select more than 10 customers', 'Warning');
      this.listofSelectedCustomers.pop();
      return;
    }
    for (const list of this.listofSelectedCustomers) {
      this.customersList.push(list.customerId);
    }
  }

  onChangeDeviceMacId($event) {
    this.deviceMACIDlist = [];
    if (this.listofSelectedDeviceMacIds.length > 10) {
      this.commonServices.showWarning('Can not select more than 10 Mac Ids', 'Warning');
      this.listofSelectedDeviceMacIds.pop();
      return;
    }
    for (const list of this.listofSelectedDeviceMacIds) {
      this.deviceMACIDlist.push(list.deviceMACId);
    }
  }

  onChangePhoneNumber($event) {
    this.phoneNumberList = [];
    if (this.listofSelectedPhoneNumbers.length > 10) {
      this.commonServices.showWarning('Can not select more than 10 phone numbers', 'Warning');
      this.listofSelectedPhoneNumbers.pop();
      return;
    }
    for (const list of this.listofSelectedPhoneNumbers) {
      this.phoneNumberList.push(list.phoneNumber);
    }
  }

  getDevices() {
    this.isAccessLogsLoading = true;
    const token = localStorage.getItem('zerv');
    this.commonServices.getDevices(token, '', '', '', '').subscribe(
      (res) => {
        if (res.code === '200') {
          this.devices = res.listGetAllDevices;
          // this.devices.forEach((o, i) => {
          //   (o.id = i + 1);
          //   this.devicesMacIds.push(o.deviceMACId);
          // });
          this.isAccessLogsLoading = false;
        }
      },
      (err) => {
        // console.log(err);
        this.isAccessLogsLoading = false;
      }
    );
  }

  getCustomer() {
    this.isAccessLogsLoading = true;
    const token = localStorage.getItem('zerv');
    this.commonServices.getCustomer(token).subscribe(
      res => {
        if (res) {
          this.customersInfo = res;
          this.isAccessLogsLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.isAccessLogsLoading = false;
      }
    );
  }

  getUsersList() {
    this.isAccessLogsLoading = true;
    const token = localStorage.getItem('zerv');
    this.commonServices.getAllUsers(token, '', '', '', '').subscribe(
      (res) => {
        if (res.code === '200') {
          this.userData = res.listGetAllUsers;
          // this.userData.filter(e => {
          //   this.phoneNumbers.push(e.phoneNumber);
          //   return true;
          // });
          this.isAccessLogsLoading = false;
        }
        this.userData.forEach((o, i) => (o.id = i + 1));
        // console.log(this.phoneNumberList)
      },
      (err) => {
        this.isAccessLogsLoading = false;
      }
    );
  }

  dateValidation() {
    let FDate;
    let TDate;
    if (this.FromDate) {
      const stMonth = this.FromDate.month;
      const stDay = this.FromDate.day;
      const sDateMonth = stMonth < 10 ? '0' + stMonth : '' + stMonth;
      const sDateDay = stDay < 10 ? '0' + stDay : '' + stDay;
      FDate = this.FromDate.year + '-' + sDateMonth + '-' + sDateDay;
    }
    // EndDate
    if (this.ToDate) {
      const eMonth = this.ToDate.month;
      const eDay = this.ToDate.day;
      const eDateMonth = eMonth < 10 ? '0' + eMonth : '' + eMonth;
      const eDateDay = eDay < 10 ? '0' + eDay : '' + eDay;
      TDate = this.ToDate.year + '-' + eDateMonth + '-' + eDateDay;
    }
    if (FDate && TDate && FDate > TDate) {
      alert(
        'Please ensure that To Date is greater than or equal to the From Date.'
      );
      this.ToDate = '';
    }
  }

  getAllClientAuditinfo() {
    const token = localStorage.getItem('zerv');
    const customerId = this.customersList.join(',');
    const FDate = moment(this.data.fromDate).format('YYYY-MM-DD');
    const TDate = moment(this.data.toDate).format('YYYY-MM-DD');
    this.isAccessLogsLoading = true;
    this.spinnerService.show();
    this.commonServices.getClientAuditLogs(
      token,
      customerId,
      FDate,
      TDate,
      this.deviceMACIDlist.join(','),
      this.phoneNumberList.join(','),
      this.currentPage.first,
      this.currentPage.rows,
      this.sortingObj.field,
      this.sortingObj.order
    ).subscribe(
      (res) => {
        if (res.code === '200') {
          this.isAccessLogsLoading = false;
          this.spinnerService.hide();
          this.auditInfo = res.userAudits;
          this.totalRecords = res.totalElements;
          this.mainAuditInfo = res.userAudits;
          this.mainTotalRecords = res.totalElements;
          this.paginationRefresh = false;
        }
      },
      (err) => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.isAccessLogsLoading = false;
        this.spinnerService.hide();
        this.text = 'No Data Found';
        this.commonServices.showError('Failed to Load Data', 'Failed');
      }
    );
  }

  pageChanged($event) {
    this.currentPage.rows = $event.rows;
    this.currentPage.first = $event.first / $event.rows;
    this.paginationRefresh = true;
    this.getAllClientAuditinfo();
  }

  customSort(event) {
    if (event.sortOrder === 1) {
      this.sortingObj.order = 'ASC';
    } else {
      this.sortingObj.order = 'DESC';
    }
    this.sortingObj.field = event.field;
    this.getAllClientAuditinfo();
  }
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((el) => {
      return val.find((element) => {
        return element.field === el.field;
      });
    });
    localStorage.setItem(
      'selectedAccessColumns',
      JSON.stringify(this._selectedColumns)
    );
  }

  update() {
    this.getAllClientAuditinfo();

  }
  search() {
    if (!this.searchText) {
      this.mainAuditInfo = this.auditInfo;
      this.mainTotalRecords = this.totalRecords;
    } else {
      this.mainAuditInfo =  this.auditInfo
        .filter(obj => Object.values(obj)
          .some(val => val !== null ? val.toString().trim().toLowerCase().includes(this.searchText.trim().toLowerCase()) : ''));
      this.mainTotalRecords = this.mainAuditInfo.length;
    }
  }
}
