import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CommonServices } from '../../services/commonservice';
import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {GlobalConstants} from '../../constants/global-constants';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  providers: [CommonServices]
})
export class CustomersComponent implements OnInit {
  form: FormGroup;
  message: any;
  customerNameFirstLetter = GlobalConstants.customerNameFirstLetter;
  isSuperAdmin = false;
  customersInfo: any = [];
  customerData: any = {};
  customersById: any = {};
  customerLocList: any = [];
  onSubmitLoading: boolean;
  separateDialCode = true;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  addmoreAdminData: any = {};
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  // selectMessage: boolean = false;
  messageModal: BsModalRef;
  invitationMessage: any;
  Questionnaire = false;
  isChecked = false;
  welcomeTo: string = 'Welcome to ';
  messageContent: any = ". You have been granted permission for mobile access to the organization's facilities. Please download the app from iOS:  OR   Android:  "
  custId: any;
  showAddmoreAdminModal: boolean;
  dropdownList: any;
  listofSelectedCustomerId: any = [];
  itemSelect: string;
  dropdownSettings: { singleSelection: boolean; allowSearchFilter: any; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; };
  customersList: any =[];
  limitSelection = false;
  customerId: any;
  custName: any;
  isPasswordMatch: boolean;
  existedRelatedCutomers: any = [];
  @ViewChild('editCustomerBtn', { static: false }) public editCustomerBtn: ElementRef;
  @ViewChild('addCustomerBtn', { static: false }) public addCustomerBtn: ElementRef;

  removedExistingRelatedCutomers: any = [];
  addRelCustomer: any = [];
  filter: string;
  custData: any=[];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalServices: BsModalService
  ) { }
  //sorting
  key: string = 'name'; //set default
  reverse: boolean = false;
  NocustomersInfo: any;
  NocustomerLocList: any;
  customer: any;

  sort(key) {
    this.custData.sort((a, b) => {
      return (((a[key].toLowerCase() > b[key].toLowerCase()) && this.reverse) ? 1 : -1);
    });
    this.reverse = !this.reverse;
  }

  //initializing p to one
  p: number = 1;

  ngOnInit() {
    this.dropdownList = this.customersList;
    this.listofSelectedCustomerId = [];
    this.itemSelect = '';
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'customerId',
      textField: 'customerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
    };
    this.isChecked = false
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      customerName: ['', Validators.required],
      address: ['', Validators.required],
      phoneNumber: ['', [Validators.minLength(10), Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      temporaryPassword: ['', [Validators.minLength(8), Validators.required]],
      confirmPassword: ['', Validators.required]
    });
    this.spinnerService.show();
    this.getCustomer();
    this.addValidator();
    this.matchpassword();
  }
  spaceValidation(event, key) {
    this.customerData[key] = event.target.value.replace(/\s/g, '');
  }

  spaceValidationAtStart(event) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  onChange(e) {
    let checked = e.target.checked;
    this.isSuperAdmin = checked;
  }
  clear() {
    this.form.reset();
    this.isChecked = false
    this.Questionnaire = false
  }
  get f() {
    return this.form.controls;
  }
  addValidator() {

    this.form.controls['customerName'].setAsyncValidators([
      this.CustomerNotInList()
    ]);
  }
  matchpassword() {
    this.form.controls['confirmPassword'].setAsyncValidators([
      this.confirmPassword('confirmPassword')
    ]);
    this.form.controls['temporaryPassword'].setAsyncValidators([
      this.confirmPassword('temporaryPassword')
    ]);
  }

  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }

  CustomerNotInList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn: boolean = true;
      (this.customersInfo || []).filter(items => {
        if (
          this.form.controls['customerName'].value.toLowerCase() ==
          items.customerName.toLowerCase()
        ) {
          bReturn = false;
        }
      });
      let err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  getCustomer() {
    let token = localStorage.getItem('zerv');
    this.CommonServices.getCustomer(token).subscribe(
      res => {
        this.customersInfo = res;
        this.custData = res;
        if (this.customersInfo.length === 0) {
          this.NocustomersInfo = 'No Data Found!'
        }
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        this.sort('customerName');
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  verifyQuestionnaire($event) {
    this.Questionnaire = $event.target.checked;
  }
  postCustomer() {
    this.buttonDisable(this.addCustomerBtn.nativeElement);
    this.spinnerService.show();
    this.submitted = true;
    // if (this.form.invalid) {
    //   return;
    // }
    let formData = this.customerData;
    let phoneNumber =
      formData.phoneNumber.dialCode + formData.phoneNumber.number;
    phoneNumber = phoneNumber.replace(/[- )(]/g, '');
    let token = localStorage.getItem('zerv');
    formData.address = formData.address.trim();
    let obj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      customerName: formData.customerName,
      address: formData.address,
      phoneNumber: phoneNumber,
      email: formData.email,
      questionnaireEnable: this.Questionnaire,
      temporaryPassword: formData.temporaryPassword,
      isSuperAdmin: this.isSuperAdmin,
      invitationMessage: this.invitationMessage,
      sendInvite: this.isChecked
    };
    // console.log(obj);
    this.CommonServices.postCustomer(token, obj).subscribe(
      res => {
        // if (res.code == 200) {
        this.form.reset();
        this.spinnerService.hide();
        this.CommonServices.showSuccess('Customer Added Successfully', 'Customer Added ')
        this.getCustomer();
        this.onSubmitLoading = false;
        // } else {
        //   this.form.reset();
        //   this.onSubmitLoading = false;
        // }
      },
      err => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  confirmPassword(elementName: any): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let err: ValidationErrors = { exists: true };
      let bReturn: boolean = true;
      if (this.form.controls['confirmPassword'].value != this.form.controls['temporaryPassword'].value) {
        bReturn = false;
      }
      if ('confirmPassword' === elementName) {
        this.form.controls['confirmPassword'].setErrors(null);
        bReturn ? of(this.form.controls['temporaryPassword'].setErrors(null)) : of(this.form.controls['temporaryPassword'].setErrors(err));
      } else {
        this.form.controls['temporaryPassword'].setErrors(null);
        bReturn ? of(this.form.controls['confirmPassword'].setErrors(null)) : of(this.form.controls['confirmPassword'].setErrors(err));
      }
      return of(null);
    };
  }
  getCustomerById(Id, Modal) {
    this.custId = Id;
    this.customersById = '';
    this.customerLocList = '';
    this.spinnerService.show();
    let token = localStorage.getItem('zerv');
    this.CommonServices.getCustomerById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.customersById = res;
          this.customerLocList = res.listCustomerDevices;
          if (this.customerLocList.length == 0) {
            this.NocustomerLocList = 'No Data Found!'
          }
          Modal.show();
          this.spinnerService.hide();
          this.customerData['address'] = this.customersById.customerAddress;
          this.isChecked = this.customersById.sendInvite;
          this.Questionnaire = this.customersById.questionnaireEnable;
          this.invitationMessage = this.customersById.invitationMessage;
          this.onSubmitLoading = false;
        } else {
          this.spinnerService.hide();
          Modal.show();
          this.onSubmitLoading = false;
          this.customersById = '';
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.customersById = '';
        this.isChecked = false;
        this.invitationMessage = '';
        this.onSubmitLoading = false;
      }
    );
  }
  closeModal() {
    this.customersById = '';
    this.customerLocList = '';
  }
  showMessageModal(InviteMessage: TemplateRef<any>) {
    this.messageModal = this.modalServices.show(InviteMessage, { class: 'modal-md', backdrop: 'static', keyboard: false });
  }
  closeMessageModal(event) {
    this.invitationMessage = null;
    // this.selectMessage = false;
    this.isChecked = false;
    this.messageModal.hide();
  }
  checkMessage($event, InviteMessage) {
    this.isChecked = $event.target.checked;
    if ($event.target.checked) {
      this.showMessageModal(InviteMessage)
      this.invitationMessage = this.welcomeTo + this.customerData.customerName + this.messageContent
    } else {
      this.invitationMessage = null
    }
  }
  updateCheckMessage($event, InviteMessage) {
    this.isChecked = $event.target.checked;
    if ($event.target.checked) {
      this.showMessageModal(InviteMessage)
      this.invitationMessage = this.customersById.invitationMessage;
    } else {
      this.invitationMessage = null
    }
  }
  updateCustomerInfo(Id) {
    this.buttonDisable(this.editCustomerBtn.nativeElement);
    let token = localStorage.getItem('zerv');
    let obj = {
      customerAddress: this.customerData.address,
      invitationMessage: this.invitationMessage,
      sendInvite: this.isChecked,
      questionnaireEnable: this.Questionnaire
    };
    // console.log(obj);
    this.CommonServices.updateCustomerInfo(token, obj, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.customerData.address = '';
          this.invitationMessage = '';
          this.isChecked = false;
          this.CommonServices.showSuccess(res.message, 'success')
          this.getCustomer();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          this.CommonServices.showError(res.message, 'Failed')
        }
      },
      err => {
        this.CommonServices.showError('Failed to Update', 'Failed')
        this.onSubmitLoading = false;
      }
    );
  }
  addmoreAdminModal(customer) {
    this.customer = customer;
    this.showAddmoreAdminModal = true;
  }
  onCloseAdminModal() {
    this.showAddmoreAdminModal = false;
  }
  onChangeCustomerRelation($event) {
    this.listofSelectedCustomerId = $event;
  }
  onItemSelect($event) {
    this.addRelCustomer.push($event.customerId);
  }
  onDeSelect(item: any) {
    if (this.existedRelatedCutomers.length > 0) {
      for (var list of this.existedRelatedCutomers) {
        if (item.customerId == list.customerId) {
          this.removedExistingRelatedCutomers.push(list.customerId);
        } else {
          this.addRelCustomer.push(this.customerId)
        }
      }
    }
  }
  getCustomerRelations(list, modal) {
    this.spinnerService.show();
    this.custName = list.customerName;
    this.customersList = [];
    this.customersInfo.forEach(item => {
      let cust = {};
      cust['customerName'] = item.customerName;
      cust['customerId'] = item.customerId;
      this.customersList.push(cust);
    });
    this.customersList.forEach((item, index) => {
      if (item.customerName === this.custName) {
        this.customersList.splice(index, 1);
      }
    });
    this.listofSelectedCustomerId = [];
    let custId = list.customerId;
    this.customerId = custId;
    let token = localStorage.getItem('zerv');
    this.CommonServices.getCustomerRelations(token, custId).subscribe(
      res => {
        if (res.code == 'SC_RETRIEVED_SUCCESSFUL') {
          this.listofSelectedCustomerId = res.data.relatedCustomers;
          this.existedRelatedCutomers = res.data.relatedCustomers
          this.spinnerService.hide();
          modal.show();
          // this.getCustomer();
        } else {
          this.spinnerService.hide();
          modal.show();
        }
      },
      err => {
        this.spinnerService.hide();
        modal.show();
      }
    );
  }
  onSubmitCustomerRelations() {
    let token = localStorage.getItem('zerv');
    let relCustomerIds = [];
    for (var id of this.listofSelectedCustomerId) {
      relCustomerIds.push(id.customerId);
    }
    // let listofCustomerId = customerIds.join(',');
    let obj = {
      'customerId': this.customerId,
      'relCustomerIds': relCustomerIds
    };
    this.CommonServices.customerRelations(token, obj).subscribe(
      res => {
        if (res.code === 'SC_ADDED_SUCCESSFUL') {
          this.customerId = '';
          relCustomerIds = [];
          this.CommonServices.showSuccess('Customers Associated Successfully', 'Success')
          this.getCustomer();
        } else {
          this.CommonServices.showError('Not Associated', 'Failed')
        }
      },
      err => {
        this.CommonServices.showError('Not Associated', 'Failed')
      }
    );
  }
  updateCustomerRelation() {
    let token = localStorage.getItem('zerv');
    let obj = {
      'relCustomerIds': this.addRelCustomer,
      'removedRelCustomerIds': this.removedExistingRelatedCutomers
    };
    this.CommonServices.updateCustomerRelation(token, obj, this.customerId).subscribe(
      res => {
        if (res.code === 'SC_UPDATED_SUCCESSFUL') {
          this.customerId = [];
          this.addRelCustomer = [];
          this.removedExistingRelatedCutomers = [];
          this.CommonServices.showSuccess('Updated Successfully', 'Success');
          this.getCustomer();
        } else {
          this.CommonServices.showError('Not Associated', 'Failed');
        }
      },
      err => {
        this.CommonServices.showError('Not Associated', 'Failed');
      }
    );

  }

  onClearSearch(event) {
    const value = event.target.value;
    this.filterWithText();
  }

  filterWithText() {
    if (this.filter === '') {
      this.spinnerService.show();
      this.getCustomer();
    } else {
      this.custData = [];
      this.custData = this.customersInfo.filter(obj => Object.values(obj)
        .some(val => val !== null ? val.toString().trim().toLowerCase().includes(this.filter.trim().toLowerCase()) : ''));
      this.sort('customerName');
    }
  }
}
