import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CommonServices } from '../../services/commonservice';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-devices-group-client',
  templateUrl: './devices-group-client.component.html',
  styleUrls: ['./devices-group-client.component.css'],
  providers: [CommonServices]
})
export class DevicesGroupClientComponent implements OnInit {

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private commonServices: CommonServices,
  ) { }
  showViewDeviceModal: boolean;
  form: FormGroup;
  deviceGroup: any = {};
  listofGroups: any = [];
  filterRecords: any=[];
  noData: string;
  deviceGroupId: any;
  groupDetails: any = {};
  groupInfoById: any = {};
  // sorting
  key = 'name'; // set default
  reverse = false;

  @ViewChild('updateDeviceGorupBtnDisabled', { static: false }) public updateDeviceGorupBtnDisabled: ElementRef;
  @ViewChild('deleteDeviceGorupBtnDisabled', { static: false }) public deleteDeviceGorupBtnDisabled: ElementRef;
  @ViewChild('addDeviceGorupBtnDisabled', { static: false }) public addDeviceGorupBtnDisabled: ElementRef;
  // initializing p to one
  p = 1;
  editGroup = false;
  filter: string;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  ngOnInit() {
    this.form = this.fb.group({
      deviceGroupName: ['', Validators.required],
      description: [''],
    });
    this.getListofGroups();
  }

  spaceValidation(event) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  addValidator() {
    this.form.controls.deviceGroupName.setAsyncValidators([
      this.alreadyExists()
    ]);
  }

  alreadyExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn = true;
      (this.listofGroups || []).filter(items => {
        if (this.form.controls.deviceGroupName.value.toUpperCase() === items.deviceGroupName.toUpperCase()) {
          if (this.editGroup) {
            bReturn = false;
            if (this.groupInfoById.deviceGroupName.toUpperCase() === items.deviceGroupName.toUpperCase()) {
              bReturn = true;
            }
          } else {
            bReturn = false;
          }
        }
      });
      let isValid = true;
      if ((this.form.controls.deviceGroupName.value || '').trim().length === 0) {
        isValid = false;
        const invalid: ValidationErrors = { empty: true };
        return isValid ? of(null) : of(invalid);
      }
      const err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }

  getListofGroups() {
    this.spinnerService.show();
    const groupType = 'Custom-Type';
    const token = localStorage.getItem('zerv');
    const val = token.split('.')[1];
    const role1 = atob(val);
    const value = JSON.parse(role1);
    const customerId = value['custom:customerId'];
    this.commonServices.getallclientDevicegroups(token, groupType, customerId).subscribe(
      res => {
        if (res.code === '200') {
          this.listofGroups = res.listGetAllDeviceGroup;
          this.filterRecords = res.listGetAllDeviceGroup;
          if (this.listofGroups.length === 0) {
            this.noData = 'No Data Found!';
          }
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  getGroupById(Id, Modal) {
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getGroupById(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.groupInfoById = res;
          this.deviceGroup['deviceGroupName'] = res.deviceGroupName;
          this.deviceGroup['description'] = res.description;
          this.spinnerService.hide();
          this.editGroup = true;
          Modal.show();
        } else {
          this.spinnerService.hide();
          Modal.show();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        Modal.show();
      }
    );
  }
  viewDevicesModal(items) {
    this.groupDetails = items;
    this.showViewDeviceModal = true;
  }
  addGroup(addNewGroup) {
    this.buttonDisable(this.addDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    this.editGroup = false;
    const formData = this.deviceGroup;
    formData.deviceGroupName = formData.deviceGroupName.trim();
    if (formData.description) {
      formData.description = formData.description.trim();
    }
    let data: any;
    data = {
      deviceGroupName: formData.deviceGroupName,
      description: formData.description,
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.addGroupDevices(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.form.reset();
          this.spinnerService.hide();
          addNewGroup.hide();
          this.commonServices.showSuccess(res.message, 'Success');
          this.getListofGroups();
        } else {
          this.form.reset();
          this.spinnerService.hide();
          addNewGroup.hide();
          this.commonServices.showError(res.message, 'Failed');
          this.getListofGroups();
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        this.commonServices.showError("Device Group already exist with this name", 'Failed')
      }
    );

  }

  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }

  updateGroup(addNewGroup, Id) {
    this.buttonDisable(this.updateDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show()
    const formData = this.deviceGroup;
    formData.deviceGroupName = formData.deviceGroupName.trim();
    if (formData.description) {
      formData.description = formData.description.trim();
    }
    let data: any;
    data = {
      deviceGroupName: formData.deviceGroupName,
      description: formData.description,
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.updateGroupInfo(token, Id, data).subscribe(
      res => {
        if (res.code === '200') {
          this.form.reset();
          this.spinnerService.hide()
          addNewGroup.hide();
          this.commonServices.showSuccess(res.message, 'Success');
          this.getListofGroups();
        } else {
          this.form.reset();
          this.spinnerService.hide()
          addNewGroup.hide();
          this.getListofGroups();
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide()
        this.commonServices.showError("Device Group already exist with this name", 'Failed')
      }
    );
    console.log(data);

  }
  onClickBack() {
    this.showViewDeviceModal = false;
    this.groupDetails = '';
  }
  clear() {
    this.form.reset();
  }
  getGroupId(Id) {
    this.deviceGroupId = Id;
  }
  deleteGroup(Id) {
    this.buttonDisable(this.deleteDeviceGorupBtnDisabled.nativeElement);
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.DeleteGroup(token, Id).subscribe(
      res => {
        if (res.code === '200') {
          this.spinnerService.hide();
          this.getListofGroups();
          this.commonServices.showSuccess('Group Deleted successfully', 'Device Deleted');
          // this.onSubmitLoading = false;
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('Group was not Deleted', 'Try Again');
          // this.onSubmitLoading = false;
        }
      },
      error => {
        // console.log(error);
        this.spinnerService.hide();
      }
    );
  }

  onClearSearch(event) {
    const value = event.target.value;
      this.filterWithText();
  }

  filterWithText() {
    if (this.filter == '') {
        this.spinnerService.show();
        this.getListofGroups();
    } else {
        this.filterRecords = [];
        this.filterRecords = this.listofGroups.filter(obj => Object.values(obj)
        .some(val => val !== null ? val.toString().trim().toLowerCase().includes(this.filter.trim().toLowerCase()) : ''));
    }
 }
}
