import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CommonServices } from '../../services/commonservice';
import _ from 'lodash';
import { Papa } from 'ngx-papaparse';
import { MenuItem, SortEvent } from 'primeng/api';
import { GlobalConstants } from '../../constants/global-constants';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { IDevice } from '../../model/device.model';

@Component({
  selector: 'app-device-zerv-admin',
  templateUrl: './device-zerv-admin.component.html',
  styleUrls: ['./device-zerv-admin.component.css'],
  providers: [CommonServices, NgbCarouselConfig, MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceZervAdminComponent implements OnInit, OnChanges {
  isDevicesLoading = false;
  uploadResultsModal: boolean;
  uploadResultsmsg: any = [];
  itemPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  totalRecords: any = [];
  paginationRefresh: boolean;
  sortField = 'deviceMACId:0';
  currentPage = { first: 0, rows: GlobalConstants.ItemPerPage };
  filterColumn = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private papa: Papa,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig
  ) {
    for (let i = 0; i < 6; i++) {
      this._selectedColumns.push(this.cols[i]);
    }
  }

  get f() {
    return this.form.controls;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  @ViewChild('deviceDt') deviceTable: Table;

  @ViewChild('editDeviceBtnDisabled', { static: false }) public editDeviceBtnDisabled: ElementRef;
  @ViewChild('associateBtnDisabled', { static: false }) public associateBtnDisabled: ElementRef;
  @ViewChild('addDeviceBtnDisabled', { static: false }) public addDeviceBtnDisabled: ElementRef;
  @ViewChild('yesBtnSwithDisabled', { static: false }) public yesBtnSwithDisabled: ElementRef;
  @ViewChild('yesBtnDeleteDisabled', { static: false }) public yesBtnDeleteDisabled: ElementRef;

  cols = GlobalConstants.DEVICE.cols;
  selectedDevices = [];
  // tslint:disable-next-line:variable-name
  _selectedColumns: any[] = [];
  designationId;
  form: FormGroup;
  deviceForm: FormGroup;
  devices: any = [];
  allDevices: any = [];
  deviceById: any = {};
  display = 'none';
  devicesList: any = [];
  devicesData: any = {};
  customerId: any;
  customers: any;
  associate = false;
  unassociate = false;
  onSubmitLoading: boolean;
  itemrow: any = {};
  deviceId: any;
  active: any;
  access: any;
  idDevice: any;
  editDeviceData: any;
  noData: any;
  listNonCustomerDevice: any = [];
  uploadingRecords: any = [];
  checkExists: boolean;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  duplicateDevices: any = [];
  duplicateDevicesInFile: any = [];
  selectedOption: any;
  items: MenuItem[];
  listofDeviceManufacturerType: any = [];
  onSubmitLoadspin: boolean;
  totalRecordsInTable: number;
  // sorting
  key = 'name'; // set default
  reverse = false;

  // initializing p to one
  p = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.form = this.fb.group({
      deviceMACId: ['', [Validators.required,Validators.pattern('^([A-Fa-f0-9]{4,36})$')]],
      firmwareVersion: ['', Validators.required],
      deviceManufacturer: ['', Validators.required],
    });
    this.getDevices();
    this.getCustomer();
    this.addValidator();
    this.getDeviceManufacturerType();
  }

  spaceValidation(event) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }
  getMenuItems(device) {
    const me = this;
    return [{
      label: 'Options',
      items: [{
        label: 'Unlock Device',
        icon: 'pi pi-lock-open',
        command: (event: any) => {
          me.unlockDevice(device.deviceMACId);
        }
      },
      {
        label: 'Ping Device',
        icon: 'pi pi-key',
        command: () => {
          me.pingDevice(device.deviceMACId);
        }
      },
      {
        label: 'Reboot Device',
        icon: 'pi pi-power-off',
        command: () => {
          me.rebootDevice(device.deviceMACId);
        }
      }
      ]
    }
    ];
  }

  associateCustModal(modal) {
    this.buttonDisable(this.associateBtnDisabled.nativeElement);
    modal.show();
    this.selectedOption = '';
    this.getCustomer();
  }

  clear() {
    this.form.reset();
  }

  addValidator() {
    this.form.controls.deviceMACId.setAsyncValidators([
      this.ValidDeviceNotInList()
    ]);
    // this.form.controls['assosiatedNFCTagUid'].setAsyncValidators([
    //   this.NFCtagUidNotInList()
    // ]);
  }

  ValidDeviceNotInList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn = true;
      (this.devices || []).filter(items => {
        if (this.form.controls.deviceMACId.value.toUpperCase() === items.deviceMACId.toUpperCase()) {
          bReturn = false;
        }
      });
      let isValid = true;
      if ((this.form.controls.deviceMACId.value || '').trim().length === 0) {
        isValid = false;
        const invalid: ValidationErrors = { empty: true };
        return isValid ? of(null) : of(invalid);
      }
      const err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  openModalDialog() {
    this.display = 'block'; // Set block css
  }

  closeModalDialog() {
    this.display = 'none'; // set none css after close dialog
  }
  onDisableUser() {

  }
  deviceMacIdUpperCase(e) {
    if (e) {
      this.devicesData.deviceMACId = e.toUpperCase();
    }
  }
  customerChange(e) {
    this.customerId = e;
  }

  onChange(customerName, deviceKey, e) {
    this.onChange2(customerName, deviceKey, e.target.checked);
  }
  onChange2(customerName, deviceKey, isSelected) {
    const checked = isSelected;
    if (checked === true && customerName === '') {
      this.associate = true;
      this.unassociate = false;
      this.devicesList.push(deviceKey);
      // console.log(this.devicesList);
    }
    if (checked === true && customerName !== '') {
      this.unassociate = true;
      this.associate = false;
      this.devicesList.push(deviceKey);
      // console.log(this.devicesList);
    }
    if (checked === false && customerName === '') {
      this.associate = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a !== deviceKey) {
          return a;
        }
      });
      // console.log(this.devicesList);
    }
    if (checked === false && customerName !== '') {
      this.unassociate = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a !== deviceKey) {
          return a;
        }
      });
      // console.log(this.devicesList);
    }
    if (this.devicesList.length > 0) {
      this.associate = true;
    } else {
      this.associate = false;
    }
  }

  getCustomer() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getCustomer(token).subscribe(
      res => {
        // if (res.code === '200') {
        this.customers = res;
        this.onSubmitLoading = false;
        // } else {
        //   this.onSubmitLoading = false;
        // }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  getDevices() {
    this.isDevicesLoading = true;
    const token = localStorage.getItem('zerv');
    this.spinnerService.show();
    this.commonServices.getDevices(token, this.currentPage.rows, this.currentPage.first, this.sortField, this.filterColumn).subscribe(
      res => {
        this.spinnerService.hide();
        this.isDevicesLoading = false;
        if (res.code === '200') {
          this.devices = res.listGetAllDevices;
          this.allDevices = res.listGetAllDevices;
          this.paginationRefresh = false;
          this.totalRecordsInTable = res.totalDeviceCount;
          if (this.totalRecordsInTable === 0) {
            this.noData = 'No Data Found!';
          }
          this.onSubmitLoading = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.isDevicesLoading = false;
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  getDeviceById(Id, Modal) {
    this.deviceId = Id;
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.getDeviceById(token, Id).subscribe(
      res => {

        if (res.code === '200') {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
          this.deviceById = res;
          this.devicesData.deviceMACId = this.deviceById.deviceMACId;
          this.devicesData.deviceKey = this.deviceById.deviceKey;
          this.devicesData.firmwareVersion = this.deviceById.firmwareVersion;
          this.devicesData.assosiatedNFCTagUid = this.deviceById.assosiatedNFCTagUid;
          this.devicesData.deviceManufacturer = this.deviceById.deviceManufacturer;
          this.idDevice = this.deviceById.id;
          this.spinnerService.hide();
          Modal.show();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          this.devicesData = {};
          this.spinnerService.hide();
          // Modal.show()
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        this.spinnerService.hide();
        this.devicesData = {};
        this.commonServices.showError('Failed to Load', 'Error');
        // Modal.show();
        this.onSubmitLoading = false;
      }
    );
  }
  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }

  replaceSpaces(value) {
    if (value) {
      return value.replace(/\s/g, '');
    }
    return '';
  }

  postZervDevices(addDeviceModal) {
    this.buttonDisable(this.addDeviceBtnDisabled.nativeElement);
    this.onSubmitLoadspin = true;
    const formData = this.devicesData;
    formData.firmwareVersion = this.replaceSpaces(formData.firmwareVersion);
    let data: any;
    data = {
      listAddDevice: [
        {
          deviceMACId: formData.deviceMACId,
          deviceKey: formData.deviceKey ? formData.deviceKey : null,
          firmwareVersion: formData.firmwareVersion ? formData.firmwareVersion : null,
          deviceManufacturer: formData.deviceManufacturer ? formData.deviceManufacturer : null,
          assosiatedNFCTagUid: formData.deviceMACId
        }
      ]
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.postDevice(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.onSubmitLoadspin = false;
          const responseMessage = JSON.parse(res.message);
          if (responseMessage.invalidMadIds.length < 1 && responseMessage.duplicateMacIds.length < 1 && responseMessage.invalidaManufacturerType.length < 1) {
            addDeviceModal.hide();
            this.form.reset();
            this.commonServices.showSuccess('Device Added Successfully', 'Success');
            this.getDevices();
          }else{
            this.commonServices.showError('DeviceMacId is Invalid', 'Failed');
          }
        } else {
          this.onSubmitLoadspin = false;
          this.form.reset();
          this.commonServices.showError('Failed to Add Device', 'Failed');
        }
      },
      err => {
        this.onSubmitLoadspin = false;
        this.commonServices.showError('Failed to Add Device', 'Failed');
      }
    );

  }


  associateDevices(cid, dList) {
    let data: any;
    data = {
      listDevices: dList,
      customerId: cid
    };
    // console.log(data);
    const token = localStorage.getItem('zerv');
    this.commonServices.associate(token, data).subscribe(
      res => {
        if (res.code === '200') {
          this.customerId = '';
          this.devicesList = [];
          this.selectedOption = '';
          this.getDevices();
          this.closeModalDialog();
          this.onSubmitLoading = false;
          this.associate=false;
          this.buttonDisable(this.associateBtnDisabled.nativeElement);
        } else {
          this.onSubmitLoading = false;
          this.customerId = '';
          this.devicesList = [];
        }
      },
      err => {

        this.customerId = '';
        this.devicesList = [];
        this.onSubmitLoading = false;
      }
    );
  }
  getSwitch(Id, access) {
    this.deviceId = Id;
    this.access = access;
  }
  postSwitchDevice() {
    this.buttonDisable(this.yesBtnSwithDisabled.nativeElement);
    const obj = {
      id: this.deviceId,
      active: this.active
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.postSwitchDevice(token, obj).subscribe(
      res => {

        if (res.code === '200') {
          this.getDevices();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  onChangeSwitch(e) {
    this.active = e.target.checked;
  }
  no() {
    this.getDevices();
  }
  updateZervDevices(Id) {
    this.buttonDisable(this.editDeviceBtnDisabled.nativeElement);
    this.onSubmitLoadspin = true;
    this.deviceId = Id;
    const formData = this.devicesData;
    formData.firmwareVersion = this.replaceSpaces(formData.firmwareVersion);
    const obj = {
      // id: this.idDevice,
      // deviceMACId: this.deviceId,
      // updatedMAC_ID: formData.deviceMACId ? formData.deviceMACId : this.deviceId,
      // updatedDeviceKey: formData.deviceKey,
      // updatedFirmwareVersion: formData.firmwareVersion,
      // updatedDeviceManufacturer: formData.deviceManufacturer,
      // updatedAssosiatedNFCTagUid: formData.assosiatedNFCTagUid,
      deviceMACId: formData.deviceMACId,
      deviceKey: formData.deviceKey,
      firmwareVersion: formData.firmwareVersion,
      deviceManufacturer: formData.deviceManufacturer,
      assosiatedNFCTagUid: formData.assosiatedNFCTagUid,
      active: true
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.updateDevice(token, obj, Id).subscribe(
      // this.commonServices.updateDeviceMacIdInfo(token, obj).subscribe(
      res => {
        if (res.code === '200') {
          this.form.reset();
          this.commonServices.showSuccess(res.message, 'Success');
          this.getDevices();
          this.onSubmitLoadspin = false;
          this.onSubmitLoading = false;
        } else {
          this.form.reset();
          this.commonServices.showError(res.message, 'Failed');
          this.onSubmitLoadspin = false;
        }
      },
      err => {
        this.commonServices.showError('Failed to Load Data', 'Error');
        this.onSubmitLoadspin = false;
      }
    );
  }

  public changeListener(event) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: string = reader.result as string;
        this.uploadCSVdata(csv);
      };
    }
    this.reset();
  }

  uploadCSVdata(csv) {
    this.spinnerService.show();
    this.duplicateDevices = [];
    this.duplicateDevicesInFile = [];
    this.uploadingRecords = [];
    this.totalRecords = [];
    // var result = [];
    const filteredRecords = [];

    this.papa.parse(csv, {
      skipEmptyLines: true,
      header: true,
      complete: (results) => {
        this.totalRecords = results.data;
      }
    });

    const obj = {};

    for (let i = 0, len = this.totalRecords.length; i < len; i++) {
      obj[this.totalRecords[i].DeviceMACId] = this.totalRecords[i];
    }
    // tslint:disable-next-line:forin
    for (const key in obj) {
      this.uploadingRecords.push(obj[key]);
    }
    const addDevicesList = [];
    this.uploadingRecords.forEach(list => {
      list.DeviceMACId = this.replaceSpaces(list.DeviceMACId.trim());
      list.DeviceKey = this.replaceSpaces(list.DeviceKey.trim());
      list.FirmwareVersion = this.replaceSpaces(list.FirmwareVersion.trim());
      list.DeviceManufacturer  = list.DeviceManufacturer.trim();
      list.AssociatedNFCTagUid = list.AssociatedNFCTagUid.trim();
      const deviceObj = {} as IDevice;
      deviceObj.deviceMACId = list.DeviceMACId;
      deviceObj.deviceKey = list.DeviceKey ? list.DeviceKey : null;
      deviceObj.firmwareVersion = list.FirmwareVersion ? list.FirmwareVersion : null;
      deviceObj.deviceManufacturer = list.DeviceManufacturer ? list.DeviceManufacturer : null;
      deviceObj.assosiatedNFCTagUid = list.AssociatedNFCTagUid ? list.AssociatedNFCTagUid : null;
      if (deviceObj.deviceMACId) {
        addDevicesList.push(deviceObj);
      }
    });
    const data = {
      listAddDevice: addDevicesList,
    };
    const token = localStorage.getItem('zerv');
    this.commonServices.postDevice(token, data).subscribe(
      res => {
        let cId;
        const deviceList = new Array();
        if (res.code === '200') {
          const responseMessage = res.message;
          this.uploadResultsmsg = JSON.parse(responseMessage);
          const addedMacIds = this.uploadResultsmsg.addedMacIds;
          if (this.uploadResultsmsg.addedMacIds.length > 0) {
            addDevicesList.forEach(list => {
              if (addedMacIds.indexOf(list.deviceMACId) !== -1) {
                deviceList.push(list.deviceMACId);
              }
            });
          }
          if (this.uploadingRecords[0].CustomerName && this.customers) {
            for (const cust of this.customers) {
              if (this.uploadingRecords[0].CustomerName === cust.customerName) {
                cId = cust.customerId;
                break;
              }
            }
          }
          if (deviceList.length > 0) {
            this.associateDevices(cId, deviceList);
          }
          this.spinnerService.hide();
          this.uploadResultsModal = true;
        } else {
          this.spinnerService.hide();
          this.commonServices.showError(res.message, 'Failed');
        }
      },
      err => {
        this.commonServices.showError('Failed to Upload devices', 'Failed');
        this.spinnerService.hide();
      }
    );
  }
  reset() {
    this.fileUpload.nativeElement.value = '';
  }

  unlockDevice(Id) {
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    this.commonServices.unlockDevice(token, { deviceId }).subscribe(
      res => {
        if (res.code === '200') {
          this.commonServices.showSuccess('Device Unlocked Successfully', 'Success');
          this.getDevices();
        } else {
          this.commonServices.showError('Failed to Unlock Device', 'Failed');
        }
      },
      err => {
        this.commonServices.showError('Failed to Unlock Device', 'Failed');
      }
    );
  }
  pingDevice(Id) {
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    this.commonServices.pingDevice(token, { deviceId }).subscribe(
      res => {
        if (res.code === '200') {
          this.commonServices.showSuccess('Device Pinged Successfully', 'Success');
          this.getDevices();
        } else {
          this.commonServices.showError('Failed to Ping Device', 'Failed');
        }
      },
      err => {
        this.commonServices.showError('Failed to Ping Device', 'Failed');
      }
    );
  }

  rebootDevice(Id) {
    const deviceId = Id;
    const token = localStorage.getItem('zerv');
    this.commonServices.rebootDevice(token, { deviceId }).subscribe(
      res => {
        if (res.code === '200') {
          this.commonServices.showSuccess('Device Rebooted Successfully', 'Success');
          this.getDevices();
        } else {
          this.commonServices.showError('Failed to Reboot Device', 'Failed');
        }
      },
      err => {
        this.commonServices.showError('Failed to Reboot Device', 'Failed');
      }
    );
  }

  onSelectedDevice(customerName, deviceMACId, event) {
    this.onChange2(customerName, deviceMACId, event.toElement.ariaChecked === 'true');
  }

  ngOnChanges(changes: SimpleChanges) {
  }
  getDeviceManufacturerType() {
    const token = localStorage.getItem('zerv');
    this.commonServices.getDeviceManufacturerType(token).subscribe(
      res => {
        if (res.code === '200') {
          this.listofDeviceManufacturerType = res.listGetDeviceManufacturerType;
        }
      },
      err => {
        // console.log(err);
      }
    );
  }

  pageChanged($event) {
    this.currentPage.rows = $event.rows;
    this.currentPage.first = $event.first;
    this.paginationRefresh = true;
    this.getDevices();
  }

  customSort(event: SortEvent) {
    if (event.order === -1) {
      this.sortField = event.field + ':0';
    } else {
      this.sortField = event.field + ':' + event.order;
    }
    this.currentPage.rows = GlobalConstants.ItemPerPage;
    this.currentPage.first = 0;
    this.getDevices();
  }


  onClearSearch(event) {
    const value = event.target.value;
      this.onClickSearch(value);
  }

  onClickSearch(value) {
    this.currentPage.rows =  25;
    this.currentPage.first = 0;
    const searchString = value;
    if (searchString === '') {
      this.filterColumn = '';
    } else {
      this.filterColumn = 'deviceMACId:' + searchString + ',' + 'assosiatedNFCTagUid:' + searchString + ',' + 'subLocationName:' + searchString + ',' + 'locationName:' + searchString + ',' + 'subLocationFriendlyName:' + searchString + ',' + 'locationFriendlyName:' + searchString + ','
      + 'customerName:' + searchString + ',' + 'firmwareVersion:' + searchString + ',' + 'deviceManufacturer:' + searchString + ',' + 'deviceKey:' + searchString;
    }
    this.getDevices();

  }
}
