import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./client-admin/dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth/auth.guard";
import { UsersComponent } from "./client-admin/users/users.component";
import { DevicesComponent } from "./client-admin/devices/devices.component";
import { CustomersComponent } from "./zerv-admin/customers/customers.component";
import { DashboardZervAdminComponent } from "./zerv-admin/dashboard-zerv-admin/dashboard-zerv-admin.component";
import { DeviceZervAdminComponent } from "./zerv-admin/device-zerv-admin/device-zerv-admin.component";
import { UsersZervAdminComponent } from "./zerv-admin/users-zerv-admin/users-zerv-admin.component";
import { SideBarComponent } from "./layout/side-bar/side-bar.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
// import { DoorsComponent } from "./client-admin/doors/doors.component";
import { from } from "rxjs";
import { ConfirmForgotPwdComponent } from "./confirm-forgot-pwd/confirm-forgot-pwd.component";
import { AuditLogsComponent } from "./zerv-admin/audit-logs/audit-logs.component";
import { AuditLogsClientComponent } from "./client-admin/audit-logs-client/audit-logs-client.component";
// import { ReportsComponent } from './client-admin/reports/reports.component';
import { DevicesGroupComponent } from "./zerv-admin/devices-group/devices-group.component";
import { ViewDevicesComponent } from "./zerv-admin/devices-group/view-devices/view-devices.component";
import { DevicesGroupClientComponent } from "./client-admin/devices-group-client/devices-group-client.component";
import { ViewDevicesClientComponent } from "./client-admin/devices-group-client/view-devices-client/view-devices-client.component";
import { LocationGroupComponent } from "./zerv-admin/location-group/location-group.component";
import { UserAccessGroupsComponent } from "./client-admin/users/user-access-groups/user-access-groups.component";
import { LocationGroupClientComponent } from "./client-admin/location-group-client/location-group-client.component";
import { AuditTrailLogsComponent } from "./zerv-admin/audit-trail-logs/audit-trail-logs.component";


const routes: Routes = [
  // { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: "", component: SideBarComponent, canActivate: [AuthGuard] },
  // { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "", component: LoginComponent },
  // {
  //   path: '/',
  //   component: DashboardComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  { path: "login", component: LoginComponent },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "confirmForgotPassword",
    component: ConfirmForgotPwdComponent,
    // canActivate: [AuthGuard]
  },
  { 
    path: "users", 
    component: UsersComponent, 
    children:[
      {  path: "groupAccess",
      component: UserAccessGroupsComponent,}
    ],
    canActivate: [AuthGuard] 
  },
  { path: "devices", component: DevicesComponent, canActivate: [AuthGuard] },
  // { path: "doors", component: DoorsComponent, canActivate: [AuthGuard] },
  /*zerv admin*/
  {
    path: "dashboardZervAdmin",
    component: DashboardZervAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "customers",
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "devicesZervAdmin",
    component: DeviceZervAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "devicesGroup",
    component: DevicesGroupComponent,
    children:[
      {  path: "viewDevices",
      component: ViewDevicesComponent,}
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "locationsGroup",
    component: LocationGroupComponent,
    canActivate: [AuthGuard]
  },
  {
  path: "adminDevicesGroup",
  component: DevicesGroupClientComponent,
  children:[
    {  path: "adminViewDevices",
    component: ViewDevicesClientComponent,}
  ],
  canActivate: [AuthGuard]
},
{
  path: "adminLocationsGroup",
  component: LocationGroupClientComponent,
  canActivate: [AuthGuard]
},
  {
    path: "usersZervAdmin",
    component: UsersZervAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "zervAuditLogs",
    component: AuditLogsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "clientAuditLogs",
    component: AuditLogsClientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "zervAuditTrailLogs",
    component: AuditTrailLogsComponent,
    canActivate: [AuthGuard]
  },
  
  // {
  //   path: "clientReports",
  //   component: ReportsComponent,
  //   canActivate: [AuthGuard]
  // }
  // {
  //   path: '**',
  //   redirectTo: "login"
  // }
  // { path: "", redirectTo: "login", pathMatch: "full" },
  // { path: "app", loadChildren: "../app/app.module#AppModule" },
  // { path: "**", redirectTo: "login", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  // declarations: [DashboardComponent],
  exports: [RouterModule]
})
export class AppRoutingModule { }
