import {Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CommonServices} from '../../services/commonservice';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {PrimeNGConfig, SortEvent} from 'primeng/api';
import {GlobalConstants} from '../../constants/global-constants';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [CommonServices]
})
export class UsersComponent implements OnInit {
  userData: any;
  onSubmitLoading: boolean;
  showCreateUserModal: boolean;
  user: any;
  showEditUserModal: boolean;
  showViewUserModal: boolean;
  active: any;
  userPhone: any;
  access: any;
  phoneId: any;
  userId: any = {};
  userDetails: any;
  NoUserData: any;
  showUserGroupsModal: boolean;
  userGroupDetails: any = [];
  isEdit = false;
  filter;
  key;
  reverse = false;
  p = 1;

  @ViewChild('usersTable') uesrsTable: Table;
  @ViewChild('yesBtnDeleteDisabled', { static: false }) public yesBtnDeleteForUser: ElementRef;
  @ViewChild('yesBtnSwitchDisabled', { static: false }) public yesBtnSwitchDisabled: ElementRef;
  cols = GlobalConstants.CLIENT_ADMIN.USER_COLS;
  itemsPerPage = GlobalConstants.ItemPerPage;
  pageSize = GlobalConstants.PageSize;
  totalRecords: any = [];
  paginationRefresh: boolean;
  sortField = 'name:1';
  currentPage = {first: 0, rows: GlobalConstants.ItemPerPage};
  filterColumn = '';

  constructor(
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.getUsersList();
  }

  openCreateUserModal() {
    this.userDetails = this.userData;
    this.showCreateUserModal = true;
    this.showUserGroupsModal = true;
  }

  onSubmitUser(ev) {
    if (ev) {
      this.getUsersList();
    }
    this.showCreateUserModal = false;
    this.showEditUserModal = false;
  }

  buttonDisable(ele) {
    ele.setAttribute('disabled', 'true');
    setTimeout(() => ele.removeAttribute('disabled'), 1000);
  }

  getUsersList() {
    const token = localStorage.getItem('zerv');
    this.spinnerService.show();
    this.commonServices.getUser(token, this.currentPage.rows, this.currentPage.first, this.sortField, this.filterColumn).subscribe(
      res => {
        this.spinnerService.hide();
        if (res.code === '200') {
          this.userData = res.listUsers;
          this.totalRecords = res.totalUserCount;
          if (this.totalRecords === 0) {
            this.NoUserData = 'No Data Found!';
          }
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  onEditUser(user) {
    this.user = user;
    this.showEditUserModal = true;
  }

  viewUserModal(user) {
    this.user = user;
    this.showViewUserModal = true;
  }
  viewUserGroups(item) {
    this.isEdit = true;
    this.userGroupDetails = item;
    this.showUserGroupsModal = true;
    // const token = localStorage.getItem('zerv');
    // const custId = this.userGroupDetails.customerId;
    // const userId = this.userGroupDetails.phoneNumber;
    // this.commonServices.getUserById(token, userId, custId).subscribe(
    //   res => {
    //     if (res.code === '200') {
    //       this.showUserGroupsModal = true;
    //       this.spinnerService.hide();
    //     }
    //     if (res.code === 500) {
    //       this.showUserGroupsModal = false;
    //       this.commonServices.showError('Internal Server Error', 'Error');
    //     }
    //   },
    //   err => {
    //     this.commonServices.showError('Internal Server Error', 'Error');
    //   });
  }
  onClickBack($event) {
    this.showUserGroupsModal = false;
    this.userGroupDetails = '';
    this.isEdit = false;
  }
  onCloseViewUserModal() {
    this.showViewUserModal = false;
    this.isEdit = false;
  }

  onClickDeleteUser(model: ModalDirective, item) {
    model.show();
    model.config.initialState = item.phoneNumber;
  }

  onChangeSwitch(model: ModalDirective, item) {
    model.show();
    this.access = item.active;
    const data = {  id: item.phoneNumber, active: !item.active };
    model.config.initialState = data;
  }

  userActive_Inactive(modal: ModalDirective) {
    this.buttonDisable(this.yesBtnSwitchDisabled.nativeElement);
    const token = localStorage.getItem('zerv');
    this.commonServices.userActive_Inactive(token, modal.config.initialState).subscribe(
      res => {
        modal.hide();
        if (res.code === '200') {
          this.onSubmitLoading = false;
          this.getUsersList();
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
        }
      },
      err => {
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }

  no(modal: ModalDirective) {
    modal.hide();
    this.getUsersList();
  }

  deleteUser(modal: ModalDirective) {
    this.buttonDisable(this.yesBtnSwitchDisabled.nativeElement);
    console.log(modal.config.initialState);
    this.spinnerService.show();
    const token = localStorage.getItem('zerv');
    this.commonServices.DeleteUser(token, modal.config.initialState).subscribe(
      res => {
        modal.hide();
        if (res.code === '200') {
          this.spinnerService.hide();
          this.getUsersList();
          this.commonServices.showSuccess('User Deleted from Database successfully', 'User Deleted');
        } else {
          this.spinnerService.hide();
          this.commonServices.showError('User was not Deleted from Database', 'Try Again');
        }
      },
      error => {
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }

  pageChanged($event) {
    this.currentPage.rows = $event.rows;
    this.currentPage.first = $event.first;
    this.paginationRefresh = true;
    this.getUsersList();
  }

  customSort(event: SortEvent) {
    if (event.field !== 'name') {
      return;
    }

    if (event.order === -1) {
      this.sortField = event.field + ':0';
    } else {
      this.sortField = event.field + ':' + event.order;
    }
    this.currentPage.rows = GlobalConstants.ItemPerPage;
    this.currentPage.first = 0;
    this.getUsersList();
  }

  onClearSearch(event) {
    const value = event.target.value;
      this.onClickSearch(value);
  }

  onClickSearch(value) {
    this.currentPage.rows =  25;
    this.currentPage.first = 0;
    const searchString = value;
    if (searchString === '') {
      this.filterColumn = '';
    } else {
      this.filterColumn = 'name:' + searchString + ',' + 'email:' + searchString + ',' + 'phoneNumber:' + searchString;
    }
    this.getUsersList();
  }
}
