import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-sidemenu1",
  templateUrl: "./sidemenu1.component.html",
  styleUrls: ["./sidemenu1.component.css"]
})
export class Sidemenu1Component implements OnInit {
  constructor() { }

  ngOnInit() { }
  @Input()
  routerLinkActiveOptions: {
    exact: boolean;
  }
  routerLinkActive: string | string[]
}
